import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import "./plugins/vuetify-money.js";
import firebase from 'firebase';

Vue.config.productionTip = false

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCblhw9HFr16Q_hGOpU1q66CWyvYhVniGM",
  authDomain: "simple-7bda7.firebaseapp.com",
  databaseURL: "https://simple-7bda7-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "simple-7bda7",
  storageBucket: "simple-7bda7.appspot.com",
  messagingSenderId: "837310162932",
  appId: "1:837310162932:web:160a336e811f5dda68bba1",
  measurementId: "G-RJ24FKW4RE"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')