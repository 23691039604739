<template>
<div>
    <v-container id="mobilePrices" v-if="mobile" class="pricesContainerMobile">
    <div style="text-align:center;">
    <div class="animate__animated animate__fadeInDown text-center mx-5"  
         style="padding-top:100px"
    > 
      <h2 class="d-h2 mb-2">
       Sin pagar comisiones,
      </h2>
      <h5 class="d-h2" style="color:#F52F57;">
        ¡Vender ahora es Simple!
      </h5>
    </div>
    </div>
    <v-row no-gutters style="margin:auto;padding-bottom:40px;">
    <v-carousel  class="pt-15" light height="100%" v-model="model" style="z-index:0">
      <template v-slot:prev="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        style="box-shadow:none;background-color:transparent;left: 70%;"
      ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 12H5" stroke="#4F4F4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 19L5 12L12 5" stroke="#4F4F4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
          style="box-shadow:none;background-color:transparent;right: 70%;"
      ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12H19" stroke="#4F4F4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 5L19 12L12 19" stroke="#4F4F4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</v-btn>
    </template>
    <v-carousel-item>
<v-col cols="12" style="padding:30px;">
      <v-card class="pricesCardsMobile" style="border: 1px solid #BDBDBD;">
        <div style="width:225px;height:37px;background: #F52F57;box-shadow: 0px 4px 10px rgba(245,47,87, 0.2) !important;border-radius: 8px;margin:auto;position:relative;top:-22px;">
          <p class="d-subtitle-2" style="color:white;padding-top:7px;">
            El más elegido
          </p>
          <div style="width: 13.27px;height: 13.27px;background: #F52F57;border-radius: 2px;transform: rotate(-135deg);margin: auto;top: -12px;position: relative;"></div>
        </div>
        <h4 class="d-h4" style="padding-top:26px;">
          Simple Real Estate
        </h4>
        <h2 class="d-h2" style="padding-top:8px;">
          <span class="d-p-2"  style="color:black; font-size: 16px;">Desde</span> 2.950 €
        </h2>
        <p class="d-p-2" style="color:#828282">
          más iva
        </p>
        <div style="width: 227px;height: 37px;border: 1px solid #000000;border-radius: 8px;padding-top:10px;margin:auto;margin-bottom:31px">
          <p class="d-p-2">
          Sin Comisiones  <span style="font-size: 16px;"><b>·  Pagas a éxito</b></span>
          </p>
        </div>        
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          <b>No pagas nada</b> si no vendes
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Visitas acompañadas por un Profesional (gratis opcional)
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Fotos y videos profesionales de tu inmueble
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Anuncios en los mejores portales inmobiliarios
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Posicionamientos destacados Premium
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Tu anuncio con descripción completa y atractiva
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Valoraciones con Big Data
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Mejoramos la imagen de tu casa (Home staging)
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Campañas de marketing digital en redes sociales
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Seleccionamos a los mejores compradores
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Coordinación y gestión de las visitas
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Negociación por expertos con los mejores resultados
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Nos ocupamos de todo el papeleo
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Gestión de la compra venta de principio a fin
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Asesoramiento económico y legal
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Confección de contratos
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Ofrecemos financiación al comprador
        </p>
        </div>   
      </v-card>
    </v-col>
    </v-carousel-item>
    <v-carousel-item >
 <v-col cols="12" style="padding:30px;;">
      <v-card class="pricesCardsMobile" style="margin-top:10px;">
        <h4 class="d-h4" style="padding-top:65px;">
          Simple Professional Marketing
        </h4>
        <h2 class="d-h2" style="padding-top:8px;">
        1.450 €
        </h2>
        <p class="d-p-2" style="color:#828282">
          más iva
        </p>
        <div style="width: 227px;height: 37px;border: 1px solid #000000;border-radius: 8px;padding-top:10px;margin:auto;margin-bottom:31px">
          <p class="d-p-2">
          Sin Comisiones  <span style="font-size: 16px;"><b>·  Pagas al inicio</b></span>
          </p>
        </div>        
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          <b>No pagas nada</b> si no vendes
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Visitas acompañadas por un Profesional (gratis opcional)
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Fotos y videos profesionales de tu inmueble
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Anuncios en los mejores portales inmobiliarios
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Posicionamientos destacados Premium
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Tu anuncio con descripción completa y atractiva
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Valoraciones con Big Data
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Mejoramos la imagen de tu casa (Home staging)
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Campañas de marketing digital en redes sociales
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Seleccionamos a los mejores compradores
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Coordinación y gestión de las visitas
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Negociación por expertos con los mejores resultados
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Nos ocupamos de todo el papeleo
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Gestión de la compra venta de principio a fin
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Asesoramiento económico y legal
        </p>
        </div>
        <div class="pricesElementsMobile">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Confección de contratos
        </p>
        </div>
        <div class="pricesElementsMobile pb-10">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Ofrecemos financiación al comprador
        </p>
        </div>    
      </v-card>
    </v-col>
    </v-carousel-item>
  </v-carousel>
    </v-row>
    <div class="mx-5">
    <router-link
      to="/vender"
      class="decoration-remove route"
      style="text-decoration: none !important"

    >
    <v-btn
        color="secondary"
        class="mainButtonMobile d-buttom "
        style="margin-bottom:60px;"
        v-scroll="onScroll"
        @click="pricesinfo"
      >INFORMATE GRATIS</v-btn>
    </router-link> 
    </div> 
    <div class="mx-5" style="background-color: #F0F3F8;">
      <h2 class="d-h4">
        Tu piso en los mejores portales inmobiliarios
      </h2>
      <h3 class="d-h5" style="padding-top:19px;padding-bottom:28px;color:#F52F57;">
        Publicamos tu piso o casa para que lo puedas vender en 60 días sin comisiones
      </h3>
      <img style="position:relative;right:13px;" src="@/assets/Image/logosCompanies.svg"/>
      <p class="d-p-1 d-subtitle-1" style="padding-top:28px;padding-bottom:60px;margin-bottom:0;">
        Publicación y gestión de tu inmueble de forma automática en los mejores portales inmobiliarios nacionales e internacionales.
      </p>
    </div>          
    <v-img
      height="237"
      :src="require('@/assets/Image/home-2.webp')"
    ></v-img>
    <Footer />
  </v-container>
  <v-container v-else  class="px-0 py-0"
  style="background:linear-gradient(180deg, white 35%, #F0F3F8 30%);width: 100%;
max-width: 100%;">
    <div class="pricesContainer">
    <div style="text-align:center;">
    <div class="animate__animated animate__fadeInDown"  
         style="padding-top:148px"
    > 
      <h2 class="d-h2">
        Sin pagar comisiones,
      </h2>
      <h1 class="d-h2" style="color:#F52F57;">
        ¡Vender ahora es Simple!
      </h1>
    </div>
    </div>
    <v-row no-gutters style="max-width: 1366px; margin:auto;padding-top:88px; padding-bottom:40px;">
    <v-col cols="6" style="padding:30px;">
      <v-card class="pricesCards" style="border: 1px solid #BDBDBD;">
        <div style="width:225px;height:37px;background: #F52F57;box-shadow: 0px 4px 10px rgba(32, 78, 149, 0.2);border-radius: 8px;margin:auto;position:relative;top:-22px;">
          <p class="d-subtitle-2" style="color:white;padding-top:7px;">
            El más elegido
          </p>
          <div style="width: 13.27px;height: 13.27px;background: #F52F57;border-radius: 2px;transform: rotate(-135deg);margin: auto;top: -12px;position: relative;"></div>
        </div>
        <h4 class="d-h4" style="padding-top:26px;">
          Simple Real Estate
        </h4>
        <h2 class="d-h2" style="padding-top:8px;">
          <span class="d-p-2" style="color:black; font-size: 16px;">Desde</span> 2.950 €
        </h2>
        <p class="d-p-2" style="color:#828282">
          más iva
        </p>
        <div style="width: 227px;height: 37px;border: 1px solid #000000;border-radius: 8px;padding-top:10px;margin:auto;margin-bottom:31px">
          <p class="d-p-2">
          Sin Comisiones  <span style="font-size: 16px;"><b>·  Pagas a éxito</b></span>
          </p>
        </div>        
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          <b>No pagas nada</b> si no vendes
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Visitas acompañadas por un Profesional (gratis opcional)
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Fotos y videos profesionales de tu inmueble
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Anuncios en los mejores portales inmobiliarios
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Posicionamientos destacados Premium
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Tu anuncio con descripción completa y atractiva
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Valoraciones con Big Data
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Mejoramos la imagen de tu casa (Home staging)
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Campañas de marketing digital en redes sociales
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Seleccionamos a los mejores compradores
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Coordinación y gestión de las visitas
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Negociación por expertos con los mejores resultados
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Nos ocupamos de todo el papeleo
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Gestión de la compra venta de principio a fin
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Asesoramiento económico y legal
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Confección de contratos
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Ofrecemos financiación al comprador
        </p>
        </div>   
      </v-card>
    </v-col>
    <v-col cols="6" style="padding:30px;">
      <v-card class="pricesCards" >
        <h4 class="d-h4" style="padding-top:65px;">
          Simple Professional Marketing
        </h4>
        <h2 class="d-h2" style="padding-top:8px;">
          1.450 €
        </h2>
        <p class="d-p-2" style="color:#828282">
          más iva
        </p>
        <div style="width: 227px;height: 37px;border: 1px solid #000000;border-radius: 8px;padding-top:10px;margin:auto;margin-bottom:31px">
          <p class="d-p-2">
          Sin Comisiones  <span style="font-size: 16px;"><b>·  Pagas al inicio</b></span>
          </p>
        </div>        
        <div class="pricesElements">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          <b>No pagas nada</b> si no vendes
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Visitas acompañadas por un Profesional (gratis opcional)
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Fotos y videos profesionales de tu inmueble
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Anuncios en los mejores portales inmobiliarios
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Posicionamientos destacados Premium
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Tu anuncio con descripción completa y atractiva
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Valoraciones con Big Data
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Mejoramos la imagen de tu casa (Home staging)
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Campañas de marketing digital en redes sociales
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Seleccionamos a los mejores compradores
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/check.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;">
          Coordinación y gestión de las visitas
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Negociación por expertos con los mejores resultados
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Nos ocupamos de todo el papeleo
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Gestión de la compra venta de principio a fin
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Asesoramiento económico y legal
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Confección de contratos
        </p>
        </div>
        <div class="pricesElements">
        <img src="@/assets/Image/icons/cross.svg" style="height:24px;"/>
        <p class="d-p-1" style="padding-left:18px;padding-top:2px;margin-bottom: 0px;color:#BDBDBD;">
          Ofrecemos financiación al comprador
        </p>
        </div>    
      </v-card>
    </v-col>
    </v-row>
    <router-link
      to="/vender"
      class="decoration-remove route"
      
      style="text-decoration: none !important"
    >
    <v-btn
        color="secondary"
        class="mainButton d-buttom"
        style="margin-bottom:70px;text-decoration: none;"
        @click="pricesinfo"
      >INFORMATE GRATIS</v-btn>
    </router-link>
    <div style="max-width:1366px;margin:auto;background-color: #F0F3F8;">
      <h2 class="d-h2">
        Tu piso en los mejores portales inmobiliarios
      </h2>
      <h3 class="d-h3" style="padding-top:19px;padding-bottom:48px;color:#F52F57;">
        Publicamos tu piso o casa para que lo puedas vender en 60 días sin comisiones
      </h3>
      <img src="@/assets/Image/logos.svg"/>
      <p class="d-p-1" style="padding-top:48px;padding-bottom:60px;width: 608px;margin:auto;">
        Publicación y gestión de tu inmueble de forma automática en los mejores portales inmobiliarios nacionales e internacionales.
      </p>
    </div>     
      </div>  
           <v-parallax height="330" :src="require('@/assets/Image/home-2.webp')"></v-parallax>  
    <StickyShare />
    <Footer />
  </v-container>  
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import { isMobile } from "mobile-device-detect";
import StickyShare from "@/components/StickyShare.vue"; 

  export default {
    name: 'Prices',
    components: {
      Footer,
      StickyShare
    },
    data: () => ({  
       mobile: isMobile ? true : false, 
    }),
    methods: {  
      pricesinfo(){
        window.fbq.push('trackCustom', 'INFORMATE GRATIS', {content_name: 'Precios'});
      }
    },
    watch: { 
    },
    mounted() { 
      setTimeout(function () {
            window.scrollTo(0, 0);
        },2); 
    },
  }
</script>

<style scoped lang="scss">
.pricesContainer{
      max-width: 1336px;
    text-align: center;
    padding: 0 80px;
    margin: auto;
    width: 100vw;
}
@media(max-width:1024px){
  .pricesContainer{
      max-width: 1336px;
    text-align: center;
    padding: 0 80px;
    background: linear-gradient(180deg, white 35%, #F0F3F8 30%);
    margin: auto;
    width: 100vw;
}
.pricesElements{
  width: 100%;
  padding-left: 10px !important;
  padding-right:10px;
  padding-top: 13px;
  display: flex;
  text-align: left;
}
}

.pricesContainerMobile{
  max-width: 100%;
  text-align: center;
  padding:0;
  background:linear-gradient(180deg, white 35%, #F0F3F8 30%);
}

.pricesCards {
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px!important;
  max-width: 552px;
  min-height: 964px;
  height: 100%;
}

.pricesCardsMobile {
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px!important;
}

.pricesElements{
  width: 100%;
  padding-left: 50px;
  padding-top: 13px;
  display: flex;
  text-align: left;
}
.pricesElementsMobile{
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 13px;
  display: flex;
  text-align: left;
}
</style>

<style lang="css">
#mobilePrices .v-carousel__controls{
background-color: transparent !important;
top: 6px !important;
}
#mobilePrices .v-window__prev, .v-window__next {
  top: -46px !important;
  z-index: 60;
  background-color: transparent;
}
#mobilePrices .v-item--active {
color:red !important;
}
#mobilePrices .v-carousel__controls__item{
  height: 10px !important;
  width: 10px !important;
}
</style>