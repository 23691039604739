<template>
<div>
  <div v-if="mobile" id="mobile" class="top-bar">
     <v-app-bar
      :color="colors"
      flat
      :style="{height : !drawer ? '80px' : '0vh'}"
      :class="{ 'shadow-bar' : scrollPosition > 0 }"
    >
      <div class="d-flex justify-start  animate__animated animate__fadeInUp" >
          <router-link
            to="/inicio"
            class="decoration-remove route"
            style="display:flex;"
          >
          <img
            alt="Simple"
            src="../assets/Image/logo-simple.svg"
            height="35px"
          />
          </router-link>
          </div>
      <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      height="100vh"
      style="padding-top:40px;"
    >
    <v-tabs 
          optional
          v-model="tab"
          vertical
          left
          class="d-p-1"
          :color="textColor"
          background-color="transparent"
          >
          <v-tab to="/vender">Vender</v-tab>
          <v-tab to="/precios">Precios</v-tab>
          <v-tab to="/como-funciona">Como funciona</v-tab>
          <v-tab to="/cuanto-vale">¿Cuánto vale tu inmueble?</v-tab>
        </v-tabs>
    </v-navigation-drawer>
  </div>
  <div v-else id="desktop-bar" class="top-bar">
    <v-app-bar 
    absolute
    :color="colors"
    :elevation="elevations"
    class="py-0 px-md-15 px-5"
    :class="{ 'shadow-bar' : scrollPosition > 0 }"
    style="height:80px;">
      <v-toolbar-title>
        <div class="d-flex justify-start py-3" :class="{ 'animate__animated animate__fadeInUp' : scrollPosition > 0 && tab==null}">
          <router-link
            to="/inicio"
            class="decoration-remove route"
            style="display:flex; outline: 0;"
          >
          <img
            alt="Simple"
            src="../assets/Image/logo-simple.svg"
            height="35px"
            :class="{ 'logo-home-hide' : scrollPosition < 100 && tab==null}"
          />
          </router-link>
          </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="height:100%">
        <v-tabs 
          optional
          v-model="tab"
          right
          id="tabs-desktop"
          class="d-p-1"
          :color="textColor"
          background-color="transparent"
          >
          <v-tab to="/vender">Vender</v-tab>
          <v-tab to="/precios">Precios</v-tab>
          <v-tab to="/como-funciona">Como funciona</v-tab>
          <v-tab to="/cuanto-vale">¿Cuánto vale tu inmueble?</v-tab>
        </v-tabs>
      </div>
    </v-app-bar>
  </div>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
export default {
  name: "TopBar",
  data: () => ({
    tab:null,
    scrollPosition: null,
    colors: 'transparent',
    textColor: 'secondary',
    elevations: 0,
    route: null,
     mobile: isMobile ? true : false,
     drawer: false,
  }),
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
      if (this.scrollPosition > 1){
        this.colors = "white"
        this.textColor = 'primary'
        this.elevations = 5
      }
      else{
        this.colors = "transparent"
        this.textColor = 'primary'
        this.elevations = 0
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  destroy() {
  window.removeEventListener('scroll', this.updateScroll)
  },
}
</script>

<style lang="css">
.top-bar {
  position: fixed;
  top: 0%;
  width: 100%;
  z-index: 40;
}

.theme--light #tabs-desktop > .v-tabs-bar {
 height:100%;
 height: 76px !important;
}

.v-toolbar__content {
    height: 80px !important;
    padding-bottom: 0px;
    margin-bottom:0px;
}

  
#mobile .v-toolbar__content {
    padding-bottom: 0px;
    margin-bottom:0px;
  margin: auto !important;
  padding: 15px  !important;
  width: 100% !important; 
  height: 80px !important;
}

#mobile .v-tab {
  justify-content: left;
}


.v-tab {
  text-transform: none !important;
  font-family: Nunito Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  padding: 10px 24px !important;
  letter-spacing: 0 !important;
}

#desktop-bar .v-toolbar__content{
  max-width: 1366px;
  margin: auto !important;
  padding: 0px 80px !important;
}
@media(max-width:1024px){
#desktop-bar .v-toolbar__content{
  max-width: 1366px;
  margin: auto !important;
  padding: 0px 10px !important;
}
}
.logo-home-hide{
  display: none;
}

.shadow-bar{
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05) !important;
}
</style>