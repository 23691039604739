<template>
  <v-app>
    <TopBarDesktop />
    <router-view />
    <div class="main-wrapper">
    <cookie-law theme="blood-orange" buttonText="Acepto" class="text-left">
      <div slot="message">
        Se usan cookies propias y de nuestros partners para el correcto funcionamiento de los servicios de la web y para poder tener datos de uso de la misma. Si sigues navegando estarás aceptando su uso. <router-link to="/cookies" style="color:white">Más información</router-link>
      </div>
      <div slot="buttonText">
      </div>
      <div slot="buttonDeclineText">
      </div>
    </cookie-law>
    </div> 
  </v-app>  
</template>

<script>
import TopBarDesktop from './components/TopBarDesktop.vue';
import CookieLaw from 'vue-cookie-law';

export default {
  name: 'App',
  components: {
    TopBarDesktop,
    CookieLaw
  },
  data: () => ({
  }),
};
</script>

<style lang="scss">
#app{
  font-family: Nunito Sans;
  font-style: normal;
  text-align: center;
}

.d-h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
  letter-spacing: 0px !important;
}
.d-h2{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
}
.d-h3{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}
.d-h4{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}
.d-h5{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}
.d-subtitle-1{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
}
.d-subtitle-2{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.d-subtitle-3{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
}
.d-p-1{
  font-family: Nunito Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
}
.d-p-2{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}
.d-buttom{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
.d-description-1{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.d-description-2{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
}

@media(max-width:1024px){ 
 .d-h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 56px;
  letter-spacing: 0px !important;
}
.d-h2{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}
.d-h3{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}
.d-h4{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
}
.d-h5{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.d-subtitle-1{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
}
.d-subtitle-2{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.d-subtitle-3{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
}
.d-p-1{
  font-family: Nunito Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
}
.d-p-2{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}
.d-buttom{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
.d-description-1{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.d-description-2{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
}
}

.Cookie--blood-orange .Cookie__button{
  background:#f52f57;
}

.Cookie--blood-orange{
  background: black;
}
</style>