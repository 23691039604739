<template>
<div>
  <v-container  id="mobile" v-if="mobile"  class="howtoContainer">
    <div style="text-align:center;">
    <div class="animate__animated animate__fadeInDown"  
         style="padding-top:98px;padding-bottom:24px;"
    > 
      <h1 class="d-h2">
        Como funciona
      </h1>
    </div>
    </div>
    <div class="mx-5" style="max-width:1366px">
    <v-expansion-panels accordion flat style="text-align:left;margin:auto;">
    <v-expansion-panel 
      v-for="i in datalist"
      :key="i">
    <v-expansion-panel-header>
      <template v-slot:actions>
          <v-icon class="icon" style="color:#F52F57;">$expand</v-icon>
      </template>
      <span class="header d-h4"> {{ i.header }}</span>
    </v-expansion-panel-header> 
    <v-expansion-panel-content class="d-p-1">
      {{ i.text }}
    </v-expansion-panel-content>
    <v-divider></v-divider>
    </v-expansion-panel>
    </v-expansion-panels>
    </div>
    <router-link
      to="/vender"
      class="decoration-remove route mx-4"
      style="text-decoration: none !important"
    >
    <v-btn
        color="secondary"
        class="mainButtonMobile d-buttom mb-10"
        style="text-decoration: none;margin-top:70px;"
        @click="howtoinfo"
      >INFORMATE GRATIS</v-btn>
    </router-link>
    <img src="@/assets/Image/home-1920.webp" style="position: relative;width:100%"/> 
    <Footer />
  </v-container>   
  <v-container v-else class="howtoContainer">
    <div style="text-align:center;">
    <div class="animate__animated animate__fadeInDown"  
         style="padding-top:148px;padding-bottom:80px;"
    > 
      <h1 class="d-h1">
        Como funciona
      </h1>
    </div>
    </div>
    <div style="max-width:1366px;margin:auto;">
    <v-expansion-panels accordion flat style="text-align:left;margin:auto;">
    <v-expansion-panel 
      v-for="i in datalist"
      :key="i">
    <v-expansion-panel-header>
      <template v-slot:actions>
          <v-icon class="icon" style="color:#F52F57;">$expand</v-icon>
      </template>
      <span class="header d-h4"> {{ i.header }}</span>
    </v-expansion-panel-header> 
    <v-expansion-panel-content class="d-p-1">
      {{ i.text }}
    </v-expansion-panel-content>
    <v-divider></v-divider>
    </v-expansion-panel>
    </v-expansion-panels>
    <router-link
      to="/vender"
      class="decoration-remove route"
      style="text-decoration: none !important"
    >
    <v-btn
        color="secondary"
        class="mainButton d-buttom"
        style="text-decoration: none;margin-top:70px;"
        @click="howtoinfo"
      >INFORMATE GRATIS</v-btn>
    </router-link>
    </div>
    <img src="@/assets/Image/home-1920.webp" style="position: relative;width:100%"/> 
    <StickyShare />
    <Footer />
  </v-container>    
   </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import { isMobile } from "mobile-device-detect";
import StickyShare from "@/components/StickyShare.vue"; 
  export default {
    name: 'HowTo',
    components: {
      Footer,
      StickyShare
    },
    data: () => ({   
      datalist:[
        {header:"¿Cómo ayudará Simple a vender mi inmueble?",
         text:"En Simple te acompañamos durante todo el proceso comercial, realizamos reportes fotográficos profesionales con las últimas tendencias (fotos 360*, tour virtuales, videos de inmersión) , creamos tu anuncio con fotos y textos profesionales (copywriter), los colocamos entre los primeros lugares en portales inmobiliarios, monitoreamos los anuncios, preseleccionamos visitas, encontramos compradores reales, asesoramos durante la negociación, tramitamos la documentación legal para la venta. Somos muy eficientes, y lo hacemos Simple."
        },
        {header:"¿Cómo puedo saber cuánto vale mi casa?",
         text:"Simple calcula gratis el valor de tu propiedad, utilizando la tecnología Big Data. Nuestros expertos determinan el valor del inmueble y luego comparan el precio con el precio de mercado (propiedades en venta y vendidas en el último periodo). Apoyándonos en esta moderna tecnología, consigue vender su propiedad al mejor precio."
        },
        {header:"¿Quién toma las fotos?",
         text:"Simple le enviará un profesional especializado en reportes fotográficos de inmuebles, quien le aconsejará como preparar su inmueble para la venta. Seleccionadas las fotos, procedemos a la publicación del anuncio de su propiedad en todos los portales inmobiliarios."
        },
        {header:"¿Quién realiza las visitas?",
         text:"Tiene la opción de realizarlas usted mismo, porque nadie conoce su hogar y su vecindario mejor que usted. De igual manera, el equipo de Simple pone a disposición un agente experto para que lo acompañe, coordine la muestra, y guie las negociaciones.Simple organiza las visitas con compradores según su disponibilidad. En Simple nos dedicamos a encontrar los mejores compradores, eliminando visitas innecesarias."
        },
        {header:"¿Quién me ayuda con la negociación?",
         text:"Desde el comienzo, se le asignará un experto inmobiliario personal que se encarga de negociar las mejores condiciones, con todos los compradores. Se analiza cada oferta y lo acompañamos durante todo el proceso de negociación, para que, al momento de tomar una decisión, esté bien informado."
        },
        {header:"¿Quién se encarga de la documentación?",
         text:"Desde el equipo de Simple le acompañamos en todo el proceso de venta, le asesoramos y le gestionamos el encargo de venta, el contrato de reserva y el contrato de arras. Además, hacemos un seguimiento en la firma del contrato de compraventa. Con Simple estará acompañado en todo momento."
        },
        {header:"¿Cuánto cuestan los servicios de Simple?",
         text:"Vender ahora tu inmueble es Simple. No cobramos las comisiones de una agencia tradicional de un 5% o más, que representan unos 15.000€ de media, solo cobramos una tarifa fija. Bienvenidos a la nueva gestión comercial inmobiliaria, Simple!"
        },
        {header:"¿Tengo que pagar algo si no vendo mi piso?",
         text:"NO. Si no vende su propiedad, no paga nada."
        },
      ],
             mobile: isMobile ? true : false,
    }),
    methods: {  
      howtoinfo(){
        window.fbq.push('trackCustom', 'INFORMATE GRATIS', {content_name: 'como funciona'});
      }
    },
    watch: { 
    },
    mounted() {
      setTimeout(function () {
            window.scrollTo(0, 0);
        },2);  
    },
  }
</script>

<style scoped lang="scss">
.howtoContainer{
  max-width: 100%;
  text-align: center;
  padding:0;
  display:grid;
}

.icon {
  order: 0;
}

.header {
  order: 1;  
}
</style>

<style>
.v-expansion-panel-header__icon{
  margin-right: 30px !important;
  margin-left: -24px !important;
}
.v-expansion-panel-content__wrap{
  margin-left:35px !important;
}
.v-expansion-panel-header{
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
</style>