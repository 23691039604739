<template>
  <div>
    <div id="mobile" style="width:100%;" v-if="mobile">
      <div style="text-align:center;">
        <div
          class="animate__animated animate__fadeInDown"
          style="padding-top:148px;padding-bottom:95px;"
        >
          <h1 class="d-h1">
            Términos de uso y privacidad
          </h1>
        </div>
      </div>
      <div style="max-width:1366px;margin:auto;width: 90%;">
        <v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-if="tab == 0"
            class="text-left d-flex justify-space-between"
          >
            <div class="col-8 pt-13">
              <div id="Informacion">
                <h2 class="d-h3 pb-6" >Información</h2>
                <p class="d-p-1">
                  Este sitio web así como la correspondiente plataforma móvil
                  son propiedad de BRACESCO INVERSIONES, S.L. (en adelante
                  “SIMPLE”), con domicilio en calle Paseo de la Castellana nº79,
                  piso 7 C.P. 28046, Madrid y provista de CIF número B01893569
                  En todo caso, el acceso a www.simpleproptech.com por cualquier
                  usuario de Internet implica la aceptación y cumplimiento de
                  las presentes Condiciones de Uso (“CU”) y de cualquier otra
                  condición particular del producto o servicio ofertado por lo
                  que, con anterioridad al acceso y/o utilización de dichos
                  contenidos, el usuario deberá leer atentamente las CU. Para la
                  contratación de los servicios publicados y su formalización,
                  SIMPLE facilitará de forma previa las condiciones específicas
                  de los mismos, como condiciones particulares que deberán ser
                  aceptadas expresamente por el usuario y que formarán parte
                  íntegra de las presentes Condiciones Legales.
                </p>
              </div>

              <div id="Objeto">
                <h2 class="d-h3 pb-6 pt-12" >Objeto</h2>
                <p class="d-p-1">
                  Los SERVICIOS prestados por SIMPLE están dirigidos a realizar
                  acciones de intermediación, comercialización y marketing con
                  la finalidad de realizar la operación encargada por el USUARIO
                  respecto de su Inmueble. La utilización por parte del USUARIO
                  de cualquiera de los SERVICIOS de SIMPLE supone e implica el
                  conocimiento y la aceptación expresa a las presentes
                  Condiciones Generales de Uso
                </p>
              </div>

              <div id="Terminos de uso">
                <h2 class="d-h3 pb-6 pt-12" >Términos de uso</h2>
                <p class="d-p-1">
                  <span class="d-h5">Registro</span> <br />
                  El USUARIO accederá al sitio web o plataforma (la
                  “Plataforma”) y se registrará introduciendo la información
                  requerida por SIMPLE en dicho momento. <br />
                  Para poder completar el registro en la Plataforma, el USUARIO
                  deberá proporcionar los datos personales siguientes: <br />
                  ○ Nombre y apellidos <br />
                  ○ E-mail <br />
                  ○ Contraseña <br />
                  ○ Teléfono <br />
                  Una vez completado el registro, todo USUARIO podrá acceder a
                  su perfil para editar el mismo. <br />
                  Responsabilidad sobre el contenido del Perfil: Los USUARIOS
                  son completamente responsables del acceso y correcto uso de su
                  perfil y demás contenidos de la Plataforma con sujeción a la
                  legalidad vigente y a las presentes Condiciones de Uso. Los
                  USUARIOS se abstendrán de usar su perfil y el resto de los
                  contenidos de la Plataforma con fines o efectos ilícitos y que
                  sean lesivos de los derechos e intereses de terceros, o que de
                  cualquier forma puedan dañar, inutilizar, afectar o deteriorar
                  la Plataforma, sus contenidos y sus SERVICIOS, eximiendo a
                  SIMPLE de toda responsabilidad, daño o perjuicio que pudiera
                  derivarse de dicho acceso o uso ilícito por parte de terceros.
                  <br />
                  Cualquier USUARIO puede informar a SIMPLE de cualquier abuso o
                  vulneración de las presentes condiciones, a través del correo
                  electrónico hello@simpleproptech.com. El USUARIO podrá darse de baja
                  de la Plataforma comunicándolo mediante un correo electrónico
                  a través de la dirección hello@simpleproptech.com. <br />
                  <br />
                  <span class="d-h5">Servicios</span> <br />
                  Los SERVICIOS por prestar por parte de SIMPLE se detallarán en
                  el contrato deprestación de servicios que suscriba el usuario
                  con SIMPLE. Serán obligaciones del vendedor las siguientes:
                  <br />
                  ○ 1. El vendedor declara y garantiza expresamente estar en
                  posesión de todas las autorizaciones, licencias, permisos e
                  inscripciones que le sean exigibles en aplicación de la
                  normativa general y de la normativa específica necesarias para
                  la venta del inmueble, así como para suscribir y cumplir el
                  presente contrato, debiendo indemnizar a SIMPLE <br />
                  por cualquier incumplimiento que se derive de tales
                  obligaciones. En particular, el vendedor deberá contar con el
                  consentimiento de todos los copropietarios del inmueble.
                  <br />
                  Asimismo, el Vendedor declara y garantiza expresamente que se
                  halla al corriente de pago de cualesquiera obligaciones
                  exigidas al vendedor en su calidad de propietario por las
                  administraciones públicas, autoridades y demás instituciones
                  competentes de acuerdo con lo establecido en la normativa
                  vigente y que no se ha producido ningún retraso, impago o
                  aplazamiento en dichos pagos ni existe ninguna reclamación
                  administrativa por incumplimiento de tales obligaciones.
                  <br />
                  ○ 2.- El Vendedor se obliga a entregar el inmueble en las
                  condiciones pactadas y con la debida diligencia y a disponer
                  de los medios, documentación y requisitos legales para la
                  venta del inmueble; <br />
                  ○ 3.- el CLIENTE se abstendrá de llevar a cabo cualquier acto
                  que pueda, directa o indirectamente, perjudicar o dañar la
                  imagen y buena reputación de SIMPLE y/o de cualesquiera
                  compañías pertenecientes a su grupo. <br />
                  ○ 4.- El Vendedor asumirá la responsabilidad por el inmueble y
                  su venta, dejando indemne a SIMPLE de cualquier reclamación
                  realizada por terceros. Asumirá los costes y riesgos derivados
                  de la venta. <br />
                  SIMPLE no es responsable de los incumplimientos del vendedor
                  ni tampoco responde de las obligaciones que legalmente
                  competen al vendedor. SIMPLE únicamente opera como
                  intermediario poniendo en relación a ambas partes y ofreciendo
                  la máxima colaboración en los trámites de venta, pero, en
                  ningún caso, esresponsable de los incumplimientos del vendedor
                  ni de las obligaciones que legalmente corresponden al
                  vendedor. Cualquier controversia o reclamación del comprador
                  deberá dirigirse exclusivamente contra el propietario o el
                  vendedor quedando exonerado SIMPLE <br />
                  <br />
                  <span class="d-h5">Precio</span> <br />
                  El Vendedor se obliga al abono de los honorarios de SIMPLE en
                  las condicionespactadas en el contrato que suscriban las
                  partes. Todos los pagos se abonarán mediante la modalidad de
                  pago acordada entre SIMPLE y el USUARIO. Una vez aceptadas las
                  condiciones del SERVICIO y suscritoel correspondiente
                  CONTRATO, el USUARIO se obliga al pago del precio del
                  SERVICIO. <br />
                  Asimismo, El CLIENTE contrae una obligación de pago de los
                  Honorarios en los supuestos siguientes: <br />
                  ○ (i) Venta del INMUEBLE si el comprador (o terceros
                  vinculados directamente) ha sido presentado a través de SIMPLE
                  durante un periodo de 12 meses <br />
                  ○ (ii) Si durante el Periodo de Exclusividad, el CLIENTE
                  procediese directamente por su cuenta o a través de otro
                  intermediario distinto de SIMPLE a la venta del INMUEBLE.
                  <br />
                  ○ (iii) Si SIMPLE consigue una oferta por el PVP acordado Los
                  honorarios se harán efectivos a la fecha de firma del contrato
                  de Arras mediante transferencia bancaria a la cuenta designada
                  por SIMPLE ES57 2100 1574 5702 0069 4672 <br />
                  <br />
                  <span class="d-h5">Duración</span> <br />
                  La duración del contrato suscrito entre el USUARIO y SIMPLE
                  iniciará su vigencia el día de la suscripción del contrato
                  entre las partes. <br />
                  Los encargos de venta sin exclusiva estarán vigentes durante
                  el periodo de venta del inmueble, salvo manifestación en
                  contra por escrito de cualquiera de las Partes con un preaviso
                  escrito de 15 días de antelación <br />
                  Los encargos de venta con exclusiva tendrán una duración de
                  SEIS MESES, desde la firma del contrato, prorrogables
                  tácitamente salvo que medie preaviso por escrito con una
                  antelación mínima de 15 días por cualquiera de las partes.
                  <br />
                  <br />
                  <span class="d-h5">Resolución anticipada.</span> <br />
                  El Contrato suscrito entre el CLIENTE y SIMPLE podrá
                  resolverse anticipadamente según se establece a continuación:
                  <br />
                  ○ (a) En cualquier momento, por mutuo acuerdo de las Partes.
                  <br />
                  ○ (b) Por denuncia del Contrato por cualquiera de las Partes,
                  basada en el incumplimiento grave de alguna de las
                  obligaciones asumidas en virtud del presente Contrato, sin
                  perjuicio del derecho de la Parte perjudicada a optar por la
                  continuación del Contrato y exigir de la otra Parte el íntegro
                  cumplimiento de sus obligaciones y, en cualquiera de los
                  casos, la reparación del perjuicio causado por el
                  incumplimiento. <br />
                  <br />
                  <span class="d-h5"
                    >Compensación costes de intermediación</span
                  >
                  <br />
                  Se devengará la cantidad de MIL QUINIENTOS EUROS MAS IVA
                  (1.500€ más IVA) a favor de SIMPLE que deberá abonar EL
                  CLIENTE en los siguientes supuestos: ○ 1) En caso de
                  cancelación del encargo de intermediación por parte del
                  vendedor por cualquier causa, excepto el incumplimiento por
                  parte de SIMPLE de cualquiera de las obligaciones que asume
                  expresamente en el mandato de venta. <br />
                  ○ 2) En caso de cancelación de la venta por voluntad del
                  vendedor o por resolución anticipada del presente Contrato por
                  incumplimiento imputable al vendedor con anterioridad a la
                  venta del inmueble Dicha cantidad se devengará en concepto de
                  compensación por los costes de intermediación, gestiones
                  realizadas y preparación de la venta, sin perjuicio de la
                  indemnización por daños y perjuicios que pudiera
                  corresponderle, en cualquier caso.Dicha indemnización por
                  daños y perjuicios no se reclamará en el caso que el vendedor
                  haya contratado la modalidad de pago por adelantado y se haya
                  hecho efectivo dicho pago. <br />
                  <br />
                  <span class="d-h5">Derecho de desistimiento</span> <br />
                  De conformidad con lo establecido legalmente, el CLIENTE
                  dispone de 14 días naturales desde la efectiva contratación
                  del servicio mediante la firma el CONTRATO con SIMPLE para el
                  ejercicio del derecho de desistimiento. <br />
                  Para ejercer el derecho de desistimiento, el usuario tendrá
                  que notificar su decisión de desistir del servicio contratado
                  a través de una declaración inequívoca (por ejemplo, una carta
                  enviada por correo postal, fax o correo electrónico). No podrá
                  ejercerse el derecho de desistimiento en el supuesto que el
                  servicio haya sido completamente ejecutado o bien en el caso
                  de que la ejecución haya iniciado con el previo consentimiento
                  expreso del CLIENTE. <br />
                  <br />
                  <span class="d-h5">Información de publicación</span> <br />
                  Los datos publicados en la web son facilitados por el VENDEDOR
                  del inmueble, quien garantiza que toda la información que se
                  incluya en el anuncio es veraz, exacta, íntegra y actualizada.
                  Desde SIMPLE nos esforzaremos por comprobar la veracidad y
                  exactitud de los datos publicados en los anuncios, pero no
                  podemos garantizar totalmente la veracidad de los datos. Por
                  eso, el VENDEDOR se compromete a mantener los datos
                  actualizados en todo momento, notificándonos con la mayor
                  rapidez posible en caso de que algún dato esté desactualizado
                  o fuese inexacto. Al publicar un anuncio, el VENDEDOR reconoce
                  ser el propietario del inmueble que anuncia o estar
                  debidamente autorizado por el propietario del inmueble para su
                  publicación y será el único responsable de la veracidad de los
                  datos publicados, por lo que responderá en exclusiva ante
                  cualquier responsabilidad o daño que pudiera derivarse al
                  respecto. Sin embargo, SIMPLE será responsable de los errores
                  en los datos publicados que no se correspondan con los
                  facilitados por el propietario. <br />
                  <br />
                  <span class="d-h5"
                    >Derechos de propiedad intelectual e industrial</span
                  >
                  <br />
                  SIMPLE es titular o licenciataria de todos los derechos de
                  propiedad intelectual e industrial incluidos en la Plataforma,
                  así como sobre los contenidos accesibles a través de la misma.
                  Los derechos de propiedad intelectual de la Plataforma, así
                  como: textos, imágenes, fotografías, diseño gráfico,
                  estructura de navegación, información y contenidos que se
                  recogen en ella son titularidad de SIMPLE, a quien corresponde
                  el ejercicio exclusivo de los derechos de explotación de estos
                  en cualquier forma y, en especial, los derechos de
                  reproducción, distribución, comunicación pública y
                  transformación, de conformidad con la legislación española de
                  derechos de propiedad intelectual e industrial. La
                  autorización al USUARIO para el acceso a la Plataforma no
                  supone renuncia, transmisión, licencia o cesión total ni
                  parcial sobre derechos de propiedad intelectual o industrial
                  por parte de SIMPLE. No está permitido suprimir, eludir o
                  manipular de ningún modo los contenidos de la Plataforma de
                  SIMPLE. <br />
                  Asimismo, está prohibido modificar, copiar, reutilizar,
                  explotar, reproducir, comunicar públicamente, hacer segundas o
                  posteriores publicaciones, cargar archivos, enviar por correo,
                  transmitir, usar, tratar o distribuir de cualquier forma la
                  totalidad o parte de los contenidos incluidos en la Plataforma
                  para propósitos públicos o comerciales, si no se cuenta con la
                  autorización expresa y por escrito de SIMPLE o, en su caso,
                  del titular de los derechos a que corresponda. <br />
                  El USUARIO que proceda a compartir cualquier tipo de contenido
                  a través de la Plataforma, asegura que ostenta los derechos
                  necesarios para hacerlo, quedando exento SIMPLE de cualquier
                  responsabilidad sobre el contenido y legalidad de la
                  información ofrecida. La facilitación de contenidos por parte
                  de los USUARIOS a través de la Plataforma supondrá la cesión a
                  SIMPLE, con carácter gratuito, y con la máxima amplitud
                  permitida por la legislación vigente, de los derechos de
                  explotación de propiedad intelectual o industrial derivados de
                  tales contenidos. El usuario se obliga a usar los contenidos
                  de forma diligente, correcta y lícita y, en particular, se
                  compromete a abstenerse de suprimir, eludir o manipular el
                  "copyright" y demás datos identificativos de los derechos de
                  Simple o de sus titulares incorporados a los contenidos, así
                  como a los dispositivos técnicos de protección o cualesquiera
                  mecanismos de información que pudieren incluirse en los
                  contenidos. Asimismo, el usuario se abstendrá de emplear los
                  contenidos y, en particular, la información de cualquier clase
                  obtenida a través del Sitio Web para remitir publicidad,
                  comunicaciones con fines comerciales, mensajes no solicitados
                  dirigidos a una pluralidad de personas con independencia de su
                  finalidad, así como a abstenerse de comercializar o divulgar
                  de cualquier modo dicha información. SIMPLE informa que no
                  concede licencia o autorización implícita alguna sobre
                  losderechos de propiedad intelectual y/o industrial o sobre
                  cualquier otro derecho o propiedad relacionada, directa o
                  indirectamente, con los contenidos incluidos en la web.<br />
                  <br />
                  <span class="d-h5">Protección de datos personales</span>
                  <br />
                  SIMPLE declara que cumple la normativa vigente respecto a la
                  protección de datos, en particular, con lo establecido en el
                  Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo,
                  de 27 de abril de 2016, relativo a la protección de las
                  personas físicas en lo que respecta al tratamiento de datos
                  personales y a la libre circulación de estos datos, Ley
                  Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
                  Personales y garantía de los derechos digitales. Por ello, le
                  informamos que sus datos personales quedarán incorporados y
                  serán tratados en los ficheros de SIMPLE, con el fin de
                  poderle prestar y ofrecer nuestros servicios. El usuario podrá
                  ejercitar los derechos de acceso, rectificación, cancelación y
                  oposición frente a SIMPLE, en cualquier momento y de forma
                  gratuita, para ello deberán enviarnos una comunicación
                  escrita, concretando el derecho que ejercita, junto a un
                  documento acreditativo de su identidad (DNI/NIE o pasaporte),
                  a cualquiera de las siguientes: <br />
                  -Por correo postal a SIMPLE (RGPD: Ejercicio de derechos)
                  calle Paseo de la Castellana nº79, piso 7 C.P. 28046, Madrid.
                  <br />
                  -Por correo electrónico hello@simpleproptech.com con asunto RGPD:
                  Ejercicio de derechos. Asimismo, te informamos de que tiene
                  derecho a presentar una reclamación ante la Agencia Española
                  de Protección de Datos si consideras que hemos cometido una
                  infracción de la legislación en materia de protección de datos
                  respecto al tratamiento de tus datos personales. <br />
                  Mientras el USUARIO no comunique lo contrario a SIMPLE, ésta
                  entenderá que sus datos no han sido modificados, que el
                  USUARIO se compromete a notificar a SIMPLE cualquier variación
                  y que SIMPLE tiene el consentimiento para utilizarlos afin de
                  poder fidelizar la relación entre las partes. <br />
                  <br />
                  <span class="d-h5"
                    >Modificación de las condiciones de uso</span
                  >
                  <br />
                  SIMPLE se reserva el derecho de modificar, en cualquier
                  momento, la presentación y configuración del Sitio Web, así
                  como las presentes CU. Por ello, SIMPLE recomienda al CLIENTE
                  leerlas atentamente cada vez que acceda al Sitio Web. Los
                  CLIENTES siempre dispondrán de las CU en un sitio visible,
                  libremente accesible para cuantasconsultas quieran realizar.
                  En caso de que cualquier cláusula de las presentes CU sea
                  declarada nula, las demás cláusulas seguirán vigentes y se
                  interpretarán teniendo en cuenta la voluntad de las partes y
                  la finalidad misma de las presentesCU. SIMPLE podrá no
                  ejercitar alguno de los derechos y facultades conferidos en
                  estedocumento lo que no implicará en ningún caso la renuncia a
                  los mismos salvo reconocimiento expreso por parte de SIMPLE o
                  prescripción de la acción que en cada caso corresponda. <br />
                  SIMPLE no garantiza la inexistencia de interrupciones o
                  errores en el acceso de la Plataforma o a su contenido, ni que
                  ésta se encuentre siempre actualizada, no obstante, SIMPLE
                  llevará a cabo, siempre que no concurran causas que lo hagan
                  imposible o de difícil ejecución, y tan pronto tenga noticia
                  de los errores, desconexiones o falta de actualización en los
                  contenidos, todas aquellas labores tendentes a subsanar los
                  errores, restablecer la comunicación y actualizar los
                  contenidos. <br />
                  <br />
                  <span class="d-h5">Ley aplicable y jurisdicción</span> <br />
                  Estas CU se rigen por la ley española. La relación entre
                  SIMPLE y el USUARIO, se regirá e interpretará de conformidad
                  con las Condiciones Generales que en materia de
                  interpretación, validez y ejecución se regirán por la
                  legislación española. <br />
                  <br />
                  <span class="d-h5">Resolución del Contrato</span>
                </p>

                <p class="d-p-1">
                  El contrato podrá ser resuelto por acuerdo entre las partes o
                  por denuncia del Contrato por cualquiera de las Partes, basada
                  en el incumplimiento grave de alguna de las obligaciones
                  asumidas en virtud del presente Contrato, sin perjuicio del
                  derecho de la Parte cumplidora a optar por la continuación del
                  Contrato y exigir de la otra Parte el íntegro cumplimiento de
                  sus obligaciones y, en cualquiera de los casos, la reparación
                  del perjuicio causado por el incumplimiento. <br />
                  <br />

                  En caso de desistimiento unilateral por parte del vendedor o
                  de cancelación del encargo de intermediación por parte del
                  vendedor por cualquier causa no imputable a SIMPLE y en
                  particular, en caso de cancelación de la venta por voluntad
                  del vendedor o por resolución anticipada del presente Contrato
                  por incumplimiento imputable al vendedor con anterioridad a la
                  venta del inmueble, el vendedor pagará a SIMPLE la suma de MIL
                  (1.500) EUROS + IVA, como compensación por los costes de
                  intermediación, gestiones realizadas y preparación de la
                  venta, sin perjuicio de la indemnización por daños y
                  perjuicios que pudiera corresponderle en cualquier caso.
                  <br />
                  1. Serán obligaciones del vendedor las siguientes: El vendedor
                  declara y garantiza expresamente estar en posesión de todas
                  las autorizaciones, licencias, permisos e inscripciones que le
                  sean exigibles en aplicación de la normativa general y de la
                  normativa específica necesarias para la venta del inmueble,
                  así como para suscribir y cumplir el presente contrato,
                  debiendo indemnizar a SIMPLE por cualquier incumplimiento que
                  se derive de tales obligaciones. En particular, el vendedor
                  deberá contar con el consentimiento de todos los
                  copropietarios del inmueble. Asimismo, el Vendedor declara y
                  garantiza expresamente que se halla al corriente de pago de
                  cualesquiera obligaciones exigidas al vendedor en su calidad
                  de propietario por las administraciones públicas, autoridades
                  y demás instituciones competentes de acuerdo con lo
                  establecido en la normativa vigente y que no se ha producido
                  ningún retraso, impago o aplazamiento en dichos pagos ni
                  existe ninguna reclamación administrativa por incumplimiento
                  de tales obligaciones. <br />
                  2. El Vendedor venderá el inmueble en las condiciones
                  estipuladas en el presente contrato. <br />
                  3. El Vendedor se obliga a entregar el inmueble en las
                  condiciones pactadas en este contrato y con la debida
                  diligencia y a disponer de los medios, documentación y
                  requisitos legales para la venta del inmueble; el Vendedor se
                  abstendrá de llevar a cabo cualquier acto que pueda, directa o
                  indirectamente, perjudicar o dañar la imagen y buena
                  reputación de SIMPLE y/o de cualesquiera compañías
                  pertenecientes a su grupo. <br />
                  4. El Vendedor asumirá la responsabilidad por el inmueble y su
                  venta, dejando indemne a SIMPLE de cualquier reclamación
                  realizada por terceros. Asumirálos costes y riesgos derivados
                  de la venta. <br />
                  5. El Vendedor se obliga al abono de los honorarios de SIMPLE
                  en lascondiciones pactadas en este contrato. <br />
                  6. El Vendedor se obliga a facilitar un correo electrónico a
                  efectos de notificaciones.
                </p>
              </div>
              <div id="Politica de cookies">
                <h2 class="d-h3 pb-6 pt-12" >
                  Política de cookies
                </h2>
                <p class="d-p-1">
                  <span class="d-h5">¿Qué es una Cookie?</span> <br />
                  Una Cookie es un fichero que se descarga en su ordenador al
                  acceder a determinadas páginas web. Las cookies permiten a una
                  página web, entre otras cosas, almacenar y recuperar
                  información sobre los hábitos de navegación de un USUARIO o de
                  su equipo y, dependiendo de la información que contenga y de
                  la forma en que utilice su equipo, pueden utilizarse para
                  reconocer al USUARIO. Las cookies son esenciales para el
                  funcionamiento de internet, aportando innumerables ventajas en
                  la prestación de SERVICIOS interactivos, facilitándole la
                  navegación y usabilidad de nuestra web. La información que le
                  proporcionamos a continuación le ayudará a comprender los
                  diferentes tipos de cookies:
                  <br />
                  <br />
                  <span class="d-h5">TIPOS DE COOKIES</span> <br />
                  SEGÚN LA ENTIDAD QUE LAS GESTIONE <br />
                  Cookies propias: Son aquellas que se recaban por el propio
                  editor para prestar el servicio solicitado por el USUARIO.
                  <br />
                  Cookies de terceros: Son aquellas que son recabadas y
                  gestionadas por un tercero, estas no se pueden considerar
                  propias. <br />
                  SEGÚN EL PLAZO DE TIEMPO QUE PERMANEZCAN ACTIVADAS <br />
                  Cookies de sesión: Recaban datos mientras el USUARIO navega
                  por la red con la finalidad de prestar el servicio solicitado.
                  <br />
                  Cookies persistentes: Se almacenan en el terminal y la
                  información obtenida, será utilizada por el responsable de la
                  cookie con la finalidad de prestar el servicio solicitado.
                  <br />
                  SEGÚN SU FINALIDAD <br />
                  Cookies técnicas: Son las necesarias para la correcta
                  navegación por la web. <br />
                  Cookies de personalización: Permiten al USUARIO las
                  características (idioma) para la navegación por la website.
                  <br />
                  Cookies de análisis: Permiten al prestador el análisis
                  vinculado a la navegación realizada por el USUARIO, con la
                  finalidad de llevar un seguimiento de uso de la página web,
                  así como realizar estadísticas de los contenidos más
                  visitados, número de visitantes, etc. <br />
                  Cookies publicitarias: Permiten al editor incluir en la web,
                  espacios publicitarios, según el contenido de la propia web.
                  <br />
                  Cookies de publicidad comportamental: Permiten al editor
                  incluir en la página web espacios publicitarios según la
                  información obtenida a través de los hábitos de navegación del
                  USUARIO. <br />
                  Según lo dispuesto en el artículo 22.2 de la Ley 34/2002, de
                  11 de julio, de SERVICIOS de la Sociedad de la Información y
                  de Comercio Electrónico (LSSI-CE), SIMPLE informa de las
                  cookies utilizadas en nuestra website:
                  <table class="text-left">
                  <tr class="d-flex">
                    <th class="col-3">Cookie</th>
                    <th class="col-3">Descripción</th>
                    <th class="col-3">Duracón</th>
                    <th class="col-3">Fuente</th>
                  </tr>
                  <tr class="d-flex">
                    <td class="col-3">_ga, _gat, _gid</td>
                    <td class="col-3">
                      Cookies de análisis que se almacenan identificadores
                      anónimos y marcas temporales para conseguir métricas que
                      ayuden a SIMPLE a comprender el comportamiento de los
                      Usuarios.
                    </td>
                    <td class="col-3">2 años</td>
                    <td class="col-3">Google(Analytic s)</td>
                  </tr>
                    <tr class="d-flex">
                    <td class="col-3">_fbp</td>
                    <td class="col-3">Utilizado por Facebook para
                        ofrecer una serie de productos
                        publicitarios, como ofertas en
                        tiempo real de terceros
                        anunciantes</td>
                    <td class="col-3">1 día </td>
                     <td class="col-3">Facebook</td>
                  </tr>
                    <tr class="d-flex">
                    <td class="col-3">is-logged-in,
                    SIMPLE_session</td>
                    <td class="col-3">Cookie técnica de SIMPLE
                    paramantener la sessión del
                    usuario entre página</td>
                    <td class="col-3">1 años</td>
                     <td class="col-3">SIMPLE</td>
                  </tr>
                  </table>
                  Asimismo, SIMPLE informa al USUARIO de que tiene la posibilidad de configurar su
                  navegador de modo que se le informe de la recepción de cookies, pudiendo, si así lo
                  desea, impedir que sean instaladas en su disco duro.  <br /> <br />
                   <span class="d-h5">CONFIGURACIÓN DE COOKIES</span> <br />
                   La legislación vigente nos obliga a solicitar la conformidad del USUARIO para poder
gestionar las cookies. En caso de que el USUARIO decida no autorizar su uso,
SIMPLE no almacenaría ninguna cookie y tan sólo se usarían las cookies técnicas
dado que sin ellas no es posible navegar por la web. En caso de seguir navegando
por la web yno denegar la autorización, dicha actividad implicará la aceptación de su
uso. <br />
El USUARIO puede restringir, bloquear o borrar las cookies de SIMPLE o
cualquierotra página web, utilizando su navegador. En cada navegador la
operativa es diferente, a continuación, se facilitan los enlaces de distintos
navegadores sobrecómo realizar dichas modificaciones:
<br />
Internet Explorer: <br />
http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=ie10
<br />
FireFox: <br />
http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we <br />
Chrome: <br /> https://support.google.com/chrome/answer/95647?hl=es <br />
Safari:  <br /> www.apple.com/es/privacy/use-of-cookies/ <br />
En caso de realizar modificaciones en la configuración de las cookies, SIMPLE
informa que no asegura el correcto funcionamiento de las distintas funcionalidades
de la web. <br />
 <span class="d-h5">Política de privacidad de la plataforma</span> <br />
 Bienvenido a la Política de Privacidad de SIMPLE (la “Política”). En este documentose
describe cómo recopilamos, almacenamos, usamos, protegemos y compartimos su
información y con quién la compartimos.  <br />
¿Quién es el responsable del Tratamiento de sus datos personales? <br />
● Identidad: BRACESCO INVERSIONES, S.L. (“SIMPLE”)  <br /> 
● Domicilio social: calle Paseo de la Castellana nº79 piso 7 Madrid <br />
● C.I.F. nº: B-01893569.  <br />
● Email: hello@simpleproptech.com. <br />
● Teléfono: (+34) 919049444  <br />
¿Con qué finalidad tratamos sus datos personales? <br>
Tratamos la información que nos facilita el usuario con la finalidad de gestionar las
operaciones de compraventa de inmuebles solicitada por el usuario y el envío de
comunicaciones comerciales electrónicas. <br>
En los formularios del Sitio Web, será necesario que el usuario cumplimente los
campos que SIMPLE requiera como obligatorios ya que, en caso contrario, el
usuario no podrá ser registrado en SIMPLE. El usuario debe comunicar a SIMPLE sus
datos personales actuales a fin de que la información contenida en sus ficheros
esté actualizada y sin errores <br>
¿Por cuánto tiempo conservaremos sus datos? <br>
Los datos personales proporcionados se conservarán durante el plazo de diez (10)
años de conformidad con la normativa vigente <br>
¿Cuál es la legitimación para el tratamiento de sus datos? <br>
La base legal para el tratamiento de datos es el consentimiento del usuario prestado
mediante la aceptación de la cláusula de tratamiento de datos, así como la
ejecución de la relación contractual. <br>
¿A qué destinatarios se comunicarán sus datos? <br>
Sus datos también pueden estar alojados en Estados Unidos con MAILCHIMP por el
servicio de mailing, al amparo del acuerdo EU-US "Privacy Shield" disponible en:
https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active <br>
Finalmente, sus datos también pueden comunicarse a Celerity Europe AG para el
envío de SMS. Dicha entidad tiene los datos alojados en Suiza, país que ofrece nivel
de protección equiparable según Decisión 2000/518/CE de la Comisión, de 26 de
julio de 2000. <br>
¿Cuáles son sus derechos cuando nos facilita sus datos personales? <br>
El usuario puede ejercer su derecho de acceso a los datos personales, así como
solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión
cuando los datos ya no sean necesarios para los fines que fueron recogidos.
También podrá solicitar la limitación, portabilidad y oposición del tratamiento de sus
datos, en determinadas circunstancias y por motivos relacionados con su situación
particular. <br><br>
Igualmente, tiene derecho a revocar su consentimiento en cualquier momento sin
que ello afecte de forma retroactiva al tratamiento de datos personales realizado
hasta ese momento. <br><br>

El usuario podrá ejercitar los derechos referidos anteriormente, en los términos y
condiciones previstos en la legislación vigente, en el domicilio social de SIMPLE o
solicitarlo mediante el envío de correo electrónico a hello@simpleproptech.com. <br><br>

En el supuesto de que no obtenga una respuesta satisfactoria y desee formular una
reclamación u obtener más información al respecto de cualquiera de estos derechos, puede acudirse a la Agencia Española de Protección de Datos
(www.agpd.es - C/ Jorge Juan, 6 de Madrid). <br><br>
                </p>
              </div>
            </div>

            <div class="col-3 pt-13 ">
              <v-list flat style="position: sticky;position: -webkit-sticky;
  position: sticky;
  top: -1px;">
                <v-list-item-group v-model="selectedItem" color="secondary">
                  <template v-for="(item, i) in itemsTerm">
                    <v-list-item :key="i">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      class="col-12 py-0"
                      v-if="i < itemsTerm.length - 1"
                      :key="i"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-tab-item>

          <v-tab-item   v-if="tab == 1"  class="text-left d-flex justify-space-between">
               <div class="col-8 pt-13">
                   <h2 id="responsable" class="d-h3 pb-6">¿Qui&eacute;n es el responsable del Tratamiento de los datos personales de los Usuarios?</h2>
<ul>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Identidad: BRACESCO INVERSIONES, S.L. (&ldquo;SIMPLE&rdquo;)</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Plataforma: https://simpleproptech.com</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>NIF: B01893569</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Direcci&oacute;n Postal: C/ Paseo de la Castellana n&ordm;79 piso 7, 28046, Madrid</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Tel&eacute;fono: (+34) 919049444</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Contacto: </span><a href="mailto:hello@simpleproptech.com"><span>hello@simpleproptech.com</span></a></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Legitimaci&oacute;n: Cumplimiento de la relaci&oacute;n contractual, inter&eacute;s leg&iacute;timo y consentimiento del Usuario</span></p>
</li>
</ul>
<p dir="ltr"><span>En la presente pol&iacute;tica de privacidad se facilita informaci&oacute;n relativa al tratamiento de los datos personales de nuestros Usuarios de conformidad con lo previsto a la normativa aplicable, en particular, en el Reglamento General de Protecci&oacute;n de Datos (&ldquo;RGPD&rdquo;)</span></p>
<br /><br />
 <h2 id="datos-usuarios" class="d-h3">¿Qu&eacute; datos de los Usuarios tratamos?</h2>
<br>
<p dir="ltr"><span>SIMPLE &uacute;nicamente recoger&aacute; aquellos datos personales que el Usuario desee revelar voluntariamente, y, de acuerdo con la normativa vigente en materia de protecci&oacute;n de datos, as&iacute; como en materia de servicios de la sociedad de la informaci&oacute;n y de comercio electr&oacute;nico, comunicar&aacute; que dichos datos personales aportados en el momento de su registro, o cualquier otro facilitado a SIMPLE para su acceso a algunos de los servicios de la Plataforma, ser&aacute;n incorporados en ficheros titularidad de SIMPLE.</span></p>
<ul>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Datos de contacto y registro</span><span>: Datos que el Usuario nos facilita cuando se pone en contacto y/o se registra con SIMPLE: por ejemplo, nombre y apellidos, direcci&oacute;n, ciudad, correo electr&oacute;nico y tel&eacute;fono. Aparte SIMPLE tendr&aacute; acceso a la informaci&oacute;n adicional facilitada ya sea a trav&eacute;s del formulario de contacto, mediante correo electr&oacute;nico, WhatsApp o a trav&eacute;s de atenci&oacute;n al cliente de forma telef&oacute;nica.</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Informaci&oacute;n adicional compartida por el Usuario</span><span>: El Usuario podr&aacute; facilitar informaci&oacute;n adicional a SIMPLE para que esta intermedie en la contrataci&oacute;n de servicios de proveedores y/o empresas colaboradoras (mudanza, contrataci&oacute;n</span><span><br /></span>
<span>alarma, etc.) u otros fines como datos de facturaci&oacute;n o incidencias. SIMPLE, si lo estima conveniente, llevar&aacute; a cabo la transcripci&oacute;n y grabaci&oacute;n de las conversaciones mantenidas con el Usuario para la tramitaci&oacute;n de incidencias, dudas u otras consultas que pudiera realizar.</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Datos derivados de otras fuentes</span><span>: Si el Usuario llega a la Plataforma de SIMPLE a trav&eacute;s de una fuente externa (como ser&iacute;a por ejemplo un enlace de otra p&aacute;gina web o de una red social como Instagram, Facebook, etc.), SIMPLE recopila los datos de dicha fuente. Asimismo, si el Usuario accede a la Plataforma a trav&eacute;s de los productos y servicios ofrecidos por Google, &eacute;sta podr&aacute; enviar datos de navegaci&oacute;n del Usuario a SIMPLE, con el acceso a la Plataforma a trav&eacute;s de los enlaces creados por Google.</span></p>
</li>
</ul>
<br /><br />
 <h2 id="finalidad" class="d-h3 pb-6">¿Con qu&eacute; finalidad y base legal utilizamos los datos?</h2>
<p dir="ltr"><span>Prestaci&oacute;n de servicios</span><span>. Gestionar el registro del Usuario en la Plataforma, mejorar los servicios y productos de SIMPLE, intermediar en la contrataci&oacute;n de la prestaci&oacute;n de servicios ofertados en la Plataforma con proveedores o empresas colaboradoras, atender consultas y a los compromisos generados por los respectivos contratos, habiendo consentido los Usuarios expresamente el tratamiento de los datos al darse de alta en la Plataforma o bien en el momento de la suscripci&oacute;n de dichos contratos.</span></p>
<p dir="ltr"><span>Comunicaciones</span><span>. SIMPLE utiliza tambi&eacute;n los datos para realizar comunicaciones v&iacute;a correo electr&oacute;nico, WhatsApp y/o llamada telef&oacute;nica al Usuario acerca de los servicios contratados, la operativa de &eacute;stos, informar de sus productos y servicios con fines comerciales.</span></p>
<p dir="ltr"><span>Fines estad&iacute;sticos y an&aacute;lisis del servicio</span><span>. SIMPLE tambi&eacute;n utiliza la informaci&oacute;n con fines estad&iacute;sticos para investigar y analizar c&oacute;mo mejorar los servicios que presta a los Usuarios.</span></p>
<p dir="ltr"><span>Cumplimiento de obligaciones legales</span><span>. En cumplimiento de las obligaciones legales que se apliquen en cada momento, SIMPLE utilizar&aacute; los datos para gestionar la facturaci&oacute;n y a efectos de contabilidad, informar a los &oacute;rganos competentes de las Administraciones P&uacute;blicas, as&iacute; como a proveedores o empresas colaboradoras para cumplir con los servicios contratados o cuando dichas autoridades competentes soliciten datos en el marco de investigaciones tributarias, judiciales y/o la interposici&oacute;n de las acciones necesarias para proteger los intereses de SIMPLE.</span><span><br /></span></p>
<br> <br>
 <h2 id="destinatarios" class="d-h3 pb-6">&iquest;A qu&eacute; destinatarios se comunicar&aacute;n sus datos?</h2>
<p dir="ltr"><span>Seg&uacute;n la finalidad con la que fueron recogidos, los datos de los Usuarios ser&aacute;n comunicados, en caso de ser necesario, a las Administraciones P&uacute;blicas, entidades p&uacute;blicas y privadas, fuerzas y cuerpos de seguridad, proveedores necesarios para el normal funcionamiento de los servicios contratados como proveedores hosting, de pago, herramientas de gesti&oacute;n interna, socios comerciales, o terceros que siguen las instrucciones de SIMPLE, para procesar la informaci&oacute;n compartida con ellos conforme a la legalidad vigente y la presente Pol&iacute;tica de Privacidad.</span></p>
<p dir="ltr"><span>Si la propiedad de SIMPLE cambiara o la mayor parte de sus activos fueran adquiridos por un tercero, los Usuarios ser&aacute;n informados que esta transferir&aacute; sus datos al adquirente con la finalidad de seguir prestando los servicios objeto del tratamiento de datos. En todo caso, el nuevo responsable comunicar&aacute; a los Usuarios sus datos de identificaci&oacute;n.</span></p>
<p dir="ltr"><span>Los datos tambi&eacute;n pueden estar alojados en Estados Unidos con MailChimp por el servicio de emailing, al amparo del acuerdo EU-EE.UU. (Privacy Shield) o ser comunicados a Celerity Europe AG para el env&iacute;o de SMS cuyos datos se encuentran alojados en Suiza, pa&iacute;s que ofrece nivel de protecci&oacute;n equiparable seg&uacute;n Decisi&oacute;n 2000/518/CE de la Comisi&oacute;n, de 26 de julio de 2000.</span></p>
<p dir="ltr"><span>Para que el Usuario pueda contratar los servicios de proveedores de SIMPLE, ofrecidos a trav&eacute;s de la Plataforma, ciertos datos de car&aacute;cter personal de los Usuarios podr&aacute;n ser compartidos con:</span></p>
<p dir="ltr"><span>Proveedores y profesionales colaboradores</span><span> que ofrecen sus servicios a los Usuarios a trav&eacute;s de la Plataforma https://SIMPLE.com/servicios, entre otros, HolaLuz, Boxmotions, Tyco Integrated Security, Aqu&iacute; tu reforma, Grupo BC, etc., con los que se ha suscrito un contrato que deber&aacute;n tratar seg&uacute;n, contrato suscrito con SIMPLE, la informaci&oacute;n de conformidad con la presente Pol&iacute;tica de Privacidad y la legislaci&oacute;n aplicable en la materia de protecci&oacute;n de datos.</span></p>
<p dir="ltr"><span>Proveedores de servicios de pago</span><span>. Los proveedores de servicios de pago han sido elegidos en base a sus medidas de seguridad y cumpliendo, en todo caso, las medidas de seguridad establecidas por la normativa de servicios de pago.</span><span><br /></span></p>
<p dir="ltr"><span>Compa&ntilde;&iacute;as aseguradoras</span><span>. SIMPLE podr&aacute; comunicar los datos de los Usuarios a las compa&ntilde;&iacute;as aseguradoras con las que SIMPLE tengan un acuerdo para la gesti&oacute;n y tramitaci&oacute;n de reclamaciones y siniestros derivados de su actividad.</span></p>
<p dir="ltr"><span>En el supuesto que el Usuario contacte directamente con los proveedores o empresas colaboradoras y facilite sus datos directamente a &eacute;stos, SIMPLE no ser&aacute; responsable por el uso que &eacute;stos hagan.</span></p>
<br /><br />
 <h2 id="tiempo" class="d-h3 pb-6">&iquest;Por cu&aacute;nto tiempo ser&aacute;n conservados los datos?</h2>
<p dir="ltr"><span>Los datos de los Usuarios ser&aacute;n conservados durante la ejecuci&oacute;n y mantenimiento de la relaci&oacute;n contractual, esto es, mientras sigan siendo Usuarios de SIMPLE o hasta que ejercites tu derecho de limitaci&oacute;n.</span></p>
<p dir="ltr"><span>En caso de darse de baja de la Plataforma, SIMPLE conservar&aacute; los datos de los Usuarios durante el per&iacute;odo legal establecido en la normativa que fuera de aplicaci&oacute;n, para la ejecuci&oacute;n y defensa de acciones en las que SIMPLE pudiera ser parte.</span></p>
<br /><br />
 <h2 id="derechos-usuario" class="d-h3 pb-6">&iquest;Qu&eacute; derechos puede ejercer el Usuario?</h2>
<p dir="ltr"><span>Como consecuencia del tratamiento de los datos personales de los Usuarios por parte de SIMPLE, la legislaci&oacute;n vigente otorga a estos una serie de derechos. En particular, los Usuarios podr&aacute;n ejercitar ante SIMPLE los siguientes derechos:</span></p>
<ol>
<li dir="ltr">
<p dir="ltr"><span>Derecho de acceso: El Usuario tendr&aacute; derecho a conocer los datos personales que son tratados por SIMPLE, as&iacute; como las finalidades de dichos tratamientos.</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>Derecho de rectificaci&oacute;n: El Usuario podr&aacute; solicitar en cualquier momento la rectificaci&oacute;n de un dato si los que constaran registrados fueran err&oacute;neos o inexactos.</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>Derecho de supresi&oacute;n: Solicitar, en cualquier momento, la supresi&oacute;n de los datos de los ficheros de SIMPLE, cuando esto sea posible de conformidad con la legislaci&oacute;n vigente.</span><span><br /></span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>Derecho de oposici&oacute;n: El Usuario podr&aacute; oponerse al tratamiento de sus datos en relaci&oacute;n con cualesquiera finalidades con las que SIMPLE trate los datos, o retirar el consentimiento para la recepci&oacute;n de comunicaciones comerciales.</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>Derecho a solicitar la limitaci&oacute;n del tratamiento: El Usuario podr&aacute; solicitar la limitaci&oacute;n del tratamiento de sus datos personales cuando no sean correctos o exactos, haya dudas de la legitimidad del tratamiento o se opte por limitar el tratamiento a su eliminaci&oacute;n. Tambi&eacute;n cuando los datos ya no son necesarios conforme a la finalidad para la que fueron recabados, pero el Usuario precisa que SIMPLE los conserve para interponer acciones legales.</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>Derecho a la portabilidad de los datos: El Usuario tendr&aacute; derecho a, siempre que t&eacute;cnicamente sea posible y razonable, solicitar que los datos sean comunicados a otro responsable del tratamiento. El ejercicio de los derechos que ostenta el Usuario podr&aacute; hacerse efectivo dirigi&eacute;ndose a SIMPLE a trav&eacute;s de alguno de los siguientes medios, acompa&ntilde;ando copia de su DNI o documento identificativo equivalente mediante:</span></p>
</li>
</ol>
<p dir="ltr"><span>Correo electr&oacute;nico que deber&aacute; dirigir a la siguiente direcci&oacute;n: </span><a href="mailto:hello@simpleproptech.com"><span>hello@simpleproptech.com</span></a><span>Correo postal dirigido a calle Paseo de la Castellana n&ordm;79 piso 7, 28046, Madrid</span></p>
<p dir="ltr"><span>Tambi&eacute;n podr&aacute; ponerse en contacto con nuestro Delegado de Protecci&oacute;n de Datos (DPO) de SIMPLE, mediante direcci&oacute;n de correo electr&oacute;nico </span><a href="mailto:hello@simpleproptech.com"><span>hello@simpleproptech.com</span></a></p>
<p dir="ltr"><span>O presentar reclamaci&oacute;n ante la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos y reclamar ante este organismo la protecci&oacute;n de tus derechos (</span><a href="http://www.agpd.es/"><span>www.agpd.es </span></a><span>- C/ Jorge Juan, 6 de Madrid).</span></p>
<br /> <br />
<h2 id="terms-uso" class="d-h3 pb-6">&iquest;Qu&eacute; t&eacute;rminos de uso aplican a los servicios contratados a trav&eacute;s de la Plataforma?</h2>
<p dir="ltr"><span>El acceso a la Plataforma, y la creaci&oacute;n voluntaria de un perfil por parte del Usuario, implica el conocimiento y la </span><span>aceptaci&oacute;n expresa e inequ&iacute;voca de los presentes T&eacute;rminos de Uso,</span><span>Pol&iacute;tica de Privacidad y la Pol&iacute;tica de Cookies por parte de todos los Usuarios</span><span>.</span><span><br /></span></p>
<p dir="ltr"><span>Para poder contratar los servicios de los proveedores o profesionales a trav&eacute;s de la Plataforma https://simpleproptech.com/servicios es indispensable que el Usuario sea mayor de edad y que &eacute;ste facilite a SIMPLE, los datos de contacto solicitados.</span></p>
<p dir="ltr"><span>Condiciones. El precio de los servicios ofertados ser&aacute; el que se publicite en la plataforma de SIMPLE, en cada momento, cuyo pago se realizar&aacute; ya sea directamente al proveedor o a SIMPLE en el momento de su contrataci&oacute;n, mediante transferencia bancaria o tarjetas de d&eacute;bito/cr&eacute;dito a trav&eacute;s de la plataforma Stripe. En todo caso, los gastos ocasionados por recibos devueltos ser&aacute;n imputados al Usuario.</span></p>
<p dir="ltr"><span>El Usuario autoriza expresamente a SIMPLE para que le remita por medios telem&aacute;ticos, a la direcci&oacute;n de correo electr&oacute;nico facilitada, el recibo de los servicios contratados y/o las facturas generadas a su nombre.</span></p>
<p dir="ltr"><span>Cancelaciones. La cancelaci&oacute;n del servicio contratado por el Usuario, cuando de acuerdo con la propia naturaleza del servicio que ofrezca el proveedor o profesional colaborador, se pudiese considerar que la ejecuci&oacute;n de la prestaci&oacute;n del servicio ha comenzado, no supondr&aacute; la devoluci&oacute;n al Usuario del precio abonado, salvo que el proveedor o empresa colaboradora tenga otra pol&iacute;tica de cancelaciones.</span></p>
<p dir="ltr"><span>Proveedores o profesionales colaboradores. SIMPLE dispone de una red de profesionales independientes que colaboran con &eacute;sta para cumplir con la ejecuci&oacute;n de los servicios contratados por el Usuario. Todos ellos se han obligado con SIMPLE a prestar sus servicios con los est&aacute;ndares de calidad adecuados y dando cumplimiento, en todo momento, a la normativa que les sea de aplicaci&oacute;n, incluyendo la contrataci&oacute;n de todas las autorizaciones, p&oacute;lizas de seguros y licencias necesarias. No obstante, no implica ni certificaci&oacute;n ni aval acerca de su fiabilidad, idoneidad y/o seguridad por parte de SIMPLE.</span></p>
<p dir="ltr"><span>Responsabilidad. Los Usuarios conocen y aceptan que SIMPLE es una plataforma tecnol&oacute;gica, cuya principal actividad es la intermediaci&oacute;n, por ello es responsabilidad de los proveedores o profesionales colaboradores prestar los servicios diligentemente con los m&aacute;s altos est&aacute;ndares de calidad y dando cumplimiento, en todo momento, a la normativa que le sea de aplicaci&oacute;n, incluyendo la contrataci&oacute;n de todas las autorizaciones y licencias necesarias, siendo estos quienes responder&aacute;n ante los Usuarios quedando obligados a indemnizar por cualesquiera da&ntilde;os y perjuicios derivados directa o indirectamente de la prestaci&oacute;n de los servicios contratados.</span><span><br /></span></p>
<p dir="ltr"><span>SIMPLE no ser&aacute; responsable (i) por el retraso en el cumplimiento de las obligaciones de los proveedores y/o profesionales colaboradores; (ii) la no ejecuci&oacute;n total o parcial o satisfactoria de la prestaci&oacute;n del servicio contratado a estos; as&iacute; como (iii) por cualquier acci&oacute;n, omisi&oacute;n o retraso originado por caso fortuito o fuerza mayor que afecte a la ejecuci&oacute;n de los servicios. En consecuencia, el Usuario se obliga a mantener en todo caso plenamente indemne a SIMPLE por cualquier reclamaci&oacute;n derivada del servicio prestado por los dichos proveedores y/o profesionales colaboradores. Al realizar la contrataci&oacute;n se establecer&aacute; una relaci&oacute;n directa entre el Usuario y el tercero colaborador, siendo SIMPLE ajena a la misma, facilitando &uacute;nicamente su intermediaci&oacute;n para que las partes puedan comunicarse y en caso de reclamaci&oacute;n del Usuario.</span></p>
<p><span>&nbsp;</span></p>
<p><span>&nbsp;</span></p>
              </div>

            <div class="col-3 pt-13 ">
              <v-list flat>
                <v-list-item-group v-model="selectedItemUsers" color="secondary">
                  <template v-for="(item, i) in itemsUsers">
                    <v-list-item :key="'A' + i ">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      class="col-12 py-0"
                      v-if="i < itemsTerm.length - 1"
                      :key="i"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-tab-item>

          <v-tab-item v-if="tab == 2"  class="text-left d-flex justify-space-between">
               <div class="col-8 pt-13">
                    <h2 id="resonsable-tratamiento" class="d-h3">¿Quién es el Responsable del Tratamiento de sus datos personales?</h2> <br>
                <p class="d-p-1">
<ul>
<li dir="ltr" style="display:flex;">
<p dir="ltr"><span>Identidad: BRACESCO INVERSIONES, S.L. (&ldquo;Simple&rdquo;)</span></p>
</li>
<li dir="ltr" style="display:flex;">
<p dir="ltr"><span>Domicilio social: </span><span>Paseo de la Castellana n&ordm;79 piso 7, 28046, Madrid</span></p>
</li>
<li dir="ltr" style="display:flex;">
<p dir="ltr"><span>C.I.F. n&ordm;: B01893569</span></p>
</li>
<li dir="ltr" style="display:flex;">
<p dir="ltr"><span>Email: </span><a href="mailto:hello@simpleproptech.com"><span>hello@simpleproptech.com</span></a></p>
</li>
<li dir="ltr" style="display:flex;">
<p dir="ltr"><span>Tel&eacute;fono: 919 049 444</span></p>
</li>
</ul>
</p>
<br>
 <h2 class="d-h3" id="finalidad-datos">¿Con qué finalidad tratamos sus datos personales?</h2> <br>
<p dir="ltr"><span>Tratamos la informaci&oacute;n que nos facilita el propio proveedor para la gesti&oacute;n de los servicios contratados y de los cobros y pagos consecuencia de los mismos.</span></p>
<br>
<br>
 <h2  id="tiempo-datos" class="d-h3">¿Por cuánto tiempo conservaremos sus datos?</h2> <br>
<p dir="ltr"><span>Los datos personales proporcionados por el proveedor se conservar&aacute;n durante el plazo de diez (10) a&ntilde;os a contar desde la finalizaci&oacute;n de la relaci&oacute;n contractual de conformidad con la normativa vigente.</span><span><br /></span></p>
<br><br>
<h2 id="legitimacion" class="d-h3">¿Cuál es la legitimaci&oacute;n para el tratamiento de sus datos?</h2> <br>
<p dir="ltr"><span>La base legal para el tratamiento de datos es la ejecuci&oacute;n de la relaci&oacute;n contractual.</span></p>
<p dir="ltr"><span>Si no se facilitan todos los datos de car&aacute;cter personal requeridos por Simple a lo largo de la relaci&oacute;n contractual, podr&iacute;a implicar la resoluci&oacute;n del contrato.</span></p>
<br><br>
<h2 id="derechos" class="d-h3">&iquest;Cu&aacute;les son sus derechos cuando nos facilita sus datos personales?</h2> <br>
<p dir="ltr"><span>El proveedor puede ejercer su derecho de acceso a los datos personales, as&iacute; como solicitar la rectificaci&oacute;n de los datos inexactos o, en su caso, solicitar su supresi&oacute;n cuando los datos ya no sean necesarios para los fines que fueron recogidos.</span></p>
<p dir="ltr"><span>Tambi&eacute;n podr&aacute; solicitar la limitaci&oacute;n, portabilidad y oposici&oacute;n del tratamiento de sus</span></p>
<p dir="ltr"><span>datos, en determinadas circunstancias y por motivos relacionados con su situaci&oacute;n particular.</span></p>
<p><span><span>&nbsp;</span></span></p>
<p dir="ltr"><span>Igualmente, podr&aacute; tiene derecho a revocar su consentimiento en cualquier momento sin que ello afecte de forma retroactiva al tratamiento de datos personales realizado hasta ese momento.</span></p>
<p><span><span>&nbsp;</span></span></p>
<p dir="ltr"><span>El Proveedor podr&aacute; ejercitar los derechos referidos anteriormente, en los t&eacute;rminos y condiciones previstos en la legislaci&oacute;n vigente, en el domicilio social de Simple o solicitarlo mediante el env&iacute;o de un correo electr&oacute;nico a </span><a href="mailto:hello@simpleproptech.com"><span>hello@simpleproptech.com</span></a></p>
<p><span><span>&nbsp;</span></span></p>
<p dir="ltr"><span>En el supuesto de que no obtenga una respuesta satisfactoria o desee mayor informaci&oacute;n al respecto de cualquiera de estos derechos, puede acudirse a la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos </span><a href="http://www.agpd.es/"><span>(www.agpd.es </span></a><span>&ndash; C/ Jorge Juan, 6 de Madrid).</span></p>
<p><span>&nbsp;</span></p>
              </div>

            <div class="col-3 pt-13 ">
              <v-list flat>
                <v-list-item-group v-model="selectedItemVendors" color="secondary">
                  <template v-for="(item, i) in itemsVendors">
                    <v-list-item :key="'B' + i">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      class="col-12 py-0"
                      v-if="i < itemsTerm.length - 1"
                      :key="i"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <img
        src="@/assets/Image/home-1920.webp"
        style="position: relative;width:100%"
      />
      <Footer />

      <v-img height="237" :src="require('@/assets/Image/home-2.webp')"></v-img>
      <Footer />
    </div>
    <v-container  v-else  class="howtoContainer">
      <div style="text-align:center;">
        <div
          class="animate__animated animate__fadeInDown"
          style="padding-top:148px;padding-bottom:95px;"
        >
          <h1 class="d-h1">
            Términos de uso y privacidad
          </h1>
        </div>
      </div>
      <div style="max-width:1366px;margin:auto;width: 90%;">
        <v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-if="tab == 0"
            class="text-left d-flex justify-space-between"
          >
            <div class="col-8 pt-13">
              <div id="Informacion">
                <h2 class="d-h3 pb-6" >Información</h2>
                <p class="d-p-1">
                  Este sitio web así como la correspondiente plataforma móvil
                  son propiedad de BRACESCO INVERSIONES, S.L. (en adelante
                  “SIMPLE”), con domicilio en calle Paseo de la Castellana nº79,
                  piso 7 C.P. 28046, Madrid y provista de CIF número B01893569
                  En todo caso, el acceso a www.simpleproptech.com por cualquier
                  usuario de Internet implica la aceptación y cumplimiento de
                  las presentes Condiciones de Uso (“CU”) y de cualquier otra
                  condición particular del producto o servicio ofertado por lo
                  que, con anterioridad al acceso y/o utilización de dichos
                  contenidos, el usuario deberá leer atentamente las CU. Para la
                  contratación de los servicios publicados y su formalización,
                  SIMPLE facilitará de forma previa las condiciones específicas
                  de los mismos, como condiciones particulares que deberán ser
                  aceptadas expresamente por el usuario y que formarán parte
                  íntegra de las presentes Condiciones Legales.
                </p>
              </div>

              <div id="Objeto">
                <h2 class="d-h3 pb-6 pt-12" >Objeto</h2>
                <p class="d-p-1">
                  Los SERVICIOS prestados por SIMPLE están dirigidos a realizar
                  acciones de intermediación, comercialización y marketing con
                  la finalidad de realizar la operación encargada por el USUARIO
                  respecto de su Inmueble. La utilización por parte del USUARIO
                  de cualquiera de los SERVICIOS de SIMPLE supone e implica el
                  conocimiento y la aceptación expresa a las presentes
                  Condiciones Generales de Uso
                </p>
              </div>

              <div id="Terminos de uso">
                <h2 class="d-h3 pb-6 pt-12" >Términos de uso</h2>
                <p class="d-p-1">
                  <span class="d-h5">Registro</span> <br />
                  El USUARIO accederá al sitio web o plataforma (la
                  “Plataforma”) y se registrará introduciendo la información
                  requerida por SIMPLE en dicho momento. <br />
                  Para poder completar el registro en la Plataforma, el USUARIO
                  deberá proporcionar los datos personales siguientes: <br />
                  ○ Nombre y apellidos <br />
                  ○ E-mail <br />
                  ○ Contraseña <br />
                  ○ Teléfono <br />
                  Una vez completado el registro, todo USUARIO podrá acceder a
                  su perfil para editar el mismo. <br />
                  Responsabilidad sobre el contenido del Perfil: Los USUARIOS
                  son completamente responsables del acceso y correcto uso de su
                  perfil y demás contenidos de la Plataforma con sujeción a la
                  legalidad vigente y a las presentes Condiciones de Uso. Los
                  USUARIOS se abstendrán de usar su perfil y el resto de los
                  contenidos de la Plataforma con fines o efectos ilícitos y que
                  sean lesivos de los derechos e intereses de terceros, o que de
                  cualquier forma puedan dañar, inutilizar, afectar o deteriorar
                  la Plataforma, sus contenidos y sus SERVICIOS, eximiendo a
                  SIMPLE de toda responsabilidad, daño o perjuicio que pudiera
                  derivarse de dicho acceso o uso ilícito por parte de terceros.
                  <br />
                  Cualquier USUARIO puede informar a SIMPLE de cualquier abuso o
                  vulneración de las presentes condiciones, a través del correo
                  electrónico hello@simpleproptech.com. El USUARIO podrá darse de baja
                  de la Plataforma comunicándolo mediante un correo electrónico
                  a través de la dirección hello@simpleproptech.com. <br />
                  <br />
                  <span class="d-h5">Servicios</span> <br />
                  Los SERVICIOS por prestar por parte de SIMPLE se detallarán en
                  el contrato deprestación de servicios que suscriba el usuario
                  con SIMPLE. Serán obligaciones del vendedor las siguientes:
                  <br />
                  ○ 1. El vendedor declara y garantiza expresamente estar en
                  posesión de todas las autorizaciones, licencias, permisos e
                  inscripciones que le sean exigibles en aplicación de la
                  normativa general y de la normativa específica necesarias para
                  la venta del inmueble, así como para suscribir y cumplir el
                  presente contrato, debiendo indemnizar a SIMPLE <br />
                  por cualquier incumplimiento que se derive de tales
                  obligaciones. En particular, el vendedor deberá contar con el
                  consentimiento de todos los copropietarios del inmueble.
                  <br />
                  Asimismo, el Vendedor declara y garantiza expresamente que se
                  halla al corriente de pago de cualesquiera obligaciones
                  exigidas al vendedor en su calidad de propietario por las
                  administraciones públicas, autoridades y demás instituciones
                  competentes de acuerdo con lo establecido en la normativa
                  vigente y que no se ha producido ningún retraso, impago o
                  aplazamiento en dichos pagos ni existe ninguna reclamación
                  administrativa por incumplimiento de tales obligaciones.
                  <br />
                  ○ 2.- El Vendedor se obliga a entregar el inmueble en las
                  condiciones pactadas y con la debida diligencia y a disponer
                  de los medios, documentación y requisitos legales para la
                  venta del inmueble; <br />
                  ○ 3.- el CLIENTE se abstendrá de llevar a cabo cualquier acto
                  que pueda, directa o indirectamente, perjudicar o dañar la
                  imagen y buena reputación de SIMPLE y/o de cualesquiera
                  compañías pertenecientes a su grupo. <br />
                  ○ 4.- El Vendedor asumirá la responsabilidad por el inmueble y
                  su venta, dejando indemne a SIMPLE de cualquier reclamación
                  realizada por terceros. Asumirá los costes y riesgos derivados
                  de la venta. <br />
                  SIMPLE no es responsable de los incumplimientos del vendedor
                  ni tampoco responde de las obligaciones que legalmente
                  competen al vendedor. SIMPLE únicamente opera como
                  intermediario poniendo en relación a ambas partes y ofreciendo
                  la máxima colaboración en los trámites de venta, pero, en
                  ningún caso, esresponsable de los incumplimientos del vendedor
                  ni de las obligaciones que legalmente corresponden al
                  vendedor. Cualquier controversia o reclamación del comprador
                  deberá dirigirse exclusivamente contra el propietario o el
                  vendedor quedando exonerado SIMPLE <br />
                  <br />
                  <span class="d-h5">Precio</span> <br />
                  El Vendedor se obliga al abono de los honorarios de SIMPLE en
                  las condicionespactadas en el contrato que suscriban las
                  partes. Todos los pagos se abonarán mediante la modalidad de
                  pago acordada entre SIMPLE y el USUARIO. Una vez aceptadas las
                  condiciones del SERVICIO y suscritoel correspondiente
                  CONTRATO, el USUARIO se obliga al pago del precio del
                  SERVICIO. <br />
                  Asimismo, El CLIENTE contrae una obligación de pago de los
                  Honorarios en los supuestos siguientes: <br />
                  ○ (i) Venta del INMUEBLE si el comprador (o terceros
                  vinculados directamente) ha sido presentado a través de SIMPLE
                  durante un periodo de 12 meses <br />
                  ○ (ii) Si durante el Periodo de Exclusividad, el CLIENTE
                  procediese directamente por su cuenta o a través de otro
                  intermediario distinto de SIMPLE a la venta del INMUEBLE.
                  <br />
                  ○ (iii) Si SIMPLE consigue una oferta por el PVP acordado Los
                  honorarios se harán efectivos a la fecha de firma del contrato
                  de Arras mediante transferencia bancaria a la cuenta designada
                  por SIMPLE ES57 2100 1574 5702 0069 4672 <br />
                  <br />
                  <span class="d-h5">Duración</span> <br />
                  La duración del contrato suscrito entre el USUARIO y SIMPLE
                  iniciará su vigencia el día de la suscripción del contrato
                  entre las partes. <br />
                  Los encargos de venta sin exclusiva estarán vigentes durante
                  el periodo de venta del inmueble, salvo manifestación en
                  contra por escrito de cualquiera de las Partes con un preaviso
                  escrito de 15 días de antelación <br />
                  Los encargos de venta con exclusiva tendrán una duración de
                  SEIS MESES, desde la firma del contrato, prorrogables
                  tácitamente salvo que medie preaviso por escrito con una
                  antelación mínima de 15 días por cualquiera de las partes.
                  <br />
                  <br />
                  <span class="d-h5">Resolución anticipada.</span> <br />
                  El Contrato suscrito entre el CLIENTE y SIMPLE podrá
                  resolverse anticipadamente según se establece a continuación:
                  <br />
                  ○ (a) En cualquier momento, por mutuo acuerdo de las Partes.
                  <br />
                  ○ (b) Por denuncia del Contrato por cualquiera de las Partes,
                  basada en el incumplimiento grave de alguna de las
                  obligaciones asumidas en virtud del presente Contrato, sin
                  perjuicio del derecho de la Parte perjudicada a optar por la
                  continuación del Contrato y exigir de la otra Parte el íntegro
                  cumplimiento de sus obligaciones y, en cualquiera de los
                  casos, la reparación del perjuicio causado por el
                  incumplimiento. <br />
                  <br />
                  <span class="d-h5"
                    >Compensación costes de intermediación</span
                  >
                  <br />
                  Se devengará la cantidad de MIL QUINIENTOS EUROS MAS IVA
                  (1.500€ más IVA) a favor de SIMPLE que deberá abonar EL
                  CLIENTE en los siguientes supuestos: ○ 1) En caso de
                  cancelación del encargo de intermediación por parte del
                  vendedor por cualquier causa, excepto el incumplimiento por
                  parte de SIMPLE de cualquiera de las obligaciones que asume
                  expresamente en el mandato de venta. <br />
                  ○ 2) En caso de cancelación de la venta por voluntad del
                  vendedor o por resolución anticipada del presente Contrato por
                  incumplimiento imputable al vendedor con anterioridad a la
                  venta del inmueble Dicha cantidad se devengará en concepto de
                  compensación por los costes de intermediación, gestiones
                  realizadas y preparación de la venta, sin perjuicio de la
                  indemnización por daños y perjuicios que pudiera
                  corresponderle, en cualquier caso.Dicha indemnización por
                  daños y perjuicios no se reclamará en el caso que el vendedor
                  haya contratado la modalidad de pago por adelantado y se haya
                  hecho efectivo dicho pago. <br />
                  <br />
                  <span class="d-h5">Derecho de desistimiento</span> <br />
                  De conformidad con lo establecido legalmente, el CLIENTE
                  dispone de 14 días naturales desde la efectiva contratación
                  del servicio mediante la firma el CONTRATO con SIMPLE para el
                  ejercicio del derecho de desistimiento. <br />
                  Para ejercer el derecho de desistimiento, el usuario tendrá
                  que notificar su decisión de desistir del servicio contratado
                  a través de una declaración inequívoca (por ejemplo, una carta
                  enviada por correo postal, fax o correo electrónico). No podrá
                  ejercerse el derecho de desistimiento en el supuesto que el
                  servicio haya sido completamente ejecutado o bien en el caso
                  de que la ejecución haya iniciado con el previo consentimiento
                  expreso del CLIENTE. <br />
                  <br />
                  <span class="d-h5">Información de publicación</span> <br />
                  Los datos publicados en la web son facilitados por el VENDEDOR
                  del inmueble, quien garantiza que toda la información que se
                  incluya en el anuncio es veraz, exacta, íntegra y actualizada.
                  Desde SIMPLE nos esforzaremos por comprobar la veracidad y
                  exactitud de los datos publicados en los anuncios, pero no
                  podemos garantizar totalmente la veracidad de los datos. Por
                  eso, el VENDEDOR se compromete a mantener los datos
                  actualizados en todo momento, notificándonos con la mayor
                  rapidez posible en caso de que algún dato esté desactualizado
                  o fuese inexacto. Al publicar un anuncio, el VENDEDOR reconoce
                  ser el propietario del inmueble que anuncia o estar
                  debidamente autorizado por el propietario del inmueble para su
                  publicación y será el único responsable de la veracidad de los
                  datos publicados, por lo que responderá en exclusiva ante
                  cualquier responsabilidad o daño que pudiera derivarse al
                  respecto. Sin embargo, SIMPLE será responsable de los errores
                  en los datos publicados que no se correspondan con los
                  facilitados por el propietario. <br />
                  <br />
                  <span class="d-h5"
                    >Derechos de propiedad intelectual e industrial</span
                  >
                  <br />
                  SIMPLE es titular o licenciataria de todos los derechos de
                  propiedad intelectual e industrial incluidos en la Plataforma,
                  así como sobre los contenidos accesibles a través de la misma.
                  Los derechos de propiedad intelectual de la Plataforma, así
                  como: textos, imágenes, fotografías, diseño gráfico,
                  estructura de navegación, información y contenidos que se
                  recogen en ella son titularidad de SIMPLE, a quien corresponde
                  el ejercicio exclusivo de los derechos de explotación de estos
                  en cualquier forma y, en especial, los derechos de
                  reproducción, distribución, comunicación pública y
                  transformación, de conformidad con la legislación española de
                  derechos de propiedad intelectual e industrial. La
                  autorización al USUARIO para el acceso a la Plataforma no
                  supone renuncia, transmisión, licencia o cesión total ni
                  parcial sobre derechos de propiedad intelectual o industrial
                  por parte de SIMPLE. No está permitido suprimir, eludir o
                  manipular de ningún modo los contenidos de la Plataforma de
                  SIMPLE. <br />
                  Asimismo, está prohibido modificar, copiar, reutilizar,
                  explotar, reproducir, comunicar públicamente, hacer segundas o
                  posteriores publicaciones, cargar archivos, enviar por correo,
                  transmitir, usar, tratar o distribuir de cualquier forma la
                  totalidad o parte de los contenidos incluidos en la Plataforma
                  para propósitos públicos o comerciales, si no se cuenta con la
                  autorización expresa y por escrito de SIMPLE o, en su caso,
                  del titular de los derechos a que corresponda. <br />
                  El USUARIO que proceda a compartir cualquier tipo de contenido
                  a través de la Plataforma, asegura que ostenta los derechos
                  necesarios para hacerlo, quedando exento SIMPLE de cualquier
                  responsabilidad sobre el contenido y legalidad de la
                  información ofrecida. La facilitación de contenidos por parte
                  de los USUARIOS a través de la Plataforma supondrá la cesión a
                  SIMPLE, con carácter gratuito, y con la máxima amplitud
                  permitida por la legislación vigente, de los derechos de
                  explotación de propiedad intelectual o industrial derivados de
                  tales contenidos. El usuario se obliga a usar los contenidos
                  de forma diligente, correcta y lícita y, en particular, se
                  compromete a abstenerse de suprimir, eludir o manipular el
                  "copyright" y demás datos identificativos de los derechos de
                  Simple o de sus titulares incorporados a los contenidos, así
                  como a los dispositivos técnicos de protección o cualesquiera
                  mecanismos de información que pudieren incluirse en los
                  contenidos. Asimismo, el usuario se abstendrá de emplear los
                  contenidos y, en particular, la información de cualquier clase
                  obtenida a través del Sitio Web para remitir publicidad,
                  comunicaciones con fines comerciales, mensajes no solicitados
                  dirigidos a una pluralidad de personas con independencia de su
                  finalidad, así como a abstenerse de comercializar o divulgar
                  de cualquier modo dicha información. SIMPLE informa que no
                  concede licencia o autorización implícita alguna sobre
                  losderechos de propiedad intelectual y/o industrial o sobre
                  cualquier otro derecho o propiedad relacionada, directa o
                  indirectamente, con los contenidos incluidos en la web.<br />
                  <br />
                  <span class="d-h5">Protección de datos personales</span>
                  <br />
                  SIMPLE declara que cumple la normativa vigente respecto a la
                  protección de datos, en particular, con lo establecido en el
                  Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo,
                  de 27 de abril de 2016, relativo a la protección de las
                  personas físicas en lo que respecta al tratamiento de datos
                  personales y a la libre circulación de estos datos, Ley
                  Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
                  Personales y garantía de los derechos digitales. Por ello, le
                  informamos que sus datos personales quedarán incorporados y
                  serán tratados en los ficheros de SIMPLE, con el fin de
                  poderle prestar y ofrecer nuestros servicios. El usuario podrá
                  ejercitar los derechos de acceso, rectificación, cancelación y
                  oposición frente a SIMPLE, en cualquier momento y de forma
                  gratuita, para ello deberán enviarnos una comunicación
                  escrita, concretando el derecho que ejercita, junto a un
                  documento acreditativo de su identidad (DNI/NIE o pasaporte),
                  a cualquiera de las siguientes: <br />
                  -Por correo postal a SIMPLE (RGPD: Ejercicio de derechos)
                  calle Paseo de la Castellana nº79, piso 7 C.P. 28046, Madrid.
                  <br />
                  -Por correo electrónico hello@simpleproptech.com con asunto RGPD:
                  Ejercicio de derechos. Asimismo, te informamos de que tiene
                  derecho a presentar una reclamación ante la Agencia Española
                  de Protección de Datos si consideras que hemos cometido una
                  infracción de la legislación en materia de protección de datos
                  respecto al tratamiento de tus datos personales. <br />
                  Mientras el USUARIO no comunique lo contrario a SIMPLE, ésta
                  entenderá que sus datos no han sido modificados, que el
                  USUARIO se compromete a notificar a SIMPLE cualquier variación
                  y que SIMPLE tiene el consentimiento para utilizarlos afin de
                  poder fidelizar la relación entre las partes. <br />
                  <br />
                  <span class="d-h5"
                    >Modificación de las condiciones de uso</span
                  >
                  <br />
                  SIMPLE se reserva el derecho de modificar, en cualquier
                  momento, la presentación y configuración del Sitio Web, así
                  como las presentes CU. Por ello, SIMPLE recomienda al CLIENTE
                  leerlas atentamente cada vez que acceda al Sitio Web. Los
                  CLIENTES siempre dispondrán de las CU en un sitio visible,
                  libremente accesible para cuantasconsultas quieran realizar.
                  En caso de que cualquier cláusula de las presentes CU sea
                  declarada nula, las demás cláusulas seguirán vigentes y se
                  interpretarán teniendo en cuenta la voluntad de las partes y
                  la finalidad misma de las presentesCU. SIMPLE podrá no
                  ejercitar alguno de los derechos y facultades conferidos en
                  estedocumento lo que no implicará en ningún caso la renuncia a
                  los mismos salvo reconocimiento expreso por parte de SIMPLE o
                  prescripción de la acción que en cada caso corresponda. <br />
                  SIMPLE no garantiza la inexistencia de interrupciones o
                  errores en el acceso de la Plataforma o a su contenido, ni que
                  ésta se encuentre siempre actualizada, no obstante, SIMPLE
                  llevará a cabo, siempre que no concurran causas que lo hagan
                  imposible o de difícil ejecución, y tan pronto tenga noticia
                  de los errores, desconexiones o falta de actualización en los
                  contenidos, todas aquellas labores tendentes a subsanar los
                  errores, restablecer la comunicación y actualizar los
                  contenidos. <br />
                  <br />
                  <span class="d-h5">Ley aplicable y jurisdicción</span> <br />
                  Estas CU se rigen por la ley española. La relación entre
                  SIMPLE y el USUARIO, se regirá e interpretará de conformidad
                  con las Condiciones Generales que en materia de
                  interpretación, validez y ejecución se regirán por la
                  legislación española. <br />
                  <br />
                  <span class="d-h5">Resolución del Contrato</span>
                </p>

                <p class="d-p-1">
                  El contrato podrá ser resuelto por acuerdo entre las partes o
                  por denuncia del Contrato por cualquiera de las Partes, basada
                  en el incumplimiento grave de alguna de las obligaciones
                  asumidas en virtud del presente Contrato, sin perjuicio del
                  derecho de la Parte cumplidora a optar por la continuación del
                  Contrato y exigir de la otra Parte el íntegro cumplimiento de
                  sus obligaciones y, en cualquiera de los casos, la reparación
                  del perjuicio causado por el incumplimiento. <br />
                  <br />

                  En caso de desistimiento unilateral por parte del vendedor o
                  de cancelación del encargo de intermediación por parte del
                  vendedor por cualquier causa no imputable a SIMPLE y en
                  particular, en caso de cancelación de la venta por voluntad
                  del vendedor o por resolución anticipada del presente Contrato
                  por incumplimiento imputable al vendedor con anterioridad a la
                  venta del inmueble, el vendedor pagará a SIMPLE la suma de MIL
                  (1.500) EUROS + IVA, como compensación por los costes de
                  intermediación, gestiones realizadas y preparación de la
                  venta, sin perjuicio de la indemnización por daños y
                  perjuicios que pudiera corresponderle en cualquier caso.
                  <br />
                  1. Serán obligaciones del vendedor las siguientes: El vendedor
                  declara y garantiza expresamente estar en posesión de todas
                  las autorizaciones, licencias, permisos e inscripciones que le
                  sean exigibles en aplicación de la normativa general y de la
                  normativa específica necesarias para la venta del inmueble,
                  así como para suscribir y cumplir el presente contrato,
                  debiendo indemnizar a SIMPLE por cualquier incumplimiento que
                  se derive de tales obligaciones. En particular, el vendedor
                  deberá contar con el consentimiento de todos los
                  copropietarios del inmueble. Asimismo, el Vendedor declara y
                  garantiza expresamente que se halla al corriente de pago de
                  cualesquiera obligaciones exigidas al vendedor en su calidad
                  de propietario por las administraciones públicas, autoridades
                  y demás instituciones competentes de acuerdo con lo
                  establecido en la normativa vigente y que no se ha producido
                  ningún retraso, impago o aplazamiento en dichos pagos ni
                  existe ninguna reclamación administrativa por incumplimiento
                  de tales obligaciones. <br />
                  2. El Vendedor venderá el inmueble en las condiciones
                  estipuladas en el presente contrato. <br />
                  3. El Vendedor se obliga a entregar el inmueble en las
                  condiciones pactadas en este contrato y con la debida
                  diligencia y a disponer de los medios, documentación y
                  requisitos legales para la venta del inmueble; el Vendedor se
                  abstendrá de llevar a cabo cualquier acto que pueda, directa o
                  indirectamente, perjudicar o dañar la imagen y buena
                  reputación de SIMPLE y/o de cualesquiera compañías
                  pertenecientes a su grupo. <br />
                  4. El Vendedor asumirá la responsabilidad por el inmueble y su
                  venta, dejando indemne a SIMPLE de cualquier reclamación
                  realizada por terceros. Asumirálos costes y riesgos derivados
                  de la venta. <br />
                  5. El Vendedor se obliga al abono de los honorarios de SIMPLE
                  en lascondiciones pactadas en este contrato. <br />
                  6. El Vendedor se obliga a facilitar un correo electrónico a
                  efectos de notificaciones.
                </p>
              </div>
              <div id="Politica de cookies">
                <h2 class="d-h3 pb-6 pt-12" >
                  Política de cookies
                </h2>
                <p class="d-p-1">
                  <span class="d-h5">¿Qué es una Cookie?</span> <br />
                  Una Cookie es un fichero que se descarga en su ordenador al
                  acceder a determinadas páginas web. Las cookies permiten a una
                  página web, entre otras cosas, almacenar y recuperar
                  información sobre los hábitos de navegación de un USUARIO o de
                  su equipo y, dependiendo de la información que contenga y de
                  la forma en que utilice su equipo, pueden utilizarse para
                  reconocer al USUARIO. Las cookies son esenciales para el
                  funcionamiento de internet, aportando innumerables ventajas en
                  la prestación de SERVICIOS interactivos, facilitándole la
                  navegación y usabilidad de nuestra web. La información que le
                  proporcionamos a continuación le ayudará a comprender los
                  diferentes tipos de cookies:
                  <br />
                  <br />
                  <span class="d-h5">TIPOS DE COOKIES</span> <br />
                  SEGÚN LA ENTIDAD QUE LAS GESTIONE <br />
                  Cookies propias: Son aquellas que se recaban por el propio
                  editor para prestar el servicio solicitado por el USUARIO.
                  <br />
                  Cookies de terceros: Son aquellas que son recabadas y
                  gestionadas por un tercero, estas no se pueden considerar
                  propias. <br />
                  SEGÚN EL PLAZO DE TIEMPO QUE PERMANEZCAN ACTIVADAS <br />
                  Cookies de sesión: Recaban datos mientras el USUARIO navega
                  por la red con la finalidad de prestar el servicio solicitado.
                  <br />
                  Cookies persistentes: Se almacenan en el terminal y la
                  información obtenida, será utilizada por el responsable de la
                  cookie con la finalidad de prestar el servicio solicitado.
                  <br />
                  SEGÚN SU FINALIDAD <br />
                  Cookies técnicas: Son las necesarias para la correcta
                  navegación por la web. <br />
                  Cookies de personalización: Permiten al USUARIO las
                  características (idioma) para la navegación por la website.
                  <br />
                  Cookies de análisis: Permiten al prestador el análisis
                  vinculado a la navegación realizada por el USUARIO, con la
                  finalidad de llevar un seguimiento de uso de la página web,
                  así como realizar estadísticas de los contenidos más
                  visitados, número de visitantes, etc. <br />
                  Cookies publicitarias: Permiten al editor incluir en la web,
                  espacios publicitarios, según el contenido de la propia web.
                  <br />
                  Cookies de publicidad comportamental: Permiten al editor
                  incluir en la página web espacios publicitarios según la
                  información obtenida a través de los hábitos de navegación del
                  USUARIO. <br />
                  Según lo dispuesto en el artículo 22.2 de la Ley 34/2002, de
                  11 de julio, de SERVICIOS de la Sociedad de la Información y
                  de Comercio Electrónico (LSSI-CE), SIMPLE informa de las
                  cookies utilizadas en nuestra website:
                  <table class="text-left">
                  <tr class="d-flex">
                    <th class="col-3">Cookie</th>
                    <th class="col-3">Descripción</th>
                    <th class="col-3">Duracón</th>
                    <th class="col-3">Fuente</th>
                  </tr>
                  <tr class="d-flex">
                    <td class="col-3">_ga, _gat, _gid</td>
                    <td class="col-3">
                      Cookies de análisis que se almacenan identificadores
                      anónimos y marcas temporales para conseguir métricas que
                      ayuden a SIMPLE a comprender el comportamiento de los
                      Usuarios.
                    </td>
                    <td class="col-3">2 años</td>
                    <td class="col-3">Google(Analytic s)</td>
                  </tr>
                    <tr class="d-flex">
                    <td class="col-3">_fbp</td>
                    <td class="col-3">Utilizado por Facebook para
                        ofrecer una serie de productos
                        publicitarios, como ofertas en
                        tiempo real de terceros
                        anunciantes</td>
                    <td class="col-3">1 día </td>
                     <td class="col-3">Facebook</td>
                  </tr>
                    <tr class="d-flex">
                    <td class="col-3">is-logged-in,
                    SIMPLE_session</td>
                    <td class="col-3">Cookie técnica de SIMPLE
                    paramantener la sessión del
                    usuario entre página</td>
                    <td class="col-3">1 años</td>
                     <td class="col-3">SIMPLE</td>
                  </tr>
                  </table>
                  Asimismo, SIMPLE informa al USUARIO de que tiene la posibilidad de configurar su
                  navegador de modo que se le informe de la recepción de cookies, pudiendo, si así lo
                  desea, impedir que sean instaladas en su disco duro.  <br /> <br />
                   <span class="d-h5">CONFIGURACIÓN DE COOKIES</span> <br />
                   La legislación vigente nos obliga a solicitar la conformidad del USUARIO para poder
gestionar las cookies. En caso de que el USUARIO decida no autorizar su uso,
SIMPLE no almacenaría ninguna cookie y tan sólo se usarían las cookies técnicas
dado que sin ellas no es posible navegar por la web. En caso de seguir navegando
por la web yno denegar la autorización, dicha actividad implicará la aceptación de su
uso. <br />
El USUARIO puede restringir, bloquear o borrar las cookies de SIMPLE o
cualquierotra página web, utilizando su navegador. En cada navegador la
operativa es diferente, a continuación, se facilitan los enlaces de distintos
navegadores sobrecómo realizar dichas modificaciones:
<br />
Internet Explorer: <br />
http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=ie10
<br />
FireFox: <br />
http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we <br />
Chrome: <br /> https://support.google.com/chrome/answer/95647?hl=es <br />
Safari:  <br /> www.apple.com/es/privacy/use-of-cookies/ <br />
En caso de realizar modificaciones en la configuración de las cookies, SIMPLE
informa que no asegura el correcto funcionamiento de las distintas funcionalidades
de la web. <br />
 <span class="d-h5">Política de privacidad de la plataforma</span> <br />
 Bienvenido a la Política de Privacidad de SIMPLE (la “Política”). En este documentose
describe cómo recopilamos, almacenamos, usamos, protegemos y compartimos su
información y con quién la compartimos.  <br />
¿Quién es el responsable del Tratamiento de sus datos personales? <br />
● Identidad: BRACESCO INVERSIONES, S.L. (“SIMPLE”)  <br /> 
● Domicilio social: calle Paseo de la Castellana nº79 piso 7 Madrid <br />
● C.I.F. nº: B-01893569.  <br />
● Email: hello@simpleproptech.com. <br />
● Teléfono: (+34) 919049444  <br />
¿Con qué finalidad tratamos sus datos personales? <br>
Tratamos la información que nos facilita el usuario con la finalidad de gestionar las
operaciones de compraventa de inmuebles solicitada por el usuario y el envío de
comunicaciones comerciales electrónicas. <br>
En los formularios del Sitio Web, será necesario que el usuario cumplimente los
campos que SIMPLE requiera como obligatorios ya que, en caso contrario, el
usuario no podrá ser registrado en SIMPLE. El usuario debe comunicar a SIMPLE sus
datos personales actuales a fin de que la información contenida en sus ficheros
esté actualizada y sin errores <br>
¿Por cuánto tiempo conservaremos sus datos? <br>
Los datos personales proporcionados se conservarán durante el plazo de diez (10)
años de conformidad con la normativa vigente <br>
¿Cuál es la legitimación para el tratamiento de sus datos? <br>
La base legal para el tratamiento de datos es el consentimiento del usuario prestado
mediante la aceptación de la cláusula de tratamiento de datos, así como la
ejecución de la relación contractual. <br>
¿A qué destinatarios se comunicarán sus datos? <br>
Sus datos también pueden estar alojados en Estados Unidos con MAILCHIMP por el
servicio de mailing, al amparo del acuerdo EU-US "Privacy Shield" disponible en:
https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active <br>
Finalmente, sus datos también pueden comunicarse a Celerity Europe AG para el
envío de SMS. Dicha entidad tiene los datos alojados en Suiza, país que ofrece nivel
de protección equiparable según Decisión 2000/518/CE de la Comisión, de 26 de
julio de 2000. <br>
¿Cuáles son sus derechos cuando nos facilita sus datos personales? <br>
El usuario puede ejercer su derecho de acceso a los datos personales, así como
solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión
cuando los datos ya no sean necesarios para los fines que fueron recogidos.
También podrá solicitar la limitación, portabilidad y oposición del tratamiento de sus
datos, en determinadas circunstancias y por motivos relacionados con su situación
particular. <br><br>
Igualmente, tiene derecho a revocar su consentimiento en cualquier momento sin
que ello afecte de forma retroactiva al tratamiento de datos personales realizado
hasta ese momento. <br><br>

El usuario podrá ejercitar los derechos referidos anteriormente, en los términos y
condiciones previstos en la legislación vigente, en el domicilio social de SIMPLE o
solicitarlo mediante el envío de correo electrónico a hello@simpleproptech.com. <br><br>

En el supuesto de que no obtenga una respuesta satisfactoria y desee formular una
reclamación u obtener más información al respecto de cualquiera de estos derechos, puede acudirse a la Agencia Española de Protección de Datos
(www.agpd.es - C/ Jorge Juan, 6 de Madrid). <br><br>
                </p>
              </div>
            </div>

            <div class="col-3 pt-13 ">
              <v-list flat style="position: sticky;position: -webkit-sticky;
  position: sticky;
  top: -1px;">
                <v-list-item-group v-model="selectedItem" color="secondary">
                  <template v-for="(item, i) in itemsTerm">
                    <v-list-item :key="i">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      class="col-12 py-0"
                      v-if="i < itemsTerm.length - 1"
                      :key="i"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-tab-item>

          <v-tab-item   v-if="tab == 1"  class="text-left d-flex justify-space-between">
               <div class="col-8 pt-13">
                   <h2 id="responsable" class="d-h3 pb-6">¿Qui&eacute;n es el responsable del Tratamiento de los datos personales de los Usuarios?</h2>
<ul>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Identidad: BRACESCO INVERSIONES, S.L. (&ldquo;SIMPLE&rdquo;)</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Plataforma: https://simpleproptech.com</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>NIF: B01893569</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Direcci&oacute;n Postal: C/ Paseo de la Castellana n&ordm;79 piso 7, 28046, Madrid</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Tel&eacute;fono: (+34) 919049444</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Contacto: </span><a href="mailto:hello@simpleproptech.com"><span>hello@simpleproptech.com</span></a></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Legitimaci&oacute;n: Cumplimiento de la relaci&oacute;n contractual, inter&eacute;s leg&iacute;timo y consentimiento del Usuario</span></p>
</li>
</ul>
<p dir="ltr"><span>En la presente pol&iacute;tica de privacidad se facilita informaci&oacute;n relativa al tratamiento de los datos personales de nuestros Usuarios de conformidad con lo previsto a la normativa aplicable, en particular, en el Reglamento General de Protecci&oacute;n de Datos (&ldquo;RGPD&rdquo;)</span></p>
<br /><br />
 <h2 id="datos-usuarios" class="d-h3">¿Qu&eacute; datos de los Usuarios tratamos?</h2>
<br>
<p dir="ltr"><span>SIMPLE &uacute;nicamente recoger&aacute; aquellos datos personales que el Usuario desee revelar voluntariamente, y, de acuerdo con la normativa vigente en materia de protecci&oacute;n de datos, as&iacute; como en materia de servicios de la sociedad de la informaci&oacute;n y de comercio electr&oacute;nico, comunicar&aacute; que dichos datos personales aportados en el momento de su registro, o cualquier otro facilitado a SIMPLE para su acceso a algunos de los servicios de la Plataforma, ser&aacute;n incorporados en ficheros titularidad de SIMPLE.</span></p>
<ul>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Datos de contacto y registro</span><span>: Datos que el Usuario nos facilita cuando se pone en contacto y/o se registra con SIMPLE: por ejemplo, nombre y apellidos, direcci&oacute;n, ciudad, correo electr&oacute;nico y tel&eacute;fono. Aparte SIMPLE tendr&aacute; acceso a la informaci&oacute;n adicional facilitada ya sea a trav&eacute;s del formulario de contacto, mediante correo electr&oacute;nico, WhatsApp o a trav&eacute;s de atenci&oacute;n al cliente de forma telef&oacute;nica.</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Informaci&oacute;n adicional compartida por el Usuario</span><span>: El Usuario podr&aacute; facilitar informaci&oacute;n adicional a SIMPLE para que esta intermedie en la contrataci&oacute;n de servicios de proveedores y/o empresas colaboradoras (mudanza, contrataci&oacute;n</span><span><br /></span>
<span>alarma, etc.) u otros fines como datos de facturaci&oacute;n o incidencias. SIMPLE, si lo estima conveniente, llevar&aacute; a cabo la transcripci&oacute;n y grabaci&oacute;n de las conversaciones mantenidas con el Usuario para la tramitaci&oacute;n de incidencias, dudas u otras consultas que pudiera realizar.</span></p>
</li>
<li dir="ltr" style="display:flex">
<p dir="ltr"><span>Datos derivados de otras fuentes</span><span>: Si el Usuario llega a la Plataforma de SIMPLE a trav&eacute;s de una fuente externa (como ser&iacute;a por ejemplo un enlace de otra p&aacute;gina web o de una red social como Instagram, Facebook, etc.), SIMPLE recopila los datos de dicha fuente. Asimismo, si el Usuario accede a la Plataforma a trav&eacute;s de los productos y servicios ofrecidos por Google, &eacute;sta podr&aacute; enviar datos de navegaci&oacute;n del Usuario a SIMPLE, con el acceso a la Plataforma a trav&eacute;s de los enlaces creados por Google.</span></p>
</li>
</ul>
<br /><br />
 <h2 id="finalidad" class="d-h3 pb-6">¿Con qu&eacute; finalidad y base legal utilizamos los datos?</h2>
<p dir="ltr"><span>Prestaci&oacute;n de servicios</span><span>. Gestionar el registro del Usuario en la Plataforma, mejorar los servicios y productos de SIMPLE, intermediar en la contrataci&oacute;n de la prestaci&oacute;n de servicios ofertados en la Plataforma con proveedores o empresas colaboradoras, atender consultas y a los compromisos generados por los respectivos contratos, habiendo consentido los Usuarios expresamente el tratamiento de los datos al darse de alta en la Plataforma o bien en el momento de la suscripci&oacute;n de dichos contratos.</span></p>
<p dir="ltr"><span>Comunicaciones</span><span>. SIMPLE utiliza tambi&eacute;n los datos para realizar comunicaciones v&iacute;a correo electr&oacute;nico, WhatsApp y/o llamada telef&oacute;nica al Usuario acerca de los servicios contratados, la operativa de &eacute;stos, informar de sus productos y servicios con fines comerciales.</span></p>
<p dir="ltr"><span>Fines estad&iacute;sticos y an&aacute;lisis del servicio</span><span>. SIMPLE tambi&eacute;n utiliza la informaci&oacute;n con fines estad&iacute;sticos para investigar y analizar c&oacute;mo mejorar los servicios que presta a los Usuarios.</span></p>
<p dir="ltr"><span>Cumplimiento de obligaciones legales</span><span>. En cumplimiento de las obligaciones legales que se apliquen en cada momento, SIMPLE utilizar&aacute; los datos para gestionar la facturaci&oacute;n y a efectos de contabilidad, informar a los &oacute;rganos competentes de las Administraciones P&uacute;blicas, as&iacute; como a proveedores o empresas colaboradoras para cumplir con los servicios contratados o cuando dichas autoridades competentes soliciten datos en el marco de investigaciones tributarias, judiciales y/o la interposici&oacute;n de las acciones necesarias para proteger los intereses de SIMPLE.</span><span><br /></span></p>
<br> <br>
 <h2 id="destinatarios" class="d-h3 pb-6">&iquest;A qu&eacute; destinatarios se comunicar&aacute;n sus datos?</h2>
<p dir="ltr"><span>Seg&uacute;n la finalidad con la que fueron recogidos, los datos de los Usuarios ser&aacute;n comunicados, en caso de ser necesario, a las Administraciones P&uacute;blicas, entidades p&uacute;blicas y privadas, fuerzas y cuerpos de seguridad, proveedores necesarios para el normal funcionamiento de los servicios contratados como proveedores hosting, de pago, herramientas de gesti&oacute;n interna, socios comerciales, o terceros que siguen las instrucciones de SIMPLE, para procesar la informaci&oacute;n compartida con ellos conforme a la legalidad vigente y la presente Pol&iacute;tica de Privacidad.</span></p>
<p dir="ltr"><span>Si la propiedad de SIMPLE cambiara o la mayor parte de sus activos fueran adquiridos por un tercero, los Usuarios ser&aacute;n informados que esta transferir&aacute; sus datos al adquirente con la finalidad de seguir prestando los servicios objeto del tratamiento de datos. En todo caso, el nuevo responsable comunicar&aacute; a los Usuarios sus datos de identificaci&oacute;n.</span></p>
<p dir="ltr"><span>Los datos tambi&eacute;n pueden estar alojados en Estados Unidos con MailChimp por el servicio de emailing, al amparo del acuerdo EU-EE.UU. (Privacy Shield) o ser comunicados a Celerity Europe AG para el env&iacute;o de SMS cuyos datos se encuentran alojados en Suiza, pa&iacute;s que ofrece nivel de protecci&oacute;n equiparable seg&uacute;n Decisi&oacute;n 2000/518/CE de la Comisi&oacute;n, de 26 de julio de 2000.</span></p>
<p dir="ltr"><span>Para que el Usuario pueda contratar los servicios de proveedores de SIMPLE, ofrecidos a trav&eacute;s de la Plataforma, ciertos datos de car&aacute;cter personal de los Usuarios podr&aacute;n ser compartidos con:</span></p>
<p dir="ltr"><span>Proveedores y profesionales colaboradores</span><span> que ofrecen sus servicios a los Usuarios a trav&eacute;s de la Plataforma https://SIMPLE.com/servicios, entre otros, HolaLuz, Boxmotions, Tyco Integrated Security, Aqu&iacute; tu reforma, Grupo BC, etc., con los que se ha suscrito un contrato que deber&aacute;n tratar seg&uacute;n, contrato suscrito con SIMPLE, la informaci&oacute;n de conformidad con la presente Pol&iacute;tica de Privacidad y la legislaci&oacute;n aplicable en la materia de protecci&oacute;n de datos.</span></p>
<p dir="ltr"><span>Proveedores de servicios de pago</span><span>. Los proveedores de servicios de pago han sido elegidos en base a sus medidas de seguridad y cumpliendo, en todo caso, las medidas de seguridad establecidas por la normativa de servicios de pago.</span><span><br /></span></p>
<p dir="ltr"><span>Compa&ntilde;&iacute;as aseguradoras</span><span>. SIMPLE podr&aacute; comunicar los datos de los Usuarios a las compa&ntilde;&iacute;as aseguradoras con las que SIMPLE tengan un acuerdo para la gesti&oacute;n y tramitaci&oacute;n de reclamaciones y siniestros derivados de su actividad.</span></p>
<p dir="ltr"><span>En el supuesto que el Usuario contacte directamente con los proveedores o empresas colaboradoras y facilite sus datos directamente a &eacute;stos, SIMPLE no ser&aacute; responsable por el uso que &eacute;stos hagan.</span></p>
<br /><br />
 <h2 id="tiempo" class="d-h3 pb-6">&iquest;Por cu&aacute;nto tiempo ser&aacute;n conservados los datos?</h2>
<p dir="ltr"><span>Los datos de los Usuarios ser&aacute;n conservados durante la ejecuci&oacute;n y mantenimiento de la relaci&oacute;n contractual, esto es, mientras sigan siendo Usuarios de SIMPLE o hasta que ejercites tu derecho de limitaci&oacute;n.</span></p>
<p dir="ltr"><span>En caso de darse de baja de la Plataforma, SIMPLE conservar&aacute; los datos de los Usuarios durante el per&iacute;odo legal establecido en la normativa que fuera de aplicaci&oacute;n, para la ejecuci&oacute;n y defensa de acciones en las que SIMPLE pudiera ser parte.</span></p>
<br /><br />
 <h2 id="derechos-usuario" class="d-h3 pb-6">&iquest;Qu&eacute; derechos puede ejercer el Usuario?</h2>
<p dir="ltr"><span>Como consecuencia del tratamiento de los datos personales de los Usuarios por parte de SIMPLE, la legislaci&oacute;n vigente otorga a estos una serie de derechos. En particular, los Usuarios podr&aacute;n ejercitar ante SIMPLE los siguientes derechos:</span></p>
<ol>
<li dir="ltr">
<p dir="ltr"><span>Derecho de acceso: El Usuario tendr&aacute; derecho a conocer los datos personales que son tratados por SIMPLE, as&iacute; como las finalidades de dichos tratamientos.</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>Derecho de rectificaci&oacute;n: El Usuario podr&aacute; solicitar en cualquier momento la rectificaci&oacute;n de un dato si los que constaran registrados fueran err&oacute;neos o inexactos.</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>Derecho de supresi&oacute;n: Solicitar, en cualquier momento, la supresi&oacute;n de los datos de los ficheros de SIMPLE, cuando esto sea posible de conformidad con la legislaci&oacute;n vigente.</span><span><br /></span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>Derecho de oposici&oacute;n: El Usuario podr&aacute; oponerse al tratamiento de sus datos en relaci&oacute;n con cualesquiera finalidades con las que SIMPLE trate los datos, o retirar el consentimiento para la recepci&oacute;n de comunicaciones comerciales.</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>Derecho a solicitar la limitaci&oacute;n del tratamiento: El Usuario podr&aacute; solicitar la limitaci&oacute;n del tratamiento de sus datos personales cuando no sean correctos o exactos, haya dudas de la legitimidad del tratamiento o se opte por limitar el tratamiento a su eliminaci&oacute;n. Tambi&eacute;n cuando los datos ya no son necesarios conforme a la finalidad para la que fueron recabados, pero el Usuario precisa que SIMPLE los conserve para interponer acciones legales.</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>Derecho a la portabilidad de los datos: El Usuario tendr&aacute; derecho a, siempre que t&eacute;cnicamente sea posible y razonable, solicitar que los datos sean comunicados a otro responsable del tratamiento. El ejercicio de los derechos que ostenta el Usuario podr&aacute; hacerse efectivo dirigi&eacute;ndose a SIMPLE a trav&eacute;s de alguno de los siguientes medios, acompa&ntilde;ando copia de su DNI o documento identificativo equivalente mediante:</span></p>
</li>
</ol>
<p dir="ltr"><span>Correo electr&oacute;nico que deber&aacute; dirigir a la siguiente direcci&oacute;n: </span><a href="mailto:hello@simpleproptech.com"><span>hello@simpleproptech.com</span></a><span>Correo postal dirigido a calle Paseo de la Castellana n&ordm;79 piso 7, 28046, Madrid</span></p>
<p dir="ltr"><span>Tambi&eacute;n podr&aacute; ponerse en contacto con nuestro Delegado de Protecci&oacute;n de Datos (DPO) de SIMPLE, mediante direcci&oacute;n de correo electr&oacute;nico </span><a href="mailto:hello@simpleproptech.com"><span>hello@simpleproptech.com</span></a></p>
<p dir="ltr"><span>O presentar reclamaci&oacute;n ante la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos y reclamar ante este organismo la protecci&oacute;n de tus derechos (</span><a href="http://www.agpd.es/"><span>www.agpd.es </span></a><span>- C/ Jorge Juan, 6 de Madrid).</span></p>
<br /> <br />
<h2 id="terms-uso" class="d-h3 pb-6">&iquest;Qu&eacute; t&eacute;rminos de uso aplican a los servicios contratados a trav&eacute;s de la Plataforma?</h2>
<p dir="ltr"><span>El acceso a la Plataforma, y la creaci&oacute;n voluntaria de un perfil por parte del Usuario, implica el conocimiento y la </span><span>aceptaci&oacute;n expresa e inequ&iacute;voca de los presentes T&eacute;rminos de Uso,</span><span>Pol&iacute;tica de Privacidad y la Pol&iacute;tica de Cookies por parte de todos los Usuarios</span><span>.</span><span><br /></span></p>
<p dir="ltr"><span>Para poder contratar los servicios de los proveedores o profesionales a trav&eacute;s de la Plataforma https://simpleproptech.com/servicios es indispensable que el Usuario sea mayor de edad y que &eacute;ste facilite a SIMPLE, los datos de contacto solicitados.</span></p>
<p dir="ltr"><span>Condiciones. El precio de los servicios ofertados ser&aacute; el que se publicite en la plataforma de SIMPLE, en cada momento, cuyo pago se realizar&aacute; ya sea directamente al proveedor o a SIMPLE en el momento de su contrataci&oacute;n, mediante transferencia bancaria o tarjetas de d&eacute;bito/cr&eacute;dito a trav&eacute;s de la plataforma Stripe. En todo caso, los gastos ocasionados por recibos devueltos ser&aacute;n imputados al Usuario.</span></p>
<p dir="ltr"><span>El Usuario autoriza expresamente a SIMPLE para que le remita por medios telem&aacute;ticos, a la direcci&oacute;n de correo electr&oacute;nico facilitada, el recibo de los servicios contratados y/o las facturas generadas a su nombre.</span></p>
<p dir="ltr"><span>Cancelaciones. La cancelaci&oacute;n del servicio contratado por el Usuario, cuando de acuerdo con la propia naturaleza del servicio que ofrezca el proveedor o profesional colaborador, se pudiese considerar que la ejecuci&oacute;n de la prestaci&oacute;n del servicio ha comenzado, no supondr&aacute; la devoluci&oacute;n al Usuario del precio abonado, salvo que el proveedor o empresa colaboradora tenga otra pol&iacute;tica de cancelaciones.</span></p>
<p dir="ltr"><span>Proveedores o profesionales colaboradores. SIMPLE dispone de una red de profesionales independientes que colaboran con &eacute;sta para cumplir con la ejecuci&oacute;n de los servicios contratados por el Usuario. Todos ellos se han obligado con SIMPLE a prestar sus servicios con los est&aacute;ndares de calidad adecuados y dando cumplimiento, en todo momento, a la normativa que les sea de aplicaci&oacute;n, incluyendo la contrataci&oacute;n de todas las autorizaciones, p&oacute;lizas de seguros y licencias necesarias. No obstante, no implica ni certificaci&oacute;n ni aval acerca de su fiabilidad, idoneidad y/o seguridad por parte de SIMPLE.</span></p>
<p dir="ltr"><span>Responsabilidad. Los Usuarios conocen y aceptan que SIMPLE es una plataforma tecnol&oacute;gica, cuya principal actividad es la intermediaci&oacute;n, por ello es responsabilidad de los proveedores o profesionales colaboradores prestar los servicios diligentemente con los m&aacute;s altos est&aacute;ndares de calidad y dando cumplimiento, en todo momento, a la normativa que le sea de aplicaci&oacute;n, incluyendo la contrataci&oacute;n de todas las autorizaciones y licencias necesarias, siendo estos quienes responder&aacute;n ante los Usuarios quedando obligados a indemnizar por cualesquiera da&ntilde;os y perjuicios derivados directa o indirectamente de la prestaci&oacute;n de los servicios contratados.</span><span><br /></span></p>
<p dir="ltr"><span>SIMPLE no ser&aacute; responsable (i) por el retraso en el cumplimiento de las obligaciones de los proveedores y/o profesionales colaboradores; (ii) la no ejecuci&oacute;n total o parcial o satisfactoria de la prestaci&oacute;n del servicio contratado a estos; as&iacute; como (iii) por cualquier acci&oacute;n, omisi&oacute;n o retraso originado por caso fortuito o fuerza mayor que afecte a la ejecuci&oacute;n de los servicios. En consecuencia, el Usuario se obliga a mantener en todo caso plenamente indemne a SIMPLE por cualquier reclamaci&oacute;n derivada del servicio prestado por los dichos proveedores y/o profesionales colaboradores. Al realizar la contrataci&oacute;n se establecer&aacute; una relaci&oacute;n directa entre el Usuario y el tercero colaborador, siendo SIMPLE ajena a la misma, facilitando &uacute;nicamente su intermediaci&oacute;n para que las partes puedan comunicarse y en caso de reclamaci&oacute;n del Usuario.</span></p>
<p><span>&nbsp;</span></p>
<p><span>&nbsp;</span></p>
              </div>

            <div class="col-3 pt-13 ">
              <v-list flat>
                <v-list-item-group v-model="selectedItemUsers" color="secondary">
                  <template v-for="(item, i) in itemsUsers">
                    <v-list-item :key="'A' + i ">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      class="col-12 py-0"
                      v-if="i < itemsTerm.length - 1"
                      :key="i"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-tab-item>

          <v-tab-item v-if="tab == 2"  class="text-left d-flex justify-space-between">
               <div class="col-8 pt-13">
                    <h2 id="resonsable-tratamiento" class="d-h3">¿Quién es el Responsable del Tratamiento de sus datos personales?</h2> <br>
                <p class="d-p-1">
<ul>
<li dir="ltr" style="display:flex;">
<p dir="ltr"><span>Identidad: BRACESCO INVERSIONES, S.L. (&ldquo;Simple&rdquo;)</span></p>
</li>
<li dir="ltr" style="display:flex;">
<p dir="ltr"><span>Domicilio social: </span><span>Paseo de la Castellana n&ordm;79 piso 7, 28046, Madrid</span></p>
</li>
<li dir="ltr" style="display:flex;">
<p dir="ltr"><span>C.I.F. n&ordm;: B01893569</span></p>
</li>
<li dir="ltr" style="display:flex;">
<p dir="ltr"><span>Email: </span><a href="mailto:hello@simpleproptech.com"><span>hello@simpleproptech.com</span></a></p>
</li>
<li dir="ltr" style="display:flex;">
<p dir="ltr"><span>Tel&eacute;fono: 919 049 444</span></p>
</li>
</ul>
</p>
<br>
 <h2 class="d-h3" id="finalidad-datos">¿Con qué finalidad tratamos sus datos personales?</h2> <br>
<p dir="ltr"><span>Tratamos la informaci&oacute;n que nos facilita el propio proveedor para la gesti&oacute;n de los servicios contratados y de los cobros y pagos consecuencia de los mismos.</span></p>
<br>
<br>
 <h2  id="tiempo-datos" class="d-h3">¿Por cuánto tiempo conservaremos sus datos?</h2> <br>
<p dir="ltr"><span>Los datos personales proporcionados por el proveedor se conservar&aacute;n durante el plazo de diez (10) a&ntilde;os a contar desde la finalizaci&oacute;n de la relaci&oacute;n contractual de conformidad con la normativa vigente.</span><span><br /></span></p>
<br><br>
<h2 id="legitimacion" class="d-h3">¿Cuál es la legitimaci&oacute;n para el tratamiento de sus datos?</h2> <br>
<p dir="ltr"><span>La base legal para el tratamiento de datos es la ejecuci&oacute;n de la relaci&oacute;n contractual.</span></p>
<p dir="ltr"><span>Si no se facilitan todos los datos de car&aacute;cter personal requeridos por Simple a lo largo de la relaci&oacute;n contractual, podr&iacute;a implicar la resoluci&oacute;n del contrato.</span></p>
<br><br>
<h2 id="derechos" class="d-h3">&iquest;Cu&aacute;les son sus derechos cuando nos facilita sus datos personales?</h2> <br>
<p dir="ltr"><span>El proveedor puede ejercer su derecho de acceso a los datos personales, as&iacute; como solicitar la rectificaci&oacute;n de los datos inexactos o, en su caso, solicitar su supresi&oacute;n cuando los datos ya no sean necesarios para los fines que fueron recogidos.</span></p>
<p dir="ltr"><span>Tambi&eacute;n podr&aacute; solicitar la limitaci&oacute;n, portabilidad y oposici&oacute;n del tratamiento de sus</span></p>
<p dir="ltr"><span>datos, en determinadas circunstancias y por motivos relacionados con su situaci&oacute;n particular.</span></p>
<p><span><span>&nbsp;</span></span></p>
<p dir="ltr"><span>Igualmente, podr&aacute; tiene derecho a revocar su consentimiento en cualquier momento sin que ello afecte de forma retroactiva al tratamiento de datos personales realizado hasta ese momento.</span></p>
<p><span><span>&nbsp;</span></span></p>
<p dir="ltr"><span>El Proveedor podr&aacute; ejercitar los derechos referidos anteriormente, en los t&eacute;rminos y condiciones previstos en la legislaci&oacute;n vigente, en el domicilio social de Simple o solicitarlo mediante el env&iacute;o de un correo electr&oacute;nico a </span><a href="mailto:hello@simpleproptech.com"><span>hello@simpleproptech.com</span></a></p>
<p><span><span>&nbsp;</span></span></p>
<p dir="ltr"><span>En el supuesto de que no obtenga una respuesta satisfactoria o desee mayor informaci&oacute;n al respecto de cualquiera de estos derechos, puede acudirse a la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos </span><a href="http://www.agpd.es/"><span>(www.agpd.es </span></a><span>&ndash; C/ Jorge Juan, 6 de Madrid).</span></p>
<p><span>&nbsp;</span></p>
              </div>

            <div class="col-3 pt-13 ">
              <v-list flat>
                <v-list-item-group v-model="selectedItemVendors" color="secondary">
                  <template v-for="(item, i) in itemsVendors">
                    <v-list-item :key="'B' + i">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      class="col-12 py-0"
                      v-if="i < itemsTerm.length - 1"
                      :key="i"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <img
        src="@/assets/Image/home-1920.webp"
        style="position: relative;width:100%"
      />
      <StickyShare />
      <Footer />
    </v-container>
    
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import { isMobile } from "mobile-device-detect";
import StickyShare from "@/components/StickyShare.vue"; 

export default {
  name: "HowTo",
  components: {
    Footer,
    StickyShare
  },
  data: () => ({
       mobile: isMobile ? true : false,
    tab: 0,
    items: ["Términos de uso", "Privacidad Usuarios", "Privacidad Proveedores"],
    selectedItem: 0,
    itemsTerm: [
      { text: "Información" },
      { text: "Objeto" },
      { text: "Términos de uso" },
      {text: "Política de cookies"},
    ],
    selectedItemUsers: 0,
    itemsUsers: [
      { text: "¿Quién es el responsable del Tratamiento de los datos personales de los Usuarios?" },
      { text: "¿Qué datos de los Usuarios tratamos?" },
      { text: "¿Con qué finalidad y base legal utilizamos los datos?" },
      {text: "¿A qué destinatarios se comunicarán sus datos?"},
      { text: "¿Por cuánto tiempo serán conservados los datos?" },
      { text: "¿Qué derechos puede ejercer el Usuario?" },
      { text: "¿Qué términos de uso aplican a los servicios contratados a través de la Plataforma?" },
    ],
    selectedItemVendors:0,
    itemsVendors: [
      { text: "¿Quién es el Responsable del Tratamiento de sus datos personales?" },
      { text: "¿Con qué finalidad tratamos sus datos personales?" },
      { text: "¿Por cuánto tiempo conservaremos sus datos?" },
      { text: "¿Cuál es la legitimación para el tratamiento de sus datos?"},
      { text: "¿Cuáles son sus derechos cuando nos facilita sus datos personales?" },
    ],
  }),
  methods: {},
  watch: {
    selectedItem() {
      switch (this.selectedItem) {
  case 0:
     document.getElementById("Informacion").scrollIntoView({ behavior: "smooth" });
    break;
  case 1:
      document.getElementById("Objeto").scrollIntoView({ behavior: "smooth" });
    break;
  case 2:
      document.getElementById("Terminos de uso").scrollIntoView({  behavior: "smooth" });
    break;
  case 3:
      document.getElementById("Politica de cookies").scrollIntoView({ behavior: "smooth" });
    break;
  default:
     document.getElementById("Informacion").scrollIntoView({ behavior: "smooth" });
}
    },
    selectedItemUsers() {
      switch (this.selectedItemUsers) {
  case 0:
     document.getElementById("responsable").scrollIntoView({ behavior: "smooth" });
    break;
  case 1:
      document.getElementById("datos-usuarios").scrollIntoView({ behavior: "smooth" });
    break;
  case 2:
      document.getElementById("finalidad").scrollIntoView({ behavior: "smooth" });
    break;
  case 3:
      document.getElementById("destinatarios").scrollIntoView({ behavior: "smooth" });
    break;
     case 4:
      document.getElementById("tiempo").scrollIntoView({ behavior: "smooth" });
    break;
     case 5:
      document.getElementById("derechos-usuario").scrollIntoView({ behavior: "smooth" });
    break;
     case 6:
      document.getElementById("terms-uso").scrollIntoView({ behavior: "smooth" });
    break;
  default:
     document.getElementById("responsable").scrollIntoView({ behavior: "smooth" });
}
    },
      selectedItemVendors() {
      switch (this.selectedItemVendors) {
  case 0:
     document.getElementById("resonsable-tratamiento").scrollIntoView({ behavior: "smooth" });
    break;
  case 1:
      document.getElementById("finalidad-datos").scrollIntoView({ behavior: "smooth" });
    break;
  case 2:
      document.getElementById("tiempo-datos").scrollIntoView({ behavior: "smooth" });
    break;
  case 3:
      document.getElementById("legitimacion").scrollIntoView({ behavior: "smooth" });
    break;
     case 4:
      document.getElementById("derechos").scrollIntoView({ behavior: "smooth" });
    break;
  default:
     document.getElementById("resonsable-tratamiento").scrollIntoView({ behavior: "smooth" });
}
    },
  },
  mounted() {
    setTimeout(function() {
      window.scrollTo(0, 0);
    }, 2);
  },
};
</script>

<style scoped lang="scss">
.howtoContainer {
  max-width: 100%;
  text-align: center;
  padding: 0;
  display: grid;
}

.icon {
  order: 0;
}

.header {
  order: 1;
}
</style>

<style>
.v-expansion-panel-header__icon {
  margin-right: 30px !important;
  margin-left: -24px !important;
}
.v-expansion-panel-content__wrap {
  margin-left: 35px !important;
}
.v-expansion-panel-header {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
</style>
