<template>
  <div>
    <div id="mobile" style="width:100%;" v-if="mobile">
      <div class="">
        <div class="d-flex aling-end" style="min-height:233px;flex-direction: column;position:relative">
          <img
            height="auto"
            width="100%"
            src="../assets/Image/home-1.webp"
            alt="Fondo mobile home"
            style="position:absolute;bottom:0;"
          />
        </div>
        <v-card class="mainFormMobile">
          <h2
            class="d-h3"
            style="width: 339px; text-align:left; color:black;"
          >
            Vende rápido y simple sin pagar comisión.
          </h2>          
   <v-form ref="form" v-model="isFormValid">
          <v-text-field
            class="pt-6"
            outlined
            label="Ciudad"
            color="#204E95"
            required
            :rules="cityRules"
            @focus="changeStatusMap()"
            @focusout="changeStatusMap()"
            v-model="ciudad"
          >
            <template v-slot:append>
              <v-icon :class="{ active: mapIsActive }">$IconMap</v-icon>
            </template>
          </v-text-field>
          <v-text-field
            outlined
            label="Correo electrónico"
            color="#204E95"
            v-model="email"
            :rules="emailRules"
            required
            @focus="changeStatusMail()"
            @focusout="changeStatusMail()"
          >
            <template slot="append">
              <v-icon :class="{ active: mailIsActive }">$IconMail</v-icon>
            </template>
          </v-text-field>
          <v-text-field
            outlined
            label="Teléfono"
            color="#204E95"
            :rules="phoneRules"
            required
            v-model="phone"
            @focus="changeStatusPhone()"
            @focusout="changeStatusPhone()"
          >
            <template slot="append">
              <v-icon :class="{ active: phoneIsActive }">$IconPhone</v-icon>
            </template>
          </v-text-field>

          <v-checkbox
            v-model="accept"
             :rules="acceptRules"
              required
            label="Acepto políticas de Privacidad"
            color="secondary"
            value="accept"
            hide-details
            class="pt-0 mt-0"
          ></v-checkbox>
            </v-form>
          <div class="pt-6 text-left">
            <v-btn color="secondary" @click="sendEmailNotification" class="mainButtonMobile d-buttom"
              >INFORMATE GRATIS</v-btn
            >
          </div>
          <p class="p-d-1 pt-4 text-left">
            También puedes llamarnos al
            <a href="tel:919 049 444" style="text-decoration:none"><b>919 049 444</b></a>
          </p>
        </v-card>
      </div>
      <div class="px-4" style="margin:auto">
        <p
          class="d-subtitle-2"
          style="text-align:left; margin:56px auto 0px auto;"
        >
          <b>Simple</b> es una inmobiliaria Online (PropTech) que une lo mejor del
          marketing digital con el real estate, consiguiendo vender un inmueble en menos
          tiempo de particular a particular y sin comisiones de agencia de forma
          100% digital y dinámica con procesos profesionales y transparentes que
          permiten una mejor experiencia de usuario de la mano de nuevas
          tecnologías.
        </p>
        <h4 class="d-h3 pt-6" style="color:#f52f57; text-align:left;">
          Vender tu inmueble ahora es Simple.
        </h4>
        <h3 class="d-h2" style="text-align:center;margin-top:56px;">
          ¿Cuánto puedes ahorrar en comisiones?
        </h3>
        <v-card
          class="slider-card mt-12"
          style="height:auto;background:white;box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);border-radius: 8px"
        >
          <v-row no-gutters>
            <v-col cols="12">
              <div class="pa-5 pb-0" style=";background: #FFFFFF;">
                <h4 class="d-h4" style="text-align:left;color:#204E95">
                  Precio de Venta
                </h4>
                <v-row no-gutters>
                  <v-col cols="12">
                    <vue-slider
                      ref="slider"
                      v-model="sellvalue"
                      v-bind="selloptions"
                      style="text-size: 48px;padding-top:22px;"
                    ></vue-slider>
                  </v-col>
                  <v-col class="pt-5 pb-1" cols="12">
                    <vuetify-money
                      v-model="sellvalue"
                      v-bind:outlined="true"
                      v-bind:options="options"
                      type="number"
                      class="inputPrice"
                      color="#204E95"
                    />
                  </v-col>
                </v-row>
                <h4 class="d-h4" style="text-align:left;color:#204E95">
                  Comisión de Agencia
                </h4>
                <v-row no-gutters>
                  <v-col cols="12">
                    <vue-slider
                      ref="slider"
                      v-model="comisionvalue"
                      v-bind="comisionoptions"
                      style="text-size: 48px;padding-top:22px;"
                    ></vue-slider>
                  </v-col>
                  <v-col class="pt-5" cols="12">
                    <v-text-field
                      v-model="comisionvalue"
                      outlined
                      required
                      type="number"
                      suffix="%"
                      class="inputPrice"
                      min="3"
                      max="7"
                      color="#204E95"
                      persistent-hint
                      hint="más iva"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col class="pt-0" cols="12">
              <div class="hr mx-5 mb-5" style="width:auto;"></div>
              <div style="text-align:left;">
                <h4
                  class="d-h4 text-center"
                  style="padding-bottom:20px;padding-top:0;margin:0;"
                >
                  Tu coste con:
                </h4>
                <p
                  class="d-subtitle-2 text-center"
                  style="color:#4F4F4F;padding-bottom:4px;padding-top:0;margin:0;"
                >
                  Agencia Tradicional
                </p>
                <h4
                  class="d-h4 text-center"
                  style="color:#4F4F4F;padding-bottom:30px;padding-top:0;margin:0;"
                >
                  {{ Number(total).toLocaleString() }} €
                  <span style="font-size:12px;font-weight:400;color:#828282">· más iva</span>
                </h4>
                <p
                  class="d-subtitle-2 text-center"
                  style="color:#4F4F4F;padding-bottom:4px;padding-top:0;margin:0;"
                >
                  Simple
                </p>
                <h4
                  class="d-h4 text-center"
                  style="color:#4F4F4F;padding-top:0;margin:0;"
                >
                  {{ Number(simple).toLocaleString() }} €
                  <span style="font-size:12px; font-weight:400;color:#828282">· más iva</span>
                </h4>
              </div>
              <div
                style="width: 28px;height: 28px;border-radius: 2px;transform: rotate(-45deg);background: #6383B4;position:relative;top:42px;
  right: -46%;z-index:5"
              ></div>
              <div
                class="mt-7"
                style="background: #6383B4;box-shadow: 0px 4px 10px rgba(32, 78, 149, 0.2);border-radius: 8px;position:relative;z-index:1"
              >
                <div class="px-4 pr-4 d-flex align-center" style="margin:auto;">
                  <div class="col-3">
                    <svg
                      width="58"
                      height="66"
                      viewBox="0 0 58 66"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <path
                          d="M29.6691 45.7257C38.3198 45.7257 45.3214 38.692 45.3214 30.032C45.3214 21.3721 38.3063 14.3384 29.6691 14.3384C21.032 14.3384 14.0168 21.3721 14.0168 30.032C14.0304 38.692 21.032 45.7121 29.6691 45.7257ZM29.6691 16.4932C37.1303 16.4932 43.1723 22.5511 43.1723 30.032C43.1723 37.5129 37.1303 43.5708 29.6691 43.5708C22.2079 43.5708 16.166 37.5129 16.166 30.032C16.1795 22.5511 22.2215 16.5068 29.6691 16.4932Z"
                          fill="white"
                        />
                        <path
                          d="M50.647 48.4768L42.1856 52.4883C41.0231 50.3064 38.7794 48.924 36.3058 48.8563L28.4932 48.6394C27.2632 48.5988 26.0467 48.2871 24.9383 47.7179L24.1408 47.3113C20.0723 45.1836 15.2198 45.1971 11.1648 47.3249L11.2189 45.5224C11.2324 44.9261 10.7593 44.4247 10.1646 44.4111L1.62204 44.1672C1.02731 44.1536 0.527188 44.6279 0.513672 45.2242L3.84421e-05 64.0485C-0.0134782 64.6448 0.459605 65.1462 1.05434 65.1598L9.61039 65.3902H9.63742C10.2186 65.3902 10.7052 64.9294 10.7188 64.3331L10.7458 63.4251L12.976 62.2325C13.8546 61.7581 14.8684 61.6497 15.8281 61.9207L29.1149 65.6612C29.142 65.6748 29.1555 65.6748 29.1825 65.6748C30.1422 65.878 31.1154 65.9729 32.1021 65.9729C34.1837 65.9729 36.2382 65.5257 38.117 64.6583C38.1576 64.6312 38.2117 64.6177 38.2522 64.5906L57.5135 52.0953C57.9865 51.7836 58.1487 51.1466 57.8649 50.6587C56.4186 48.138 53.2557 47.1622 50.647 48.4768ZM2.18974 63.032L2.64931 46.3626L9.0562 46.5388L8.59664 63.2082L2.18974 63.032ZM37.1303 62.7474C34.7784 63.8045 32.1697 64.1027 29.6421 63.5877L16.3958 59.8608C14.9089 59.4407 13.314 59.6168 11.9488 60.3487L10.8134 60.9585L11.1243 49.8727C14.7197 47.4739 19.3424 47.23 23.1811 49.2222L23.9786 49.6288C25.3708 50.347 26.8982 50.74 28.4661 50.7943L36.2788 51.0111C38.4685 51.0789 40.3338 52.6374 40.7798 54.7922L29.1555 54.4669C28.5608 54.4534 28.0606 54.9277 28.0471 55.524C28.0336 56.1203 28.5067 56.6218 29.1014 56.6353L41.9423 56.9877H41.9693C42.5505 56.9877 43.0371 56.5269 43.0506 55.9306C43.0641 55.4563 43.0236 54.9955 42.9425 54.5212L51.6202 50.4148C51.6337 50.4148 51.6337 50.4012 51.6472 50.4012C52.8772 49.7778 54.3641 49.9811 55.3778 50.9298L37.1303 62.7474Z"
                          fill="white"
                        />
                        <path
                          d="M29.6691 12.4546C30.2638 12.4546 30.7504 11.9667 30.7504 11.3704V1.08419C30.7504 0.487885 30.2638 0 29.6691 0C29.0744 0 28.5878 0.487885 28.5878 1.08419V11.3704C28.5878 11.9667 29.0744 12.4546 29.6691 12.4546Z"
                          fill="white"
                        />
                        <path
                          d="M37.7655 12.4546C38.3603 12.4546 38.8469 11.9667 38.8469 11.3704V6.49154C38.8469 5.89523 38.3603 5.40735 37.7655 5.40735C37.1708 5.40735 36.6842 5.89523 36.6842 6.49154V11.3568C36.6842 11.9667 37.1708 12.4546 37.7655 12.4546Z"
                          fill="white"
                        />
                        <path
                          d="M21.5725 12.4546C22.1673 12.4546 22.6539 11.9667 22.6539 11.3704V6.49154C22.6539 5.89523 22.1673 5.40735 21.5725 5.40735C20.9778 5.40735 20.4912 5.89523 20.4912 6.49154V11.3568C20.4912 11.9667 20.9778 12.4546 21.5725 12.4546Z"
                          fill="white"
                        />
                        <path
                          d="M30.0205 37.6756C29.0068 37.6756 28.1147 37.5536 27.3307 37.3097C26.5468 37.0658 25.8844 36.727 25.3573 36.2797C24.8166 35.8325 24.3976 35.304 24.1002 34.6941C23.8029 34.0707 23.6407 33.3931 23.6136 32.6341H26.3575C26.5603 34.5315 27.7768 35.4801 30.0476 35.4801C30.4396 35.4801 30.818 35.4395 31.1695 35.3582C31.5209 35.2769 31.8318 35.1413 32.1021 34.9651C32.3724 34.789 32.5752 34.5586 32.7374 34.2875C32.8861 34.0165 32.9672 33.7048 32.9672 33.3253C32.9672 32.9323 32.8726 32.607 32.6968 32.3495C32.5211 32.092 32.2643 31.8752 31.9399 31.699C31.6155 31.5228 31.21 31.3738 30.7504 31.2382C30.2774 31.1163 29.7637 30.9808 29.1825 30.8588C28.4932 30.6962 27.8579 30.5335 27.2361 30.3438C26.6279 30.1541 26.0872 29.9101 25.6411 29.5984C25.1816 29.2867 24.8301 28.8801 24.5598 28.4058C24.2895 27.9315 24.1543 27.3216 24.1543 26.5762C24.1543 25.8851 24.2895 25.2752 24.5598 24.7467C24.8301 24.2317 25.2086 23.798 25.6952 23.4456C26.1818 23.0933 26.7495 22.8358 27.4118 22.6596C28.0741 22.4834 28.7905 22.3885 29.5745 22.3885C31.2911 22.3885 32.6293 22.768 33.5889 23.5405C34.5351 24.313 35.0893 25.3701 35.238 26.7253H32.5752C32.4671 26.007 32.1562 25.4514 31.629 25.0584C31.1019 24.6653 30.4125 24.4756 29.5339 24.4756C28.7094 24.4756 28.0471 24.6382 27.5605 24.9635C27.0739 25.2888 26.8171 25.7495 26.8171 26.3323C26.8171 26.6711 26.8982 26.9557 27.0739 27.1861C27.2496 27.4165 27.4929 27.6062 27.8173 27.7553C28.1417 27.9179 28.5202 28.0534 28.9798 28.1754C29.4393 28.2974 29.9394 28.4058 30.5071 28.5278C31.1965 28.6768 31.8453 28.8395 32.4671 29.0428C33.0888 29.246 33.643 29.5035 34.1296 29.8424C34.6162 30.1812 35.0082 30.6148 35.292 31.1298C35.5759 31.6584 35.7246 32.3089 35.7246 33.1085C35.7246 33.8403 35.5759 34.5044 35.292 35.06C35.0082 35.6292 34.6027 36.1035 34.1026 36.483C33.6025 36.8625 32.9942 37.1606 32.3049 37.3639C31.602 37.5672 30.8451 37.6756 30.0205 37.6756Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="58" height="66" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div class="col-9 pl-6 pr-0 text-left">
                    <h4 class="d-h5" style="color:white;">
                      Tu ahorro con Simple
                    </h4>
                    <h2 class="d-h3" style="color:white;">{{ Number(save).toLocaleString() }} €</h2>
                    <span style="font-size:12px; font-weight:400;color:white">más iva</span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <h3 class="d-h2" style="padding-top:50px">
          Ventajas de vender Simple
        </h3>
        <p class="d-subtitle-2" style="padding-top:16px; color:#F52F57;">
          La nueva revolución digital inmobiliaria.
        </p>
      </div>
      <div
        style="background-color:#F0F3F8; min-height: 894px; margin-top:48px;"
      >
        <div style="margin:auto;">
          <v-row
            no-gutters
            style="padding-top:40px"
          >
            <v-col
              cols="12"
              class="d-flex  flex-column justify-center mb-7"
              @mouseover="isHovering1 = true"
              @mouseout="isHovering1 = false"
              :class="{ 'animate__animated animate__pulse': isHovering1 }"
            >
              <div class="v-card-img-mobile pa-4">
                <img
                  src="../assets/Image/icons/profit.svg"
                  style="width: 63px;height:63px;margin:auto"
                />
              </div>
              <v-card class="v-card-sellingMobile mx-4" style="width:90%;display:inline-table !important">
                 <h4 class="d-h4 pl-md-6" style="padding-top:40px;text-align:center">
                  Más ahorro
                </h4>
                <p class="d-p-1 px-5 pt-2">
                  Obtiene más <b>dinero</b> por tu inmueble. <b>Ahorra</b> en
                  comisiones Inmobiliarias.
                </p>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              class="d-flex  flex-column justify-center  mb-7"
              @mouseover="isHovering2 = true"
              @mouseout="isHovering2 = false"
              :class="{ 'animate__animated animate__pulse': isHovering2 }"
            >
              <div class="v-card-img-mobile pa-4">
                <img
                  src="../assets/Image/icons/clock.svg"
                  style="width: 63px;height:63px;margin:auto"
                />
              </div>
              <v-card class="v-card-sellingMobile mx-4" style="width:90%;display:inline-table !important">
                 <h4 class="d-h4 pl-md-6" style="padding-top:40px;text-align:center">
                  Más rápido
                </h4>
                <p class="d-p-1 mx-5 pt-2">
                  Disminuye el <b>tiempo</b> de venta. Implementamos marketing digital para que puedas vender en 60 días.
                </p>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              class="d-flex  flex-column justify-center"
              @mouseover="isHovering3 = true"
              @mouseout="isHovering3 = false"
              :class="{ 'animate__animated animate__pulse': isHovering3 }"
            >
              <div class="v-card-img-mobile pa-4">
                <img
                  src="../assets/Image/icons/handshake.svg"
                  style="width: 63px;height:63px;margin:auto"
                />
              </div>
              <v-card class="v-card-sellingMobile mx-4" style="width:90%;display:inline-table !important">
                <h4 class="d-h4 pl-md-6" style="padding-top:40px;text-align:center">
                  Más transparente
                </h4>
                <p class="d-p-1 px-5 pt-2">
                  <b>Proceso Profesional</b> sin complicaciones, directamente
                  entre vendedores y compradores.
                </p>
              </v-card>
            </v-col>
          </v-row>
          <h1 class="d-h2" style="padding-top:50px;">
            Nuestros servicios <br />
            son <span style="color:#F52F57;">Simples</span>
          </h1>
          <div style="padding-top:48px;text-align:left;">
            <div class="px-4" style="margin:auto;">
              <ul class="m-a">
                <li>Gestión Online de todo el proceso de venta.</li>
                <li>Anuncio en los principales portales inmobiliarios.</li>
                <li>Fotos y videos profesionales de tu inmueble.</li>
                <li>Seleccionamos a los mejores compradores.</li>
                <li>Nos ocupamos de todo el papeleo, gestión de firmas y trámites.</li>
                <li>Un experto inmobiliario acompañándote en todo el proceso.</li>
              </ul>
            </div>
          </div>
        </div>
        <v-btn
          color="secondary"
          class="mainButtonMobile d-buttom mx-4"
          style="margin-top:48px;margin-bottom:48px;width:90% !important;"
          v-scroll="onScroll"
          @click="toTop"
          >INFORMATE GRATIS</v-btn
        >
        <div class="mx-5" style="background-color: #F0F3F8;">
          <h2 class="d-h4">
            Tu piso en los mejores portales inmobiliarios
          </h2>
          <h3 class="d-h5" style="padding-top:19px;padding-bottom:28px;color:#F52F57;">
            Publicamos tu piso o casa para que lo puedas vender en 60 días sin comisiones
          </h3>
          <img style="position:relative;right:13px;" src="@/assets/Image/logosCompanies.svg"/>
          <p class="d-p-1 d-subtitle-1" style="padding-top:28px;padding-bottom:60px;margin-bottom:0;">
            Publicación y gestión de tu inmueble de forma automática en los mejores portales inmobiliarios nacionales e internacionales.
          </p>
        </div>
      </div>
      <v-img height="237" :src="require('@/assets/Image/home-2.webp')"></v-img>
      <Footer />
    </div>
    <v-container v-else class="backgroundImg">
      <div class="mainDiv d-flex">
        <div class="col-6">
          <div
            class="homeLogo animate__animated animate__fadeInDown"
            :class="{
              'animate__animated animate__fadeOutUp': scrollPosition > 0,
            }"
          >
            <img class="image" src="../assets/Image/logo-simple.svg" />
            <p class="d-subtitle-1 pr-lg-0 pr-16" style="font-size:20px;">
              Online marketing & Real Estate made SIMPLE
            </p>
          </div>
        </div>
        <div class="col-6">
          <v-card class="mainForm">
            <h2
              class="d-h2"
              style="width: 339px;height: 80px; text-align:left; color:black;"
            >
              Vende rápido y simple sin pagar comisión.
            </h2>
            <v-form ref="form" v-model="isFormValid">
            <v-text-field
              class="pt-6"
              outlined
              label="Ciudad"
              color="#204E95"
              v-model="ciudad"
            required
            :rules="cityRules"
              @focus="changeStatusMap()"
              @focusout="changeStatusMap()"
            >
              <template v-slot:append>
                <v-icon :class="{ active: mapIsActive }">$IconMap</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              outlined
              label="Correo electrónico"
              color="#204E95"
              v-model="email"
              :rules="emailRules"
               required
              @focus="changeStatusMail()"
              @focusout="changeStatusMail()"
            >
              <template slot="append">
                <v-icon :class="{ active: mailIsActive }">$IconMail</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              outlined
              label="Teléfono"
              color="#204E95"
              v-model="phone"
              :rules="phoneRules"
               required
              @focus="changeStatusPhone()"
              @focusout="changeStatusPhone()"
            >
              <template slot="append">
                <v-icon :class="{ active: phoneIsActive }">$IconPhone</v-icon>
              </template>
            </v-text-field>
            <v-checkbox
              v-model="accept"
              :rules="acceptRules"
              required
              label="Acepto políticas de Privacidad"
              color="secondary"
              value="accept"
              hide-details
              class="pt-0 mt-0"
            ></v-checkbox>
            </v-form>
            <div class="pt-6 text-left">
              <v-btn @click="sendEmailNotification" color="secondary" class="mainButton d-buttom"
                >INFORMATE GRATIS</v-btn
              >
            </div>
            <p class="p-d-1 my-4 text-left">
            También puedes llamarnos al
            <a href="tel:919 049 444" style="text-decoration:none"><b>919 049 444</b></a>
            </p>
          </v-card>
        </div>
      </div>
      <div class="px-md-15 px-5" style="max-width: 1336px; margin:auto">
        <p
          class="d-subtitle-3"
          style="max-width: 1206px;text-align:left; margin:100px auto 0px auto;"
        >
          <b>Simple</b> es una inmobiliaria Online (PropTech) que une lo mejor del
          marketing digital con el real estate, consiguiendo vender un inmueble en menos
          tiempo de particular a particular y sin comisiones de agencia de forma
          100% digital y dinámica con procesos profesionales y transparentes que
          permiten una mejor experiencia de usuario de la mano de nuevas
          tecnologías.
        </p>
        <h4 class="d-h4 pt-6" style="color:#f52f57; text-align:left;">
          Vender tu inmueble ahora es Simple.
        </h4>
        <h1 class="d-h1" style="text-align:center;margin-top:100px;">
          ¿Cuánto puedes ahorrar
          <br />en comisiones?
        </h1>
        <v-card
          class="slider-card mt-12"
          :class="{
            'animate__animated animate__slideInUp': scrollPosition > 200,
          }"
        >
          <v-row no-gutters>
            <v-col cols="6" style="height:256px">
              <div class="pt-6 pl-16" style="max-width:519px;height:176px;">
                <h4 class="d-h4" style="text-align:left;color:#204E95">
                  Precio de Venta
                </h4>
                <v-row no-gutters>
                  <v-col cols="6" lg="8">
                    <vue-slider
                      ref="slider"
                      v-model="sellvalue"
                      v-bind="selloptions"
                      style="text-size: 48px;padding-top:22px;"
                    ></vue-slider>
                  </v-col>
                  <v-col cols="4" lg="4">
                    <vuetify-money
                      v-model="sellvalue"
                      v-bind:outlined="true"
                      v-bind:options="options"
                      style="margin-left:17px;"
                      type="number"
                      class="inputPrice"
                      color="#204E95"
                    />
                  </v-col>
                </v-row>
                <h4 class="d-h4" style="text-align:left;color:#204E95">
                  Comisión de Agencia
                </h4>
                <v-row no-gutters>
                  <v-col cols="6" lg="8">
                    <vue-slider
                      ref="slider"
                      v-model="comisionvalue"
                      v-bind="comisionoptions"
                      style="text-size: 48px;padding-top:22px;"
                    ></vue-slider>
                  </v-col>
                  <v-col cols="4" lg="4">
                    <v-text-field
                      v-model="comisionvalue"
                      outlined
                      required
                      type="number"
                      style="margin-left:17px;"
                      suffix="%"
                      class="inputPrice"
                      min="3"
                      max="7"
                      color="#204E95"
                      persistent-hint
                      hint="más iva"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="6" class="d-flex" style="height:256px">
              <div
                style="width:2px;height:176px;background: #E0E0E0;top:44px;position:absolute;margin:auto"
              ></div>
              <div
                class="col-6 pt-md-11 pt-8 pl-16"
                style="width:155px;height:165px;text-align:left;"
              >
                <h4
                  class="d-h4"
                  style="padding-bottom:20px;padding-top:0;margin:0;"
                >
                  Tu coste con:
                </h4>
                <p
                  class="d-subtitle-2"
                  style="color:#4F4F4F;padding-bottom:4px;padding-top:0;margin:0;"
                >
                  Agencia Tradicional
                </p>
                <h4 class="d-h4" style="color:#4F4F4F;padding-top:0;margin:0;">
                  {{ Number(total).toLocaleString() }} €<br />
                </h4>
                <p class="d-description-1" style="color:#828282">
                  más iva
                </p>
                <p
                  class="d-subtitle-2"
                  style="color:#4F4F4F;padding-bottom:4px;padding-top:0;margin:0;"
                >
                  Simple
                </p>
                <h4 class="d-h4" style="color:#4F4F4F;padding-top:0;margin:0;">
                  {{ Number(simple).toLocaleString() }} €<br />
                </h4>
                <p class="d-description-1" style="color:#828282">
                  más iva
                </p>
              </div>
              <div class="rombo"></div>
              <div
                class="col-6"
                style="height: 256px;background: #6383B4;box-shadow: 0px 4px 10px rgba(32, 78, 149, 0.2);border-radius: 8px;"
              >
                <div style="padding-top: 50px;margin:auto;">
                  <svg
                    width="58"
                    height="66"
                    viewBox="0 0 58 66"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0)">
                      <path
                        d="M29.6691 45.7257C38.3198 45.7257 45.3214 38.692 45.3214 30.032C45.3214 21.3721 38.3063 14.3384 29.6691 14.3384C21.032 14.3384 14.0168 21.3721 14.0168 30.032C14.0304 38.692 21.032 45.7121 29.6691 45.7257ZM29.6691 16.4932C37.1303 16.4932 43.1723 22.5511 43.1723 30.032C43.1723 37.5129 37.1303 43.5708 29.6691 43.5708C22.2079 43.5708 16.166 37.5129 16.166 30.032C16.1795 22.5511 22.2215 16.5068 29.6691 16.4932Z"
                        fill="white"
                      />
                      <path
                        d="M50.647 48.4768L42.1856 52.4883C41.0231 50.3064 38.7794 48.924 36.3058 48.8563L28.4932 48.6394C27.2632 48.5988 26.0467 48.2871 24.9383 47.7179L24.1408 47.3113C20.0723 45.1836 15.2198 45.1971 11.1648 47.3249L11.2189 45.5224C11.2324 44.9261 10.7593 44.4247 10.1646 44.4111L1.62204 44.1672C1.02731 44.1536 0.527188 44.6279 0.513672 45.2242L3.84421e-05 64.0485C-0.0134782 64.6448 0.459605 65.1462 1.05434 65.1598L9.61039 65.3902H9.63742C10.2186 65.3902 10.7052 64.9294 10.7188 64.3331L10.7458 63.4251L12.976 62.2325C13.8546 61.7581 14.8684 61.6497 15.8281 61.9207L29.1149 65.6612C29.142 65.6748 29.1555 65.6748 29.1825 65.6748C30.1422 65.878 31.1154 65.9729 32.1021 65.9729C34.1837 65.9729 36.2382 65.5257 38.117 64.6583C38.1576 64.6312 38.2117 64.6177 38.2522 64.5906L57.5135 52.0953C57.9865 51.7836 58.1487 51.1466 57.8649 50.6587C56.4186 48.138 53.2557 47.1622 50.647 48.4768ZM2.18974 63.032L2.64931 46.3626L9.0562 46.5388L8.59664 63.2082L2.18974 63.032ZM37.1303 62.7474C34.7784 63.8045 32.1697 64.1027 29.6421 63.5877L16.3958 59.8608C14.9089 59.4407 13.314 59.6168 11.9488 60.3487L10.8134 60.9585L11.1243 49.8727C14.7197 47.4739 19.3424 47.23 23.1811 49.2222L23.9786 49.6288C25.3708 50.347 26.8982 50.74 28.4661 50.7943L36.2788 51.0111C38.4685 51.0789 40.3338 52.6374 40.7798 54.7922L29.1555 54.4669C28.5608 54.4534 28.0606 54.9277 28.0471 55.524C28.0336 56.1203 28.5067 56.6218 29.1014 56.6353L41.9423 56.9877H41.9693C42.5505 56.9877 43.0371 56.5269 43.0506 55.9306C43.0641 55.4563 43.0236 54.9955 42.9425 54.5212L51.6202 50.4148C51.6337 50.4148 51.6337 50.4012 51.6472 50.4012C52.8772 49.7778 54.3641 49.9811 55.3778 50.9298L37.1303 62.7474Z"
                        fill="white"
                      />
                      <path
                        d="M29.6691 12.4546C30.2638 12.4546 30.7504 11.9667 30.7504 11.3704V1.08419C30.7504 0.487885 30.2638 0 29.6691 0C29.0744 0 28.5878 0.487885 28.5878 1.08419V11.3704C28.5878 11.9667 29.0744 12.4546 29.6691 12.4546Z"
                        fill="white"
                      />
                      <path
                        d="M37.7655 12.4546C38.3603 12.4546 38.8469 11.9667 38.8469 11.3704V6.49154C38.8469 5.89523 38.3603 5.40735 37.7655 5.40735C37.1708 5.40735 36.6842 5.89523 36.6842 6.49154V11.3568C36.6842 11.9667 37.1708 12.4546 37.7655 12.4546Z"
                        fill="white"
                      />
                      <path
                        d="M21.5725 12.4546C22.1673 12.4546 22.6539 11.9667 22.6539 11.3704V6.49154C22.6539 5.89523 22.1673 5.40735 21.5725 5.40735C20.9778 5.40735 20.4912 5.89523 20.4912 6.49154V11.3568C20.4912 11.9667 20.9778 12.4546 21.5725 12.4546Z"
                        fill="white"
                      />
                      <path
                        d="M30.0205 37.6756C29.0068 37.6756 28.1147 37.5536 27.3307 37.3097C26.5468 37.0658 25.8844 36.727 25.3573 36.2797C24.8166 35.8325 24.3976 35.304 24.1002 34.6941C23.8029 34.0707 23.6407 33.3931 23.6136 32.6341H26.3575C26.5603 34.5315 27.7768 35.4801 30.0476 35.4801C30.4396 35.4801 30.818 35.4395 31.1695 35.3582C31.5209 35.2769 31.8318 35.1413 32.1021 34.9651C32.3724 34.789 32.5752 34.5586 32.7374 34.2875C32.8861 34.0165 32.9672 33.7048 32.9672 33.3253C32.9672 32.9323 32.8726 32.607 32.6968 32.3495C32.5211 32.092 32.2643 31.8752 31.9399 31.699C31.6155 31.5228 31.21 31.3738 30.7504 31.2382C30.2774 31.1163 29.7637 30.9808 29.1825 30.8588C28.4932 30.6962 27.8579 30.5335 27.2361 30.3438C26.6279 30.1541 26.0872 29.9101 25.6411 29.5984C25.1816 29.2867 24.8301 28.8801 24.5598 28.4058C24.2895 27.9315 24.1543 27.3216 24.1543 26.5762C24.1543 25.8851 24.2895 25.2752 24.5598 24.7467C24.8301 24.2317 25.2086 23.798 25.6952 23.4456C26.1818 23.0933 26.7495 22.8358 27.4118 22.6596C28.0741 22.4834 28.7905 22.3885 29.5745 22.3885C31.2911 22.3885 32.6293 22.768 33.5889 23.5405C34.5351 24.313 35.0893 25.3701 35.238 26.7253H32.5752C32.4671 26.007 32.1562 25.4514 31.629 25.0584C31.1019 24.6653 30.4125 24.4756 29.5339 24.4756C28.7094 24.4756 28.0471 24.6382 27.5605 24.9635C27.0739 25.2888 26.8171 25.7495 26.8171 26.3323C26.8171 26.6711 26.8982 26.9557 27.0739 27.1861C27.2496 27.4165 27.4929 27.6062 27.8173 27.7553C28.1417 27.9179 28.5202 28.0534 28.9798 28.1754C29.4393 28.2974 29.9394 28.4058 30.5071 28.5278C31.1965 28.6768 31.8453 28.8395 32.4671 29.0428C33.0888 29.246 33.643 29.5035 34.1296 29.8424C34.6162 30.1812 35.0082 30.6148 35.292 31.1298C35.5759 31.6584 35.7246 32.3089 35.7246 33.1085C35.7246 33.8403 35.5759 34.5044 35.292 35.06C35.0082 35.6292 34.6027 36.1035 34.1026 36.483C33.6025 36.8625 32.9942 37.1606 32.3049 37.3639C31.602 37.5672 30.8451 37.6756 30.0205 37.6756Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="58" height="66" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <h4 class="d-h4" style="color:white;padding-top:23px">
                    Tu ahorro con Simple
                  </h4>
                  <h2 class="d-h2" style="color:white;">{{ Number(save).toLocaleString() }} €</h2>
                    <span style="font-size:12px; font-weight:400;color:white">más iva</span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <h1 class="d-h1" style="padding-top:124px">
          Ventajas de vender Simple
        </h1>
        <p class="d-subtitle-1" style="padding-top:16px; color:#F52F57;">
          La nueva revolución digital inmobiliaria.
        </p>
      </div>
      <div
        style="background-color:#F0F3F8; min-height: 894px; margin-top:48px;"
      >
        <div style="max-width: 1206px; margin:auto;">
          <v-row
            no-gutters
            :class="{
              'animate__animated animate__slideInUp': scrollPosition > 800,
            }"
            style="padding-top:40px"
          >
            <v-col
              cols="4"
              @mouseover="isHovering1 = true"
              @mouseout="isHovering1 = false"
              :class="{ 'animate__animated animate__pulse': isHovering1 }"
            >
              <div class="v-card-img">
                <img
                  src="../assets/Image/icons/profit.svg"
                  style="width: 75px;height:75px;margin:auto"
                />
              </div>
              <v-card class="v-card-selling" pl-md-6 pl-3>
                <h4 class="d-h4 pl-md-6 pl-3" style="padding-top:40px;">
                  Más ahorro
                </h4>
                <p class="d-p-1 pl-md-6 pl-3 pt-2">
                  Obtiene más <b>dinero</b> por tu inmueble. <b>Ahorra</b> en
                  comisiones Inmobiliarias.
                </p>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              @mouseover="isHovering2 = true"
              @mouseout="isHovering2 = false"
              :class="{ 'animate__animated animate__pulse': isHovering2 }"
            >
              <div class="v-card-img">
                <img
                  src="../assets/Image/icons/clock.svg"
                  style="width: 75px;height:75px;margin:auto"
                />
              </div>
              <v-card class="v-card-selling">
                <h4 class="d-h4 pl-md-6 pl-3" style="padding-top:40px;">
                  Más rápido
                </h4>
                <p
                  class="d-p-1 px-md-6 px-3 pt-2"
                >
                  Disminuye el <b>tiempo</b> de venta. Implementamos marketing digital para que puedas vender en 60 días.
                </p>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              @mouseover="isHovering3 = true"
              @mouseout="isHovering3 = false"
              :class="{ 'animate__animated animate__pulse': isHovering3 }"
            >
              <div class="v-card-img">
                <img
                  src="../assets/Image/icons/handshake.svg"
                  style="width: 75px;height:75px;margin:auto"
                />
              </div>
              <v-card class="v-card-selling">
                <h4 class="d-h4 pl-md-6 pl-3" style="padding-top:40px;">
                  Más transparente
                </h4>
                <p class="d-p-1 pl-md-6 pl-3 pt-2">
                  <b>Proceso Profesional</b> sin complicaciones, directamente
                  entre vendedores y compradores.
                </p>
              </v-card>
            </v-col>
          </v-row>
          <h1 class="d-h1" style="padding-top:124px;">
            Nuestros servicios son <span style="color:#F52F57;">Simples</span>
          </h1>
          <div style="padding-top:48px;text-align:left;">
            <div style="width:700px;margin:auto;">
              <ul class="a">
                <li>Gestión Online de todo el proceso de venta.</li>
                <li>Anuncio en los principales portales inmobiliarios.</li>
                <li>Fotos y videos profesionales de tu inmueble.</li>
                <li>Seleccionamos a los mejores compradores.</li>
                <li>Nos ocupamos de todo el papeleo, gestión de firmas y trámites.</li>
                <li>Un experto inmobiliario acompañándote en todo el proceso.</li>
              </ul>
            </div>
          </div>
        </div>
        <v-btn
          color="secondary"
          class="mainButton d-buttom"
          style="margin-top:76px;margin-bottom:92px;"
          v-scroll="onScroll"
          @click="toTop"
          >INFORMATE GRATIS</v-btn
        >
      </div>
      <div style="margin:auto;background-color: #F0F3F8;">
        <h2 class="d-h2">
          Tu piso en los mejores portales inmobiliarios
        </h2>
        <h3 class="d-h3" style="padding-top:19px;padding-bottom:48px;color:#F52F57;">
          Publicamos tu piso o casa para que lo puedas vender en 60 días sin comisiones
        </h3>
        <img src="@/assets/Image/logos.svg"/>
        <p class="d-p-1" style="padding-top:48px;padding-bottom:60px;width: 608px;margin:auto;margin-bottom:0;">
          Publicación y gestión de tu inmueble de forma automática en los mejores portales inmobiliarios nacionales e internacionales.
        </p>
      </div>  
      <v-parallax
        height="330"
        :src="require('@/assets/Image/home-2.webp')"
      ></v-parallax>
      <StickyShare />
      <Footer />
    </v-container>
     <v-dialog
      v-model="dialog"
      max-width="489"
    >
      <v-card style="max-width:489px; border-radius: 8px;">
        <v-card-actions style="display: flex;align-items: top;justify-content: end;">
          <v-btn
            color="black"
            text
            @click="dialog = false"
          >
            X
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <img 
          style="height: 105px;width: 105px;"
          src="https://firebasestorage.googleapis.com/v0/b/simple-7bda7.appspot.com/o/check-OK.gif?alt=media&token=6eed7f40-52d0-4c69-8fe2-bf1680d3a92c" alt="Check consulta enviada">
          <h3 class="d-h3 pt-6 black--text"> ¡La consulta se envió con éxito! </h3>
          <p class="d-p-1 pt-4 black--text">Gracias por ponerte en contacto con nosotros, pronto nos comunicaremos con usted.</p>
         </v-card-text>     
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import StickyShare from "@/components/StickyShare.vue"; 
import "vue-slider-component/theme/default.css";
import Footer from "@/components/Footer.vue";
import { isMobile } from "mobile-device-detect";
import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database

export default {
  name: "Home",
  components: {
    VueSlider,
    Footer,
    StickyShare
  },
  data: () => ({
    scrollPosition: null,
    mailIsActive: false,
    phoneIsActive: false,
    mapIsActive: false,
    isHovering1: false,
    isHovering2: false,
    isHovering3: false,
    simple: 2950,
    sellvalue: 350000,
    comisionvalue: 5,
    total: 0,
    save: 0,
    mobile: isMobile ? true : false,
    ciudad :"",
    email: "",
    phone: "",
    accept: false,
    dialog:false,
    emailRules: [
      (v) => !!v || "E-mail requerido",
       //eslint-disable-next-line
       (v) => /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(v) || 'E-mail no valido',
    ],
    phoneRules: [
      (v) => !!v || "Este campo es requerido",
      (v) => /^\d+$/.test(v) || "Este campo solo acepta números",
      (v) => v > 0 || "Este campo no puede ser 0",
    ],
    cityRules: [
      (v) => !!v || "Este campo es requerido",
      (v) =>
        /^[a-zA-Z\s\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+$/i.test(v) ||
        "Este campo no acepta números",
    ],
    acceptRules: [
      (v) => !!v || "Tiene que aceptar las políticas de Privacidad",
    ],
    isFormValid: false,
    /* eslint-disable no-mixed-spaces-and-tabs */
    options: {
      prefix: "",
      suffix: "€",
      length: 11,
      precision: 0,
    },
    selloptions: {
      dotSize: 15,
      width: "auto",
      height: 10,
      contained: false,
      direction: "ltr",
      data: null,
      min: 150000,
      max: 1500000,
      interval: 50000,
      disabled: false,
      clickable: true,
      duration: 1,
      adsorb: true,
      lazy: true,
      tooltip: "inactive",
      tooltipPlacement: "top",
      tooltipFormatter: (v) =>
        `$${("" + v).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`,
      useKeyboard: true,
      keydownHook: null,
      dragOnClick: false,
      enableCross: true,
      fixed: false,
      minRange: void 0,
      maxRange: void 0,
      order: true,
      marks: false,
      dotOptions: void 0,
      process: true,
      dotStyle: void 0,
      railStyle: void 0,
      processStyle: void 0,
      tooltipStyle: void 0,
      stepStyle: void 0,
      stepActiveStyle: void 0,
      labelStyle: void 0,
      labelActiveStyle: void 0,
    },
    comisionoptions: {
      dotSize: 15,
      width: "auto",
      height: 10,
      contained: false,
      direction: "ltr",
      data: null,
      min: 3,
      max: 7,
      interval: 1,
      disabled: false,
      clickable: true,
      duration: 1,
      adsorb: true,
      lazy: true,
      tooltip: "inactive",
      tooltipPlacement: "top",
      tooltipFormatter: (v) =>
        `$${("" + v).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`,
      useKeyboard: true,
      keydownHook: null,
      dragOnClick: false,
      enableCross: true,
      fixed: false,
      minRange: void 0,
      maxRange: void 0,
      order: true,
      marks: false,
      dotOptions: void 0,
      process: true,
      dotStyle: void 0,
      railStyle: void 0,
      processStyle: void 0,
      tooltipStyle: void 0,
      stepStyle: void 0,
      stepActiveStyle: void 0,
      labelStyle: void 0,
      labelActiveStyle: void 0,
    },
    idUnique : 0,
    /* eslint-enable no-mixed-spaces-and-tabs */
  }),
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        this.originalHeight = window.innerHeight;
      } else if (orientation === "landscape-primary") {
        this.originalHeight = window.innerHeight;
      }
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    changeStatusMail() {
      this.mailIsActive = !this.mailIsActive;
    },
    changeStatusPhone() {
      this.phoneIsActive = !this.phoneIsActive;
    },
    changeStatusMap() {
      this.mapIsActive = !this.mapIsActive;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
      window.fbq.push('trackCustom', 'INFORMATE GRATIS', {content_name: 'Inicio (ir arriba)'});
    },
     sendEmailNotification(){
      if(this.isFormValid){
        this.dialog = true;
      const emailId = this.idUnique;
      const updates = {};
      const updateSendEmail = {};
      const newEmailInformate = {
        "ciudad" : this.ciudad,
        "email" : this.email,
        "telefono" : this.phone,
        "fecha" : Date().toLocaleString(),
        "pais" : "España"
      }
       updates[`emails/${emailId}`] = newEmailInformate;
        firebase.database().ref().update(updates).then(() => {
        //enviamos mail ahora
        updateSendEmail['users/0NTlnChZeue6hc3OlFc1/subscribedToMailingList'] = this.idUnique;

        firebase.database().ref().update(updateSendEmail).then(() => {
        console.log('se envio exitosamente!');
        this.ciudad = "";
        this.email = "";
        this.phone = "";
        this.accept = false,
         this.$refs.form.resetValidation();
         });
         });
      }
      window.fbq.push('trackCustom', 'INFORMATE GRATIS', {content_name: 'Inicio (formulario)'});
     },
     generateId() {
      // Modeled after base64 web-safe chars, but ordered by ASCII.
      console.log("generate Id:");
      var PUSH_CHARS =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzfc";

      // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
      var lastPushTime = 0;

      // We generate 72-bits of randomness which get turned into 12 characters and appended to the
      // timestamp to prevent collisions with other clients.  We store the last characters we
      // generated because in the event of a collision, we'll use those same characters except
      // "incremented" by one.
      var lastRandChars = [];

      var now = new Date().getTime();
      var duplicateTime = now === lastPushTime;
      lastPushTime = now;

      var timeStampChars = new Array(8);
      for (var i = 7; i >= 0; i--) {
        timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
        // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
        now = Math.floor(now / 64);
      }
      if (now !== 0)
        throw new Error("We should have converted the entire timestamp.");

      var id = timeStampChars.join("");

      if (!duplicateTime) {
        for (i = 0; i < 12; i++) {
          lastRandChars[i] = Math.floor(Math.random() * 64);
        }
      } else {
        // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
        for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
          lastRandChars[i] = 0;
        }
        lastRandChars[i]++;
      }
      for (i = 0; i < 12; i++) {
        id += PUSH_CHARS.charAt(lastRandChars[i]);
      }
      if (id.length != 20) throw new Error("Length should be 20.");
      this.idUnique = id;
      console.log(this.idPayment);
    },
  },
  watch: {
    sellvalue() {
      this.total = Math.round(
        this.sellvalue * (this.comisionvalue / 100)
      );
      if (this.sellvalue < 50000) { this.save = this.total - 2950; this.simple = 2950;} else
      if (this.sellvalue < 100000) { this.save = this.total - 3950; this.simple = 3950;} else
      if (this.sellvalue < 150000) { this.save = this.total - 4950; this.simple = 4950;} else
      if (this.sellvalue < 200000) { this.save = this.total - 5950; this.simple = 5950;} else
      if (this.sellvalue < 250000) { this.save = this.total - 6950; this.simple = 6950;} else
      if (this.sellvalue < 300000) { this.save = this.total - 7950; this.simple = 7950;} else
      if (this.sellvalue < 350000) { this.save = this.total - 8950; this.simple = 8950;} else
      if (this.sellvalue < 400000) { this.save = this.total - 9950; this.simple = 9950;} else
      if (this.sellvalue < 450000) { this.save = this.total - 10950; this.simple = 10950;} else
      if (this.sellvalue < 500000) { this.save = this.total - 11950; this.simple = 11950;} else
      if (this.sellvalue < 550000) { this.save = this.total - 12950; this.simple = 12950;} else
      if (this.sellvalue < 600000) { this.save = this.total - 13950; this.simple = 13950;} else
      if (this.sellvalue < 650000) { this.save = this.total - 14950; this.simple = 14950;} else
      if (this.sellvalue < 700000) { this.save = this.total - 15950; this.simple = 15950;} else
      if (this.sellvalue < 750000) { this.save = this.total - 16950; this.simple = 16950;} else
      if (this.sellvalue < 800000) { this.save = this.total - 17950; this.simple = 17950;} else
      if (this.sellvalue < 850000) { this.save = this.total - 18950; this.simple = 18950;} else
      if (this.sellvalue < 900000) { this.save = this.total - 19950; this.simple = 19950;} else
      if (this.sellvalue < 950000) { this.save = this.total - 20950; this.simple = 20950;} else
      if (this.sellvalue < 1000000) { this.save = this.total - 21950; this.simple = 21950;} else
      { this.save = this.total - 21950; 
        this.simple = 21950;
      }
    },
    comisionvalue() {
      this.total = Math.round(
        this.sellvalue * (this.comisionvalue / 100)
      );
      if (this.sellvalue < 50000) { this.save = this.total - 2950; this.simple = 2950;} else
      if (this.sellvalue < 100000) { this.save = this.total - 3950; this.simple = 3950;} else
      if (this.sellvalue < 150000) { this.save = this.total - 4950; this.simple = 4950;} else
      if (this.sellvalue < 200000) { this.save = this.total - 5950; this.simple = 5950;} else
      if (this.sellvalue < 250000) { this.save = this.total - 6950; this.simple = 6950;} else
      if (this.sellvalue < 300000) { this.save = this.total - 7950; this.simple = 7950;} else
      if (this.sellvalue < 350000) { this.save = this.total - 8950; this.simple = 8950;} else
      if (this.sellvalue < 400000) { this.save = this.total - 9950; this.simple = 9950;} else
      if (this.sellvalue < 450000) { this.save = this.total - 10950; this.simple = 10950;} else
      if (this.sellvalue < 500000) { this.save = this.total - 11950; this.simple = 11950;} else
      if (this.sellvalue < 550000) { this.save = this.total - 12950; this.simple = 12950;} else
      if (this.sellvalue < 600000) { this.save = this.total - 13950; this.simple = 13950;} else
      if (this.sellvalue < 650000) { this.save = this.total - 14950; this.simple = 14950;} else
      if (this.sellvalue < 700000) { this.save = this.total - 15950; this.simple = 15950;} else
      if (this.sellvalue < 750000) { this.save = this.total - 16950; this.simple = 16950;} else
      if (this.sellvalue < 800000) { this.save = this.total - 17950; this.simple = 17950;} else
      if (this.sellvalue < 850000) { this.save = this.total - 18950; this.simple = 18950;} else
      if (this.sellvalue < 900000) { this.save = this.total - 19950; this.simple = 19950;} else
      if (this.sellvalue < 950000) { this.save = this.total - 20950; this.simple = 20950;} else
      if (this.sellvalue < 1000000) { this.save = this.total - 21950; this.simple = 21950;} else
      { this.save = this.total - 21950; 
        this.simple = 21950;
      }
    },
  },
  mounted() {
    this.generateId();
    window.addEventListener("scroll", this.updateScroll);
    this.total = Math.round(
      this.sellvalue * (this.comisionvalue / 100)
    );
    if (this.sellvalue < 50000) { this.save = this.total - 2950; this.simple = 2950;} else
    if (this.sellvalue < 100000) { this.save = this.total - 3950; this.simple = 3950;} else
    if (this.sellvalue < 150000) { this.save = this.total - 4950; this.simple = 4950;} else
    if (this.sellvalue < 200000) { this.save = this.total - 5950; this.simple = 5950;} else
    if (this.sellvalue < 250000) { this.save = this.total - 6950; this.simple = 6950;} else
    if (this.sellvalue < 300000) { this.save = this.total - 7950; this.simple = 7950;} else
    if (this.sellvalue < 350000) { this.save = this.total - 8950; this.simple = 8950;} else
    if (this.sellvalue < 400000) { this.save = this.total - 9950; this.simple = 9950;} else
    if (this.sellvalue < 450000) { this.save = this.total - 10950; this.simple = 10950;} else
    if (this.sellvalue < 500000) { this.save = this.total - 11950; this.simple = 11950;} else
    if (this.sellvalue < 550000) { this.save = this.total - 12950; this.simple = 12950;} else
    if (this.sellvalue < 600000) { this.save = this.total - 13950; this.simple = 13950;} else
    if (this.sellvalue < 650000) { this.save = this.total - 14950; this.simple = 14950;} else
    if (this.sellvalue < 700000) { this.save = this.total - 15950; this.simple = 15950;} else
    if (this.sellvalue < 750000) { this.save = this.total - 16950; this.simple = 16950;} else
    if (this.sellvalue < 800000) { this.save = this.total - 17950; this.simple = 17950;} else
    if (this.sellvalue < 850000) { this.save = this.total - 18950; this.simple = 18950;} else
    if (this.sellvalue < 900000) { this.save = this.total - 19950; this.simple = 19950;} else
    if (this.sellvalue < 950000) { this.save = this.total - 20950; this.simple = 20950;} else
    if (this.sellvalue < 1000000) { this.save = this.total - 21950; this.simple = 21950;} else
    { this.save = this.total - 21950; 
        this.simple = 21950;
      }
    setTimeout(function() {
      window.scrollTo(0, 0);
    }, 2);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
};
</script>

<style lang="scss">
.homeLogo {
  position: relative;
  float: left;
  top: 128px;
  text-align: left !important;
}

.backgroundImg {
  background-image: url("../assets/Image/home-1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 bottom;
  max-width: 100%;
  height: 768px;
  text-align: center;
  padding: 0;
}

@media (min-width: 1500px) {
  .backgroundImg {
    background-image: url("../assets/Image/home-1920.webp") !important;
  }
}

.mainDiv {
  max-width: 1366px;
  width: 100vw;
  height: 100%;
  padding: 0px 80px;
  display: inline-block;
  margin: auto;
}
@media (max-width: 1024px) {
  .mainDiv {
    max-width: 1366px;
    width: 100vw;
    height: 100%;
    padding: 0px 40px;
    display: inline-block;
  }
  .v-card-selling {
  width: 95% !important;
  height: 146px;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  text-align: left;
  display: inline-table;
}
.image{
  height:60px;
}
}
.rombo {
  width: 28px;
  height: 28px;
  border-radius: 2px;
  transform: rotate(-45deg);
  background: #6383b4;
  position: absolute;
  top: 45%;
  right: 23.7%;
  z-index: 2;
}
@media (max-width: 1024px) {
  .mainDiv {
    max-width: 1366px;
    width: 100vw;
    height: 100%;
    padding: 0px 40px;
    display: inline-block;
  }
  .rombo {
  width: 28px;
  height: 28px;
  border-radius: 2px;
  transform: rotate(-45deg);
  background: #6383b4;
  position: absolute;
  top: 45%;
  right: 22.9%;
  z-index: 2;
}
}

.mainForm {
  position: relative;
  width: 493px;
  height: 562px;
  float: right;
  top: 128px;
  padding: 40px 50px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}

.mainFormMobile {
  bottom: 20px;
  border-radius: 23px 12px 0px 0px !important;
  box-shadow: none !important;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
}
#mobile .v-sheet .v-card {
  border-radius: 23px;
}
.mainButton {
  text-transform: none !important;
  box-shadow: 0px 6px 10px rgba(245, 47, 87, 0.3) !important;
  border-radius: 4px !important;
  height: 51px !important;
  width: 217px !important;
  left: 0 !important;
}
.mainButtonMobile {
  text-transform: none !important;
  box-shadow: 0px 6px 10px rgba(245, 47, 87, 0.3) !important;
  border-radius: 4px !important;
  height: 51px !important;
  width: 100% !important;
  left: 0 !important;
}
.theme--light.v-label {
  color: #828282 !important;
  font-family: Nunito Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
}

.theme--light.v-label--active {
  color: #204e95 !important;
  font-family: Nunito Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
}

.theme--light.v-icon {
  stroke: #828282;
}

.active {
  stroke: #204e95 !important;
}

.slider-card {
  width: 100%;
  height: 256px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  display: flex;
}

.vue-slider-rail {
  background: rgba(32, 78, 149, 0.38);
  border-radius: 1px;
  height: 5px;
  filter: drop-shadow(0px 4px 10px rgba(32, 78, 149, 0.2));
}

.vue-slider-process {
  background: #204e95;
  border-radius: 1px;
  height: 5px;
  filter: drop-shadow(0px 4px 10px rgba(32, 78, 149, 0.2));
}

.vue-slider-dot-handle {
  background-color: #204e95;
  box-shadow: none;
}

.inputPrice input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 3.5px;
}

.v-card-selling {
  width: 335px;
  height: 156px;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  text-align: left;
  display: inline-table;
}
.v-card-sellingMobile {
  width: 335px;
  height: 156px;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  text-align: left;
  display: inline-table;
}
.v-card-img {
  width: 107px;
  height: 107px;
  top: 22px;
  background: #f52f57;
  box-shadow: 0px 4px 10px rgba(245, 47, 87, 0.2) !important;
  border-radius: 8px !important;
  display: flex;
  position: relative;
  z-index: 20;
  left: 50px;
}

.v-card-img-mobile {
  width: 90px;
  height: 90px;
  top: 22px;
  background: #f52f57;
  box-shadow: 0px 4px 10px rgba(245, 47, 87, 0.2) !important;
  border-radius: 8px !important;
  position: relative;
  z-index: 20;
  left: 0;
  right: 0;
  margin: auto;
}

.bottom-background {
  max-width: 100%;
  height: 300px;
}

:root {
  --animate-duration: 1.5s;
}
.hr {
  background: #e0e0e0;
  width: 100%;
  height: 0.5px;
}
</style>

<style scoped>
ul.a {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 37px;
}

ul.m-a {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

ul {
  list-style: none;
}

li {
  padding-top:10px;
  line-height: 22px;
}

ul li::before {
  content: "\2022";
  color: #f52f57;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
</style>