<template>
<div>
  <v-container id="mobile" v-if="mobile" class="howmuchContainerMobile">
    <div style="text-align:center;">
    <div class="animate__animated animate__fadeInDown mx-5"  
         style="padding-top:91px;padding-bottom:25px;"
    > 
      <h1 class="d-h2">
        Valoraciones online gratuitas 
      </h1>
      <h3 class="d-subtitle-2" style="color:#F52F57;padding-top:16px;">
        ¿Cómo saber el valor de tu propiedad? Simple.
      </h3>
    </div>
    </div>
    <div style="width:100%;margin:auto;background-color:#F0F3F8;padding-top:29px;">
      <div class="mx-5" style="max-width:1366px;">
        <div class="v-card-imghowmuch">
          <img src="../assets/Image/icons/house.svg" style="width: 60px;height:60px;margin:auto"/>
        </div>
        <v-card class="v-card-howmuchMobile px-5">
          <h3 class="d-h3" style="padding-top:57px;padding-bottom:24px;text-align:center;">
            Informe de valoración online
          </h3>
          <div style="margin:auto;display:grid;">
          <p class="d-p-1 px-4">
            Introduce los datos de tu inmueble y te ofrecemos una valoración online y gratuita de tu piso con:
          </p>
          <ul class="b mx-3">
            <li>El valor estimado de tu piso</li>
            <li>Información sobre el mercado inmobiliario en tu zona</li>
          </ul>  
          <v-btn
        color="secondary"
        class="mainButton d-buttom"
        style="margin-bottom:54px; width:100% !important; margin: 38px auto !important "
        @click="howmuchinfo"
        onclick=" window.open('https://betterplaceapp.com/valorar/bracesco-inversiones-sl/es','_blank')"        
      >ACCEDER A VALORACIÓN</v-btn> 
          </div>
           
        </v-card>
        <p class="d-p-1 mx-2 text-left mb-0" style="padding-top:48px;padding-bottom:60px;">
          Debe recordar que es un valor estimado que, acompañado al análisis de nuestros expertos inmobiliarios, logrará conocer el valor de mercado actual de su propiedad. 
        </p>
      </div>    
    </div>
     <v-img
        height="237"
        :src="require('@/assets/Image/home-2.webp')"
      ></v-img>
    <Footer />
  </v-container>  
    <v-container v-else class="howmuchContainer">
    <div style="text-align:center;">
    <div class="animate__animated animate__fadeInDown"  
         style="padding-top:148px;padding-bottom:95px;"
    > 
      <h1 class="d-h1">
        Valoraciones online gratuitas 
      </h1>
      <h3 class="d-subtitle-1" style="color:#F52F57;padding-top:16px;">
        ¿Cómo saber el valor de tu propiedad? Simple.
      </h3>
    </div>
    </div>
    <div style="height:710px;width:100%;margin:auto;background-color:#F0F3F8;">
      <div style="max-width:1366px;margin:auto;">
        <div class="v-card-imghowmuch">
          <img src="../assets/Image/icons/house.svg" style="width: 75px;height:75px;margin:auto"/>
        </div>
        <v-card class="v-card-howmuch">
          <h3 class="d-h3" style="padding-top:57px;padding-bottom:24px;text-align:center;">
            Informe de valoración online
          </h3>
          <div style="width:476px;margin:auto;display:grid;">
          <p class="d-p-1">
            Introduce los datos de tu inmueble y te ofrecemos una valoración online y gratuita de tu piso con:
          </p>
          <ul class="b">
            <li>El valor estimado de tu piso</li>
            <li>Información sobre el mercado inmobiliario en tu zona</li>
          </ul>  
          <v-btn
        color="secondary"
        class="mainButton d-buttom"
        style="margin-bottom:54px; width:259px !important; margin: 38px auto !important "
        @click="howmuchinfo"
        onclick=" window.open('https://betterplaceapp.com/valorar/bracesco-inversiones-sl/es','_blank')"
      >ACCEDER A VALORACIÓN</v-btn> 
          </div>
           
        </v-card>
        <p class="d-p-1" style="width: 753px;margin:auto;padding-top:48px;padding-bottom:60px;text-align:left;">
          Debe recordar que es un valor estimado que, acompañado al análisis de nuestros expertos inmobiliarios, logrará conocer el valor de mercado actual de su propiedad. 
        </p>
      </div>    
    </div>
    <v-parallax height="330" :src="require('@/assets/Image/home-2.webp')"></v-parallax>
    <StickyShare />
    <Footer />
  </v-container> 
</div>    
</template>

<script>
import Footer from '@/components/Footer.vue';
import { isMobile } from "mobile-device-detect";
import StickyShare from "@/components/StickyShare.vue"; 
  export default {
    name: 'HowMuch',
    components: {
      Footer,
      StickyShare
    },
    data: () => ({   
       mobile: isMobile ? true : false,
    }),
    methods: {  
      howmuchinfo(){
        window.fbq.push('trackCustom', 'ACCEDER A VALORACIÓN', {content_name: '¿Cúanto vale tu inmueble?'});
      }
    },
    watch: { 
    },
    mounted() {
      setTimeout(function () {
            window.scrollTo(0, 0);
        },2);  
    },
  }
</script>

<style scoped lang="scss">
.howmuchContainer{
  max-width: 100%;
  text-align: center;
  padding:0;
  display:grid;
}
.howmuchContainerMobile{
    max-width: 100%;
  text-align: center;
  padding:0;
  
}

.v-card-howmuch{
  width: 798px;
  height: 308px;
  background: #FFFFFF;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  text-align: left;
  display: inline-table;
}

.v-card-howmuchMobile{
  width: 100%;
  height: 308px;
  background: #FFFFFF;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  text-align: left;
  display: inline-table;
}

.v-card-imghowmuch{
  width: 100px;
  height: 100px;
  top: 22px;
  background: #F52F57;
  box-shadow: 0px 4px 10px rgba(245, 47, 87, 0.2) !important;
  border-radius: 8px  !important;
  display: flex;
  position:relative;
  z-index:20;
  margin:auto;
}

.footer-desktop{
  margin-top:-10px;
}

ul.b {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 37px;
}
</style>