import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import Mail from "@/components/customIcons/mail.vue";
import Map from "@/components/customIcons/mapPin.vue";
import Phone from "@/components/customIcons/phone.vue";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#204E95',
            secondary: '#F52F57',
            backgroundDark: '#1d3460',
            accent: '#8c9eff',
            error: '#ff000a',
          },
        },
      },
      icons: {
        iconfont: 'mdi', // default
        values: {
          IconMail: {
            component: Mail, // you can use string here if component is registered globally
          },
          IconMap: {
            component: Map, // you can use string here if component is registered globally
          },
          IconPhone: {
            component: Phone, // you can use string here if component is registered globally
          },
        },
      },  
});
