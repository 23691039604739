<template>
  <div>
    <div id="mobile" style="width:100%;" v-if="mobile">
      <div class="">
        <div class="mt-15 pt-5 mx-5 text-left">
          <h2 class="d-h2">
            Vende sin intermediarios y sin pagar comisiones
          </h2>         
          <h5 class="d-h5 mt-2 pr-15" style="color: #F52F57">Vender tu inmueble ahora es Simple.</h5>     
        </div> 
        <v-card class="mainFormSell">
          <h2
            class="d-h3"
            style="width: 339px;height: 40px; text-align:left; color:black;"
          >
            Vende rápido y simple.
          </h2>
       <v-form ref="form" v-model="isFormValid">
          <v-text-field
            class="pt-6"
            outlined
            label="Ciudad"
            color="#204E95"
            required
            :rules="cityRules"
            @focus="changeStatusMap()"
            @focusout="changeStatusMap()"
            v-model="ciudad"
          >
            <template v-slot:append>
              <v-icon :class="{ active: mapIsActive }">$IconMap</v-icon>
            </template>
          </v-text-field>
          <v-text-field
            outlined
            label="Correo electrónico"
            color="#204E95"
            v-model="email"
            :rules="emailRules"
            required
            @focus="changeStatusMail()"
            @focusout="changeStatusMail()"
          >
            <template slot="append">
              <v-icon :class="{ active: mailIsActive }">$IconMail</v-icon>
            </template>
          </v-text-field>
          <v-text-field
            outlined
            label="Teléfono"
            color="#204E95"
            :rules="phoneRules"
            required
            v-model="phone"
            @focus="changeStatusPhone()"
            @focusout="changeStatusPhone()"
          >
            <template slot="append">
              <v-icon :class="{ active: phoneIsActive }">$IconPhone</v-icon>
            </template>
          </v-text-field>

          <v-checkbox
            v-model="accept"
             :rules="acceptRules"
              required
            label="Acepto políticas de Privacidad"
            color="secondary"
            value="accept"
            hide-details
            class="pt-0 mt-0"
          ></v-checkbox>
            </v-form>
          <div class="pt-6 text-left">
            <v-btn color="secondary" @click="sendEmailNotification" class="mainButtonMobile d-buttom"
              >INFORMATE GRATIS</v-btn
            >
          </div>
          <p class="p-d-1 pt-4 text-left">
            También podes llamarnos al
            <a href="tel:919 049 444" style="text-decoration:none"><b>919 049 444</b></a>
          </p>
        </v-card>
      </div>
      
      <h3 class="d-h2" style="padding-top:50px">
      Ventajas de vender Simple
    </h3> 
    <p class="d-subtitle-2" style="padding-top:16px; color:#F52F57;">
      La nueva revolución digital inmobiliaria.
    </p> 
    <div style="background-color:#F0F3F8; min-height: 894px; margin-top:48px;">
      <div style="margin:auto;">
        <v-row
            no-gutters
            style="padding-top:40px"
          >
            <v-col
              cols="12"
              class="d-flex  flex-column justify-center mb-7"
              @mouseover="isHovering1 = true"
              @mouseout="isHovering1 = false"
              :class="{ 'animate__animated animate__pulse': isHovering1 }"
            >
              <div class="v-card-img-mobile pa-4">
                <img
                  src="../assets/Image/icons/profit.svg"
                  style="width: 63px;height:63px;margin:auto"
                />
              </div>
              <v-card class="v-card-sellingMobile mx-4" style="width:90%;display:inline-table !important">
                 <h4 class="d-h4 pl-md-6" style="padding-top:40px;text-align:center">
                  Más ahorro
                </h4>
                <p class="d-p-1 px-5 pt-2">
                  Obtiene más <b>dinero</b> por tu inmueble. <b>Ahorra</b> en
                  comisiones Inmobiliarias.
                </p>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              class="d-flex  flex-column justify-center  mb-7"
              @mouseover="isHovering2 = true"
              @mouseout="isHovering2 = false"
              :class="{ 'animate__animated animate__pulse': isHovering2 }"
            >
              <div class="v-card-img-mobile pa-4">
                <img
                  src="../assets/Image/icons/clock.svg"
                  style="width: 63px;height:63px;margin:auto"
                />
              </div>
              <v-card class="v-card-sellingMobile mx-4" style="width:90%;display:inline-table !important">
                 <h4 class="d-h4 pl-md-6" style="padding-top:40px;text-align:center">
                  Más rápido
                </h4>
                <p class="d-p-1 mx-5 pt-2">
                  Disminuye el <b>tiempo</b> de venta. Implementamos marketing digital para que puedas vender en 60 días.
                </p>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              class="d-flex  flex-column justify-center"
              @mouseover="isHovering3 = true"
              @mouseout="isHovering3 = false"
              :class="{ 'animate__animated animate__pulse': isHovering3 }"
            >
              <div class="v-card-img-mobile pa-4">
                <img
                  src="../assets/Image/icons/handshake.svg"
                  style="width: 63px;height:63px;margin:auto"
                />
              </div>
              <v-card class="v-card-sellingMobile mx-4" style="width:90%;display:inline-table !important">
                <h4 class="d-h4 pl-md-6" style="padding-top:40px;text-align:center">
                  Más transparente
                </h4>
                <p class="d-p-1 px-5 pt-2">
                  <b>Proceso Profesional</b> sin complicaciones, directamente
                  entre vendedores y compradores.
                </p>
              </v-card>
            </v-col>
          </v-row>
        <h1 class="d-h2" style="padding-top:50px;">
            Nuestros servicios <br />
            son <span style="color:#F52F57;">Simples</span>
          </h1>
          <div style="padding-top:48px;text-align:left;">
            <div class="px-4" style="margin:auto;">
              <ul class="m-a">
                <li>Gestión Online de todo el proceso de venta.</li>
                <li>Anuncio en los principales portales inmobiliarios.</li>
                <li>Fotos y videos profesionales de tu inmueble.</li>
                <li>Seleccionamos a los mejores compradores.</li>
                <li>Nos ocupamos de todo el papeleo, gestión de firmas y trámites.</li>
                <li>Un experto inmobiliario acompañándote en todo el proceso.</li>
              </ul>
            </div>
          </div>
      </div>
      <v-btn
        color="secondary"
        class="mainButtonMobile d-buttom mx-4"
        style="margin-top:48px;margin-bottom:48px;width:90% !important;"
        v-scroll="onScroll"
        @click="toTop"
      >INFORMATE GRATIS</v-btn>
        <div class="mx-5" style="background-color: #F0F3F8;">
          <h2 class="d-h4">
            Tu piso en los mejores portales inmobiliarios
          </h2>
          <h3 class="d-h5" style="padding-top:19px;padding-bottom:28px;color:#F52F57;">
            Publicamos tu piso o casa para que lo puedas vender en 60 días sin comisiones
          </h3>
          <img style="position:relative;right:13px;" src="@/assets/Image/logosCompanies.svg"/>
          <p class="d-p-1 d-subtitle-1" style="padding-top:28px;padding-bottom:60px;margin-bottom:0;">
            Publicación y gestión de tu inmueble de forma automática en los mejores portales inmobiliarios nacionales e internacionales.
          </p>
        </div> 
    </div>
      <v-img
        height="237"
        :src="require('@/assets/Image/vender-1.webp')"
      ></v-img>
      <Footer />
    </div>
    <v-container v-else class="containerSell">
      <div class="backgroundImgSell">
      <div class="mainDivSell d-flex">
        <div class="col-6">
        <div class="homeLogo animate__animated animate__fadeInDown">
          <h1 class="d-h1">
            Vende sin intermediarios<br>
            y sin pagar comisiones
          </h1>
          <h3
            class="d-h3"
            style="color:#F52F57; padding-top:24px;"
          >
            Vender tu inmueble ahora es Simple.
          </h3>          
        </div>
        </div>
          <div class="col-6">
        <v-card class="mainForm">
          <h2
            class="d-h2"
            style="width: 339px;height: 80px; text-align:left; color:black;"
          >
            Vende rápido y simple.
          </h2>
          <v-form ref="form" v-model="isFormValid">
            <v-text-field
              class="pt-6"
              outlined
              label="Ciudad"
              color="#204E95"
              v-model="ciudad"
            required
            :rules="cityRules"
              @focus="changeStatusMap()"
              @focusout="changeStatusMap()"
            >
              <template v-slot:append>
                <v-icon :class="{ active: mapIsActive }">$IconMap</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              outlined
              label="Correo electrónico"
              color="#204E95"
              v-model="email"
              :rules="emailRules"
               required
              @focus="changeStatusMail()"
              @focusout="changeStatusMail()"
            >
              <template slot="append">
                <v-icon :class="{ active: mailIsActive }">$IconMail</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              outlined
              label="Teléfono"
              color="#204E95"
              v-model="phone"
              :rules="phoneRules"
               required
              @focus="changeStatusPhone()"
              @focusout="changeStatusPhone()"
            >
              <template slot="append">
                <v-icon :class="{ active: phoneIsActive }">$IconPhone</v-icon>
              </template>
            </v-text-field>
            <v-checkbox
              v-model="accept"
              :rules="acceptRules"
              required
              label="Acepto políticas de Privacidad"
              color="secondary"
              value="accept"
              hide-details
              class="pt-0 mt-0"
            ></v-checkbox>
            </v-form>
            <div class="pt-6 text-left">
              <v-btn @click="sendEmailNotification" color="secondary" class="mainButton d-buttom"
                >INFORMATE GRATIS</v-btn
              >
            </div>
          <p class="p-d-1 pt-4 text-left">
            También podes llamarnos al
            <a href="tel:919 049 444" style="text-decoration:none"><b>919 049 444</b></a>
          </p>
        </v-card>
        </div>
      </div>
      </div>
      <div style="background-color:#F0F3F8; min-height: 894px;">
      <div style="max-width: 1206px; margin:auto;">
      <h1 class="d-h1" style="padding-top:124px">
      Ventajas de vender Simple
      </h1> 
      <p class="d-subtitle-1" style="padding-top:16px; color:#F52F57;">
      La nueva revolución digital inmobiliaria.
      </p> 
        <v-row
            no-gutters
            :class="{
              'animate__animated animate__slideInUp': scrollPosition > 800,
            }"
            style="padding-top:40px"
          >
            <v-col
              cols="4"
              @mouseover="isHovering1 = true"
              @mouseout="isHovering1 = false"
              :class="{ 'animate__animated animate__pulse': isHovering1 }"
            >
              <div class="v-card-img">
                <img
                  src="../assets/Image/icons/profit.svg"
                  style="width: 75px;height:75px;margin:auto"
                />
              </div>
              <v-card class="v-card-selling" pl-md-6 pl-3>
                <h4 class="d-h4 pl-md-6 pl-3" style="padding-top:40px;">
                  Más ahorro
                </h4>
                <p class="d-p-1 pl-md-6 pl-3 pt-2">
                  Obtiene más <b>dinero</b> por tu inmueble. <b>Ahorra</b> en
                  comisiones Inmobiliarias.
                </p>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              @mouseover="isHovering2 = true"
              @mouseout="isHovering2 = false"
              :class="{ 'animate__animated animate__pulse': isHovering2 }"
            >
              <div class="v-card-img">
                <img
                  src="../assets/Image/icons/clock.svg"
                  style="width: 75px;height:75px;margin:auto"
                />
              </div>
              <v-card class="v-card-selling">
                <h4 class="d-h4 pl-md-6 pl-3" style="padding-top:40px;">
                  Más rápido
                </h4>
                <p
                  class="d-p-1 px-md-6 px-3 pt-2"
                >
                  Disminuye el <b>tiempo</b> de venta. Implementamos marketing digital para que puedas vender en 60 días.
                </p>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              @mouseover="isHovering3 = true"
              @mouseout="isHovering3 = false"
              :class="{ 'animate__animated animate__pulse': isHovering3 }"
            >
              <div class="v-card-img">
                <img
                  src="../assets/Image/icons/handshake.svg"
                  style="width: 75px;height:75px;margin:auto"
                />
              </div>
              <v-card class="v-card-selling">
                <h4 class="d-h4 pl-md-6 pl-3" style="padding-top:40px;">
                  Más transparente
                </h4>
                <p class="d-p-1 pl-md-6 pl-3 pt-2">
                  <b>Proceso Profesional</b> sin complicaciones, directamente
                  entre vendedores y compradores.
                </p>
              </v-card>
            </v-col>
          </v-row>
        <h1 class="d-h1" style="padding-top:124px;">
          Nuestros servicios son <span style="color:#F52F57;">Simples</span>
        </h1>
        <div style="padding-top:48px;text-align:left;">
          <div style="width:600px;margin:auto;">
          <ul class="a">
            <li>Gestión Online de todo el proceso de venta.</li>
            <li>Anuncio en los principales portales inmobiliarios.</li>
            <li>Fotos y videos profesionales de tu inmueble.</li>
            <li>Seleccionamos a los mejores compradores.</li>
            <li>Nos ocupamos de todo el papeleo, gestión de firmas y trámites.</li>
            <li>Un experto inmobiliario acompañándote en todo el proceso.</li>
          </ul>
          </div>
        </div>
      </div>
      <v-btn
        color="secondary"
        class="mainButton d-buttom"
        style="margin-top:76px;margin-bottom:92px;"
        v-scroll="onScroll"
        @click="toTop"
      >INFORMATE GRATIS</v-btn>
      </div>
      <div style="margin:auto;background-color: #F0F3F8;width: 100%;">
        <h2 class="d-h2">
          Tu piso en los mejores portales inmobiliarios
        </h2>
        <h3 class="d-h3" style="padding-top:19px;padding-bottom:48px;color:#F52F57;">
          Publicamos tu piso o casa para que lo puedas vender en 60 días sin comisiones
        </h3>
        <img src="@/assets/Image/logos.svg"/>
        <p class="d-p-1" style="padding-top:48px;padding-bottom:60px;width: 608px;margin:auto;margin-bottom:0;">
          Publicación y gestión de tu inmueble de forma automática en los mejores portales inmobiliarios nacionales e internacionales.
        </p>
      </div> 
      <StickyShare />
      <Footer />
    </v-container>
     <v-dialog
      v-model="dialog"
      max-width="489"
    >
      <v-card style="max-width:489px; border-radius: 8px;">
        <v-card-actions style="display: flex;align-items: top;justify-content: end;">
          <v-btn
            color="black"
            text
            @click="dialog = false"
          >
            X
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <img 
          style="height: 105px;width: 105px;"
          src="https://firebasestorage.googleapis.com/v0/b/simple-7bda7.appspot.com/o/check-OK.gif?alt=media&token=6eed7f40-52d0-4c69-8fe2-bf1680d3a92c" alt="Check consulta enviada">
          <h3 class="d-h3 pt-6 black--text"> ¡La consulta se envió con éxito! </h3>
          <p class="d-p-1 pt-4 black--text">Gracias por ponerte en contacto con nosotros, pronto nos comunicaremos con usted.</p>
         </v-card-text>     
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import { isMobile } from "mobile-device-detect";
import StickyShare from "@/components/StickyShare.vue"; 
import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database

export default {
  name: "Sell",
  components: {
    Footer,
    StickyShare
  },
  data: () => ({
    scrollPosition: null,
    mobile: isMobile ? true : false,
     mailIsActive: false,
    phoneIsActive: false,
    mapIsActive: false,
    isHovering1: false,
    isHovering2: false,
    isHovering3: false,
     ciudad :"",
    email: "",
    phone: "",
    accept: false,
    dialog:false,
    emailRules: [
      (v) => !!v || "E-mail requerido",
       //eslint-disable-next-line
       (v) => /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(v) || 'E-mail no valido',
    ],
    phoneRules: [
      (v) => !!v || "Este campo es requerido",
      (v) => /^\d+$/.test(v) || "Este campo solo acepta números",
      (v) => v > 0 || "Este campo no puede ser 0",
    ],
    cityRules: [
      (v) => !!v || "Este campo es requerido",
      (v) =>
        /^[a-zA-Z\s\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+$/i.test(v) ||
        "Este campo no acepta números",
    ],
    acceptRules: [
      (v) => !!v || "Tiene que aceptar las políticas de Privacidad",
    ],
    isFormValid: false,
  }),
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0);
      window.fbq.push('trackCustom', 'INFORMATE GRATIS', {content_name: 'Vender (ir arriba)'});
    },
    sendEmailNotification(){
      if(this.isFormValid){
        this.dialog = true;
      const emailId = this.idUnique;
      const updates = {};
      const updateSendEmail = {};
      const newEmailInformate = {
        "ciudad" : this.ciudad,
        "email" : this.email,
        "telefono" : this.phone,
        "fecha" : Date().toLocaleString(),
        "pais" : "España"
      }
       updates[`emails/${emailId}`] = newEmailInformate;
        firebase.database().ref().update(updates).then(() => {
        //enviamos mail ahora
        updateSendEmail['users/0NTlnChZeue6hc3OlFc1/subscribedToMailingList'] = this.idUnique;

        firebase.database().ref().update(updateSendEmail).then(() => {
        console.log('se envio exitosamente!');
        this.ciudad = "";
        this.email = "";
        this.phone = "";
        this.accept = false,
         this.$refs.form.resetValidation();
         });
         })
      }
      window.fbq.push('trackCustom', 'INFORMATE GRATIS', {content_name: 'Vender (formulario)'});
     },
     generateId() {
      // Modeled after base64 web-safe chars, but ordered by ASCII.
      console.log("generate Id:");
      var PUSH_CHARS =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzfc";

      // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
      var lastPushTime = 0;

      // We generate 72-bits of randomness which get turned into 12 characters and appended to the
      // timestamp to prevent collisions with other clients.  We store the last characters we
      // generated because in the event of a collision, we'll use those same characters except
      // "incremented" by one.
      var lastRandChars = [];

      var now = new Date().getTime();
      var duplicateTime = now === lastPushTime;
      lastPushTime = now;

      var timeStampChars = new Array(8);
      for (var i = 7; i >= 0; i--) {
        timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
        // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
        now = Math.floor(now / 64);
      }
      if (now !== 0)
        throw new Error("We should have converted the entire timestamp.");

      var id = timeStampChars.join("");

      if (!duplicateTime) {
        for (i = 0; i < 12; i++) {
          lastRandChars[i] = Math.floor(Math.random() * 64);
        }
      } else {
        // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
        for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
          lastRandChars[i] = 0;
        }
        lastRandChars[i]++;
      }
      for (i = 0; i < 12; i++) {
        id += PUSH_CHARS.charAt(lastRandChars[i]);
      }
      if (id.length != 20) throw new Error("Length should be 20.");
      this.idUnique = id;
      console.log(this.idPayment);
    },
    changeStatusMail() {
      this.mailIsActive = !this.mailIsActive;
    },
    changeStatusPhone() {
      this.phoneIsActive = !this.phoneIsActive;
    },
    changeStatusMap() {
      this.mapIsActive = !this.mapIsActive;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  watch: {},
  mounted() {
     this.generateId();
    setTimeout(function() {
      window.scrollTo(0, 0);
    }, 2);
    window.addEventListener("scroll", this.updateScroll);
    this.total = Math.round(
      this.sellvalue * (this.comisionvalue / 100) +
        this.sellvalue * (this.comisionvalue / 100) * 0.21
    );
    if (this.total > 400000) {
      this.simple = 7950;
      this.save = this.total - 7950;
    } else {
      this.simple = 4950;
      this.save = this.total - 4950;
    }
    setTimeout(function() {
      window.scrollTo(0, 0);
    }, 2);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
};
</script>

<style scoped lang="scss">
.mainDivSell{
  max-width: 1366px;
  width: 100vw;
  height: 100%;
  padding: 0px 80px;
  display: inline-block;
  margin: auto;
}
@media (max-width: 1024px) {
  .mainDivSell {
      max-width: 1366px;
    width: 100vw;
    height: 100%;
    padding: 0px 40px;
    display: inline-block;
    margin:0px;
  }
}

.containerSell {
  max-width: 100%;
  height: 100%;
  text-align: center;
  padding: 0;
  display:grid;
  height: 100%;
}

.backgroundImgSell {
  width: 100%;
  height: 768px;
  background-image: url("../assets/Image/vender-1.webp");
  background-repeat: no-repeat;
  background-size: 1920px 100%;
  background-position: bottom;
}

.mainFormSell {
  box-shadow: none !important;
  padding-top: 40px;
  padding-right: 20px;
  padding-left: 20px;
}
</style>

<style scoped>
ul.a {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 37px;
}

ul.m-a {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

ul {
  list-style: none;
}

li {
  padding-top:10px;
  line-height: 22px;
}

ul li::before {
  content: "\2022";
  color: #f52f57;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
</style>
