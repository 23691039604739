<template>
<div>
    <div v-if="mobile" class="footer-mobile">
    <div  justify="center" align="center">
        <img  src="../assets/Image/simple-footer.svg" 
        />
        <h5 class="d-p-2 col-10 pt-0 white--text text-center">
           Todos los derechos reservados.
        </h5>
        <v-icon class="pt-5" style="stroke:white !important">$IconMap</v-icon>
        <h3 class="pt-2 d-h5 white--text text-center">
            <a href="https://bracescoinversiones.com/sobreNosotros" target="_blank">
            <img
                class="logo-bracesco"
                src="@/assets/logo-bracesco-inversiones.svg"
                alt="Logo bracesco inversiones"
            />
            </a>
        </h3>
        <h5 class="d-p-2 white--text text-center">
            Paseo de la Castellana 79 piso 7 C.P. 28046 Madrid
        </h5>
        <v-icon class="pt-5" style="stroke:white !important">$IconPhone</v-icon>
        <h3 class="pt-2 d-h5 text-center">
            <a class="white--text" href="tel:919 049 444" style="text-decoration:none"><b>919 049 444</b></a>
        </h3>
        <div class="d-flex pt-6 pb-12 text-center justify-center">
            <ul>
                <li>
                    <a class="sticky-sharebar__btn js-social-share" data-social="whatsapp" data-text="Contactanos por Whatsapp" data-hashtags="#nuggets, #dev" href="https://api.whatsapp.com/send?phone=34919049444" target="_blank">
                        <svg class="icon" viewBox="0 0 24 24"><title>Contactanos por Whatsapp</title><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></svg>
                    </a>
                </li>
                <li>
                    <a class="sticky-sharebar__btn js-social-share" data-social="facebook" data-url="https://simpleproptech.com" href="https://www.facebook.com/simpleproptech" target="_blank">
                        <svg class="icon" width="24" height="24" viewBox="0 0 24 24"><title>Encontranos en Facebook</title><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/></svg>
                    </a>
                </li>
                <li>
                    <a class="sticky-sharebar__btn js-social-share" data-social="instagram" data-description="Encontranos en Instagram" data-media="https://www.instagram.com/simpleproptech_esp/" href="https://www.instagram.com/simpleproptech_esp/" target="_blank">
                        <svg class="icon" viewBox="0 0 24 24"><title>Encontranos en Instagram</title><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                    </a>
                </li>
                <li>
                    <a class="sticky-sharebar__btn js-social-share" data-social="mail" href="mailto:hello@simpleproptech.com" target="_blank">
                        <svg class="icon" width="24" height="24" viewBox="0 0 24 24"><title>Contactanos por Email</title><path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/></svg>
                    </a>
                </li>
            </ul>
        </div>
        <router-link class="text-decoration-none" to="/cookies" style="color:white">
        <p class="pt-3 d-p-2 white--text text-center mb-0">
            Términos y condiciones  ·  Atribuciones
        </p>
        </router-link>
        <a target="_blank" class="col-10 pr-10 pb-5 text-decoration-none justify-center"
           href="https://somostawa.com.ar" 
           style = "justify-content:flex-end;"
        ><h5 class="d-p-2 white--text text-center">Desarrollado por </h5> 
        <img alt="Tawa" src="../assets/Image/tawa-footer.svg" style="height: 18px; padding-left: 35px"/>
        </a>
    </div>
</div> 
<div v-else class="footer-desktop">
    <div style="max-width:926px;padding-right:80px;margin:auto;text-align:left !important">
        <v-row>
            <v-col cols="6" style="width:100%;">
            <img src="../assets/Image/simple-footer.svg" style="height:50px;"
            />
            <h5 class="mr-5 white--text text-left pa-0 d-p-2">
                Todos los derechos reservados.
            </h5>
            <div class="d-flex pt-4">
            <a  target="_blank" class="pt-2 text-decoration-none justify-center"
                href="https://www.facebook.com/simpleproptech" 
                style = "justify-content:flex-end;"
            ><svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1H9C7.67392 1 6.40215 1.52678 5.46447 2.46447C4.52678 3.40215 4 4.67392 4 6V9H1V13H4V21H8V13H11L12 9H8V6C8 5.73478 8.10536 5.48043 8.29289 5.29289C8.48043 5.10536 8.73478 5 9 5H12V1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </a>
            <a  target="_blank" class="pt-2 pl-4 text-decoration-none justify-center"
                href="https://www.instagram.com/simpleproptech_esp/" 
                style = "justify-content:flex-end;"
            ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.0002 11.3701C16.1236 12.2023 15.9815 13.0523 15.594 13.7991C15.2065 14.5459 14.5933 15.1515 13.8418 15.5297C13.0903 15.908 12.2386 16.0397 11.408 15.906C10.5773 15.7723 9.80996 15.3801 9.21503 14.7852C8.62011 14.1903 8.22793 13.4229 8.09426 12.5923C7.9606 11.7616 8.09226 10.91 8.47052 10.1584C8.84878 9.40691 9.45438 8.7938 10.2012 8.4063C10.948 8.0188 11.7979 7.87665 12.6302 8.00006C13.4791 8.12594 14.265 8.52152 14.8719 9.12836C15.4787 9.73521 15.8743 10.5211 16.0002 11.3701Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.5 6.5H17.51" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </a>
            </div>
            <a target="_blank" class="text-decoration-none"
                href="https://somostawa.com.ar" 
                style = "display: flex; margin-top:100px; padding-left:0px;margin-bottom: 5px;"
            ><h5 class=" white--text text-right">Desarrollado por </h5> 
            <img alt="Tawa" src="../assets/Image/tawa-footer.svg" style="height: 18px; padding-left: 5px"/>
            </a>
            </v-col>
            <v-col cols="6" style="display: grid;align-items: right;justify-content: right;">
                <div>
                <div style="display:flex;">
                <v-icon style="stroke:white !important; width:20px;display: flex;align-items: start;justify-content: start;">$IconMap</v-icon>
                <h3 class="d-h5 white--text pl-2">
                <a href="https://bracescoinversiones.com/sobreNosotros" target="_blank">
                <img 
                class="logo-bracesco"
                src="@/assets/logo-bracesco-inversiones.svg"
                alt="Logo bracesco inversiones"
                />
                </a>
                <span><h5 class="d-p-2 white--text d-flex">
                Paseo de la Castellana 79 piso 7<br>C.P. 28046 Madrid
                </h5></span>
                </h3>
                </div>
                <div style="display:flex;padding-top:24px;">
                <v-icon style="stroke:white !important; width:20px;">$IconPhone</v-icon>
                <a class="d-h5 white--text pl-2" href="tel:919 049 444" style="text-decoration:none"><b>919 049 444</b></a>
                </div>
                </div> 
                <div>
                    <router-link class="text-decoration-none" to="/cookies" style="color:white">
         <h5 class="white--text text-left d-p-2" style="margin-top:121px;margin-bottom: 5px;">
                        Términos y condiciones  ·  Atribuciones
                    </h5>  
        </router-link>               
                </div>  
            </v-col>
        </v-row>
    </div>
</div> 
</div>   
</template>      
<script>
import { isMobile } from 'mobile-device-detect';
export default {
    name: 'Footer',
    data: () => ({
         mobile: isMobile ? true : false,
    }),
}
</script>
<style>

.footer-desktop{
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: #6383B4;
    padding-right: 80px;
    padding-left: 80px;
    padding-top: 47px;
}

.footer-mobile{
    position: relative;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #6383B4;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 17px;
    padding-bottom: 17px;
}


</style>

<style lang="scss" scoped>

.sticky-sharebar__btn {
  position: relative;
  display: block; // fallback
  display: flex;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  
  .icon {
    position: relative;
    color: rgba(0, 0, 0, 0.54); // icon color
    fill: rgba(0, 0, 0, 0.54);
    display: block;
    margin: auto;
    z-index: 2;
    transition: color .2s;
    width: 1.3em;
    height: 1.3em;
    color: white;
    fill: white;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: inherit;
    background-color: rgb(99, 131, 180);
    transform: scale(0);
    transition: transform .3s cubic-bezier(0.215, 0.61, 0.355, 1);
    
  }

  &:hover {
    .icon {
      color: white;
      fill: white;
      transform: scale(1.1);
      box-shadow: rgb(99, 131, 180);
      text-shadow: rgb(99, 131, 180);
    }

    &::before {
      transform: scale(1);
    }
  }
}

ul {
    list-style-type: none;
    display: flex;
    padding-left:0 !important;
}
</style>