import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Sell from '../views/Sell.vue'
import Prices from '../views/Prices.vue'
import HowTo from '../views/HowTo.vue'
import HowMuch from '../views/HowMuch.vue'
import Cookies from '../views/Cookies.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: {
      name: "Inicio", // poner loginui para que redirigir por defecto a login
    },
  },
  {
    path: '/inicio',
    name: 'Inicio',
    component: Home
  },
  {
    path: '/vender',
    name: 'Vender',
    component: Sell
  },
  {
    path: '/precios',
    name: 'Precios',
    component: Prices
  },
  {
    path: '/como-funciona',
    name: 'como-funciona',
    component: HowTo
  },
  {
    path: '/cuanto-vale',
    name: 'cuanto-vale',
    component: HowMuch
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: Cookies
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
